import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { EnrollmentService } from '../../services/enrollment.service';
import { UtilityService } from '../../services/utility.service';

import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-trip-enrollment-list',
	templateUrl: './trip-enrollment-list.component.html',
	styleUrls: [ './trip-enrollment-list.component.css' ]
})
export class TripEnrollmentListComponent implements OnInit {
	yearList: any = [];
	selected = new FormControl(1);
	years = new Date().getFullYear();
	public displayedColumns = [
		'customerNumber',
		'companyName',
		'dapContact',
		'dapPhone',
		'ppewhsCodeString',
		'groupManager',
		'tripEnrolled',
		'action'
	];

	public dataSource = new MatTableDataSource<CustomerEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	response: any = {};
	model = {};
	sub: any;
	constructor(
		private enrollmentService: EnrollmentService,
		private _router: Router,
		private matDialog: MatDialog,
		private _utilityService: UtilityService,
	) {}

	ngOnInit() {
		localStorage.removeItem('isPoints');		
		this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear() + 1);
		this.customerList(new Date().getFullYear());
		if (localStorage.getItem('deleteEnroll') != null && localStorage.getItem('deleteEnroll') == '1') {
			this.response.message = 'Record has been deleted successfully.';
			this.response.isShow = true;
			this.response.messageCode = 1;
			localStorage.removeItem('deleteEnroll');			          	
		}
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;

		if (localStorage.getItem('tripOptionUpdate') != null) {
			setTimeout(() => {
				//this.renderer.invokeElementMethod(this._el.nativeElement, 'focus');
				document.getElementById("txSearch").focus();
			}, 100);
			this.response = JSON.parse(localStorage.getItem('tripOptionUpdate'));
			localStorage.removeItem('tripOptionUpdate');
		}
	}

	customerList(year) {
		this.enrollmentService.getEnrollmentList(year).subscribe((result) => {
			this.dataSource.data = result as CustomerEntity[]; //.fltier(e=>e.isTripAdded)
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	changeTab(index: number) {
		this.years = this.yearList[index];
		this.customerList(this.years);
		//this.selected.setValue(Event);
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}

export interface CustomerEntity {
	id: number;
	customerNumber: string;
	companyName: string;
	dapContact: string;
	dapPhone: string;
	ppewhsCodeString: string;
	groupManager: string;
	tripEnrolled: string;
	isActive: boolean;
}
