import { Directive } from '@angular/core';
import { FormControl, Validator,NG_VALIDATORS } from '@angular/forms';
import { emptyValidator } from './empty.validator';

@Directive({
    selector: '[emptyName][ngModel]',
    providers:[{ provide: NG_VALIDATORS, useExisting: EmptyNameValidatorDirective, multi: true }]
})
export class EmptyNameValidatorDirective implements Validator {
	validate(c: FormControl) {
		return emptyValidator()(c);
	}
}
