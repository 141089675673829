import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

let myAppUrl = "";
@Injectable({
  providedIn: 'root'
})
export class DashboardCounterService {

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  
  getDashboardCounter(years:number){
    return this._http
    .get<any[]>(myAppUrl + 'api/Dashboard/GetDashboardCounter?years=' + years)
      .pipe(
        map(data => { 
          return data; 
    }));
  }
}
