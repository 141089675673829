import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoomTypesService } from '../../services/room-types.service';
import { CustomValidators } from 'src/app/Directives/custom-validators';

@Component({
	selector: 'app-room-types',
	templateUrl: './room-types.component.html',
	styleUrls: [ './room-types.component.css' ]
})
export class RoomTypesComponent implements OnInit {
	public roomTypeForm: FormGroup;
	response: any = {};
	submitted = false;
	title = '';
	model = {};
	role = +localStorage.getItem('role');
	constructor(
		private roomTypesService: RoomTypesService,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<RoomTypesComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		this.generateForm();
	}
	get f() {
		return this.roomTypeForm.controls;
	}

	generateForm() {
		if (this.data.id > 0) {
			this.title = 'Update Room Types';
		} else {
			this.data.roomPerNightCost = '';
			this.title = 'Add Room Types';
			this.data.tripId = '';
			this.data.isActive = true;
		}
		this.roomTypeForm = this.fb.group({
			Id: [ this.data.id, Validators.required ],
			TripId: [ this.data.tripId, [ Validators.required ] ],
			RoomType: [ this.data.roomType, [ Validators.required, CustomValidators.checkEmpty ] ],
			RoomPerNightCost: [ this.data.roomPerNightCost, [ Validators.required, Validators.min(1) ] ],
			IsActive: [ this.data.isActive ]
		});
	}

	onCloseClick(): void {
		this.dialogRef.close();
	}
	saveRoomType() {
		this.submitted = true;
		if (this.roomTypeForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		let dataSave = this.roomTypeForm.value;
		this.model = {
			Id: dataSave.Id,
			RoomType: dataSave.RoomType,
			TripId: dataSave.TripId,
			RoomPerNightCost: Number(dataSave.RoomPerNightCost),
			IsActive: dataSave.IsActive
		};
		this.roomTypesService.saveRoomType(this.model).subscribe((result) => {
			if (result) {
				if (result.messageCode == 1) {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			}
		});
	}
	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	deleteRoomType() {
		if (confirm('Are you sure to delete the current record?')) {
			this.roomTypesService.deleteRoomType(this.data.id).subscribe((result) => {
				if (result.messageCode == 1) {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			});
		}
	}
}