import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { TravelerService } from '../../services/traveler.service';
import { ItineraryService } from '../../services/itinerary.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-report-itinerary-return',
  templateUrl: './report-itinerary-return.component.html',
  styleUrls: ['./report-itinerary-return.component.css'],
})
export class ReportItineraryReturnComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  years = '';
  tripId = '';
  searchText = '';

  public displayedColumns = [
    'returnDate',
    'returnAirport',
    'arrivalDate',
    'arrivalAirport',
    'airlineName',
    'flightNumber',
    'returnTime',
    'arrivalTime',
    'total',
    'travelers',
    'tripCode',
  ];

  public dataSource = new MatTableDataSource<TravelerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private itineraryService: ItineraryService,
    private travelerService: TravelerService,
    public _utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    //	this.airlineList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  airlineList() {
    if (+this.years > 0 && +this.tripId > 0) {
      this.itineraryService
        .getReportItineraryReturn(+this.years, +this.tripId)
        .subscribe((result) => {
          this.model = this.dataSource.data = result as TravelerEntity[]; //.fltier(e=>e.isTripAdded)
          //  console.log(result)
          this.dataSource.paginator = this.paginator;
        });
    } else this.dataSource.data = [];
  }

  changeYear() {
    this.tripId = '';
    this.travelerService.getTripCodeList().subscribe((result) => {
      if (result) {
        if (result.length > 0)
          this.tripOptionList = result.filter((t) => t.years === this.years);
      }
      this.airlineList();
    });
  }

  changeTrip() {
    this.airlineList();
  }

  // applyFilter(filterValue: string) {
  // 	filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  //   this.searchText=filterValue;
  // 	this.dataSource.filter = filterValue;
  //   }
}

export interface TravelerEntity {
  tripCode: string;
  airlineName: string;
  flightNumber: string;
  returnDate: string;
  returnAirport: string;
  returnTime: string;
  arrivalDate: string;
  arrivalAirport: string;
  arrivalTime: string;
  total: string;
  companyNameCustom: string;
  travelers: string;
}
