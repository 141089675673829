import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Router } from '@angular/router';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-report-ytd-customer-statement',
  templateUrl: './report-ytd-customer-statement.component.html',
  styleUrls: ['./report-ytd-customer-statement.component.css'],
})
export class ReportYtdCustomerStatementComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  selected = new FormControl(1);
  years = 0;
  searchText = '';
  public displayedColumns = [
    'customerNumber',
    'companyName',
    'Trip',
    'Group',
    'GroupOptionsPoint',
    'MiscGroupPoint',
    'GroupOptionsTotal',
    'TotalAdditionalPoints',
    'TripPoints',
    'RequiredPoints',
    'Earned',
    'Balance',
  ];

  public dataSource = new MatTableDataSource<YTDCustomerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private enrollmentService: EnrollmentService,
    private _router: Router,
    private matDialog: MatDialog,
    public _utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 1,
      new Date().getFullYear() + 1
    );
    this.years = new Date().getFullYear();
    this.customerList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  formatDate(date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }
  getDateTime() {
    return this.formatDate(new Date());
  }

  customerList() {
    this.enrollmentService
      .YTDCustomerStatementList(this.years)
      .subscribe((result) => {
        this.dataSource.data = this.model = result as YTDCustomerEntity[]; //.fltier(e=>e.isTripAdded)
        this.dataSource.paginator = this.paginator;
      });
  }

  changeYear() {
    this.customerList();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }

  exportCustomer() {
    this.enrollmentService.getEnrollmentList_Export(
      this.years,
      this.searchText
    );
  }
}
export interface YTDCustomerEntity {
  customerNumber: string;
  companyName: string;
  trip: string;
  group: string;
  groupOptionsPoint: number;
  miscGroupPoint: number;
  groupOptionsTotal: number;
  totalAdditionalPoints: number;
  tripPoints: number;
  requiredPoints: number;
  earned: number;
  balance: number;
}
