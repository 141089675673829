import { Injectable, Inject } from '@angular/core';
import { map, debounceTime } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

let myAppUrl = '';
@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }

  getEnrollmentDetail(id: number) {
    return this._http
      .get<any>(myAppUrl + 'api/TripEnrollment/EnrollmentDetail?id=' + id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getEnrollmentList(year) {
    return this._http
      .get<any>(myAppUrl + 'api/TripEnrollment/EnrollmentList?year=' + year)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // getEnrollmentListReport(years:number, pageIndex:number, pageSize:number):Observable<any> {
  //   return this._http
  //     .get<any>(
  //       myAppUrl + 'api/TripEnrollment/EnrollmentListReport?years=' 
  //        + years +
  //       '&pageNumber=' + pageIndex +
  //       '&recordCount=' + pageSize 
  //     )
  //     .pipe(
  //       map((data) => {
  //         return data;
  //       })
  //     );
  // }
  getEnrollmentListReport(years: number, pageIndex: number, pageSize: number, searchText: string): Observable<any> {
    return this._http
      .get<any>(
        `${myAppUrl}api/TripEnrollment/EnrollmentListReport?years=${years}&pageNumber=${pageIndex}&recordCount=${pageSize}&searchText=${encodeURIComponent(searchText)}`
      )
      .pipe(
        map((data) => {
          return data;
        })
    );
}

  getCustomerNameList(value) {
    return this._http
      .get<any>(myAppUrl + 'api/Customer/CustomerNameList?searchKey=' + value)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  proceedEnrollment(id, years) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/TripEnrollment/ProceedEnrollment?id=' +
          id +
          '&years=' +
          years
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  updateDAPAddress(model) {
    return this._http
      .post<any>(myAppUrl + 'api/TripEnrollment/UpdateDAPAddress', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  saveTripSelection(entity) {
    return this._http
      .post<any>(myAppUrl + 'api/TripEnrollment/SaveTripSelection', entity)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTripSelection(id) {
    return this._http
      .get<any>(myAppUrl + 'api/TripEnrollment/GetTripSelection?id=' + id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteEnrollment(id: number, customerId: number) {
    return this._http
      .post<any>(
        myAppUrl +
          'api/TripEnrollment/DeleteEnrollment/' +
          id +
          '/' +
          customerId,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getNotEnrollmentList() {
    return this._http
      .get<any>(myAppUrl + 'api/TripEnrollment/NotEnrollmentList')
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTripList(id: number) {
    return this._http
      .get<any>(myAppUrl + 'api/TripEnrollment/GetTripList?id=' + id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getNotEnrollmentList_Export(field, search) {
    window.open(
      myAppUrl +
        'api/TripEnrollment/NotEnrollmentListExportToExcel?field=' +
        field +
        '&search=' +
        search
    );
  }

  getEnrollmentList_Export(year, searchText) {
    window.open(
      myAppUrl +
        'api/TripEnrollment/EnrollmentListReportToExport?year=' +
        year +
        '&searchText=' +
        searchText
    );
  }

  getCustomerStatementListExport(year, field, search) {
    window.open(
      myAppUrl +
        'api/TripEnrollment/CustomerStatementListExport?year=' +
        year +
        '&field=' +
        field +
        '&search=' +
        search
    );
  }
  getCustomerHeader(year) {
    return this._http
      .get<any>(
        myAppUrl + 'api/TripEnrollment/CustomerStatementHeader?year=' + year
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  //Added By Chandan 15-06-2023
  getYearEndCustomerHeader(year) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/TripEnrollment/YearEndCustomerStatementHeader?year=' +
          year
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  //Added By Chandan 19-06-2023
  getYECustomerStatementListExport(year, field, search) {
    window.open(
      myAppUrl +
        'api/TripEnrollment/YECustomerStatementListExport?year=' +
        year +
        '&field=' +
        field +
        '&search=' +
        search
    );
  }

  //Added By Chandan 15-06-2023
  getCustomerSxeHeader(year) {
    return this._http
      .get<any>(
        myAppUrl + 'api/TripEnrollment/CustomerSxeStatementHeader?year=' + year
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  //Added on 18 Aug 2023 By Chandan
  getCustomerSxeStatementListExport(year, field, search) {
    window.open(
      myAppUrl +
        'api/TripEnrollment/CustomerSxeStatementListExport?year=' +
        year +
        '&field=' +
        field +
        '&search=' +
        search
    );
  }

  YTDCustomerStatementList(year: number) {
    return this._http
      .get<any>(
        myAppUrl + 'api/TripEnrollment/YTDCustomerStatementList/' + year
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  //added on 09/10/2023
  getPendingArRelease(){
    return this._http
      .get<any>(
        myAppUrl + 'api/TripEnrollment/PendingArReleasedList/'
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
