import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-customer-ar-released',
  templateUrl: './customer-ar-released.component.html',
  styleUrls: ['./customer-ar-released.component.css']
})
 export class CustomerArReleasedComponent implements OnInit {
	data: any = {};
	response: any = {};
	settings: any;
	model: any = {};
	from: number = 1;
	to: number = 25;
	totalRecord: number;
	years = new Date().getFullYear()-1;
	yearList: any = [];
	constructor(
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string,
		private _utilityService: UtilityService
	) {
		this.settings = {
			columns: {
				customerNumber: {
					title: 'Company No.',
					sort: false
				},
				companyName: {
					title: 'Company Name',
					type: 'html',
					sort: false
				},

				years: {
					title: 'Year',
					filter: false,
					sort: false
				},
				status: {
					title: 'Status',
					filter: false,
					sort: false
				}	
			},
			actions: {
				edit: false,
				add: false,
				delete: false,

				position: 'right'
			},
			noDataMessage: 'No record found.',
			pager: {
				display: true,
				perPage: 25
			},
			attr: {
				class: 'table table color-table dark-table table-striped'
			}
		};
		this.bindPointsList(this.years);
	}
	ngOnInit() {
    this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 3, new Date().getFullYear() - 1);
		this.model.Year = this.years;

    if (localStorage.getItem('CustomerArReleased') != null) {
			this.response = JSON.parse(localStorage.getItem('CustomerArReleased'));
			localStorage.removeItem('CustomerArReleased');
			this.model.Year =this.response.ARYear;
		}

		this.data.onChanged().subscribe((change) => {
			this.from = (change.paging.page - 1) * change.paging.perPage + 1;
			this.to =
				this.data.lastRequestCount <= change.paging.page * change.paging.perPage
					? this.data.lastRequestCount
					: change.paging.page * change.paging.perPage;
		});
	}
	dropdownYearChange() {
		this.bindPointsList(this.model.Year);
	}
	bindPointsList(year) {
		this.data = new ServerDataSource(this.http, {
			endPoint: this.baseUrl + 'api/AllocatePoints/GetPointAllocation?year=' + year+'&status=1',
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});

		this.data.setPaging(1, 10, true);
	}

  getCustomerARReleaseExport()
  {
    let filterData = this.data.getFilter();let extraSearch='';
		if(filterData.filters.length>0)
		{
			filterData.filters.forEach(element => {
				extraSearch+= '&'+element.field+'='+element.search;				
			});
		}		
		window.open(this.baseUrl + 'api/AllocatePoints/GetCustomerARReleaseExport?year='+this.model.Year +extraSearch);
  }
}
