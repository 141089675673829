import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TravelerService } from 'src/app/services/traveler.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-report-traveler-points-option',
  templateUrl: './report-traveler-points-option.component.html',
  styleUrls: ['./report-traveler-points-option.component.css'],
})
export class ReportTravelerPointsOptionComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years;
  tripId = '';
  searchText = '';

  public displayedColumns = [
    'customerNumber',
    'companyName',
    'trip',
    'traveler',
    'dateOfBirth',
    'groups',
    'miscGroupRequest',
    'options1',
    'options2',
    'options3',
    'options4',
    'options5',
    'options6',
    'totalGroupPoints',
    'totalMiscGroupPointAdjustment',
    'groupOptionsTotal',
    'totalAdditionalPoints',
    'tripPoints',
    'requiredPoints',
    'earnedPoints',
    'balance',
  ];

  public dataSource = new MatTableDataSource<TravelerPointOptionsEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 1,
      new Date().getFullYear() + 1
    );
    this.years = new Date().getFullYear();
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
      this.changeYear();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  travelerList() {
    this.travelerService
      .reportTravelerPointsOptionList(+this.tripId, +this.years)
      .subscribe((result) => {
        this.model = this.dataSource.data =
          result as TravelerPointOptionsEntity[]; //.fltier(e=>e.isTripAdded)
        this.dataSource.paginator = this.paginator;
      });
  }

  changeYear() {
    this.tripId = '';
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.travelerList();
  }

  changeTrip() {
    this.travelerList();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
}

export interface TravelerPointOptionsEntity {
  customerNumber: string;
  companyName: string;
  trip: string;
  traveler: string;
  dateOfBirth: string;
  groups: string;
  options1: string;
  options2: string;
  options3: string;
  options4: string;
  options5: string;
  options6: string;
  totalGroupPoints: number;
  totalMiscGroupPointAdjustment: number;
  groupOptionsTotal: number;
  totalAdditionalPoints: number;
  tripPoints: number;
  requiredPoints: number;
  earnedPoints: number;
  balance: number;
  miscGroupRequest: string;
}
