
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { RoomTypesService } from '../../services/room-types.service';
import { RoomTypesComponent} from '../../Components/room-types/room-types.component';

@Component({
  selector: 'app-room-types-list',
  templateUrl: './room-types-list.component.html',
  styleUrls: ['./room-types-list.component.css']
})
export class RoomTypesListComponent implements OnInit {
	public displayedColumns = [
    'tripCode',
    'roomType' ,
	'roomPerNightCost',
	'isActive',
    'action'
	];
	public dataSource = new MatTableDataSource<RoomTypeEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	constructor(
		private roomTypesService: RoomTypesService,
		private _router: Router,
		private matDialog: MatDialog
	) {}

	ngOnInit() {
		this.roomTypeList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	roomTypeList() {
		//debugger;
		this.roomTypesService.getRoomTypeList().subscribe((result) => {
			this.dataSource.data = result as RoomTypeEntity[];
			this.dataSource.paginator = this.paginator;
		});
	}
	addUpdateRoomType(id: number) {
		this.roomTypesService.getRoomTypeDetail(id).subscribe((result) => {
			if (result) this.model = result;
			else this.model = {};
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '560px';
			dialogConfig.panelClass = 'dap-model';
			dialogConfig.data = this.model;
			const modalDialog = this.matDialog.open(RoomTypesComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					this.roomTypeList();
				}
			});
		});
	}
	deleteTripOption(id: number) {
		if (confirm('Are you sure to delete the current record?')) {
			this.roomTypesService.deleteRoomType(id).subscribe((result) => {
				this.response = result;
				if (result && result.messageCode == 1) {
					this.roomTypeList();
				}
			});
		}
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}
export interface RoomTypeEntity {
  id: number
  tripCode:string
  roomType:string 
  roomPerNightCost:number
  isActive: boolean
}

