import { Component, OnInit, Inject } from '@angular/core';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { TravelerService } from 'src/app/services/traveler.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';
import { ServerDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
//import { downloadFile } from 'file-saver';
@Component({
  selector: 'app-report-traveler',
  templateUrl: './report-traveler.component.html',
  styleUrls: ['./report-traveler.component.css']
})
export class ReportTravelerComponent implements OnInit {
	model: any = {};
	settings: any;
	data: any={};
	response: any = {};
	isRoom:boolean=false;
	from :number=1; to:number=25; totalRecord:number;
	title='Traveler by Group';
	yearList :any=[]; groupList=[]; tripOptionList=[];bedTypeList=[];tripwithYearList=[];allbedTypeList=[]
	years='';groups='';tripId='';bedTypeId=''
	constructor(
		private enrollmentService: EnrollmentService,
		private travelerService: TravelerService,	
		private _router: Router,
		private _utilityService: UtilityService,
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string
	) {
		this.travelerList(false);
		let url=this._router.url
		if(url=='/travelers-by-group')
		{
		this.settings = {
			columns: {
				customerNumber: {
					title: 'Company No.',
					sort: false
				},
				companyName: {
					title: 'Company Name',
					sort: false
				},
				trip: {
					title: 'Trip',
					sort: false,
					filter:false
				},
				traveler: {
					title: 'Traveler',
					sort: false,
					filter:false
				},
				groups: {
					title: 'Group',
					sort: false,
					filter:false
				},				
				noOfTravelers: {
					title: '# of Travelers',
					sort: false,
					filter:false
				}				
			},
			noDataMessage: "No record found.",
			actions: {
				edit: false,
				add: false,
				delete: false,
				position: 'right'
			},
			pager: {
				display: true,
				perPage: 25
			},
			attr: {
				class: 'table table color-table dark-table table-striped'
			}
		};
		}
		else
		{
			this.isRoom=true
			this.title='Traveler By Room Types';
			this.settings = {
				columns: {
					customerNumber: {
						title: 'Company No.',
						sort: false
					},
					companyName: {
						title: 'Company Name',
						sort: false
					},
					trip: {
						title: 'Trip',
						sort: false,
						filter:false
					},
					traveler: {
						title: 'Traveler',
						sort: false,
						filter:false
					},
					groups: {
						title: 'Group',
						sort: false,
						filter:false
					},					
					noOfTravelers: {
						title: '# of Travelers',
						sort: false,
						filter:false
					},	
					
					preferredBedType: {
						title: 'Room Types',
						sort: false,
						filter:false
					}			
				},
				noDataMessage: "No record found.",
				actions: {
					edit: false,
					add: false,
					delete: false,
					position: 'right'
				},
				pager: {
					display: true,
					perPage: 25
				},
				attr: {
					class: 'table table color-table dark-table table-striped'
				}
			};
		}
	}

	ngOnInit() {
		this.yearList=this._utilityService.fillYear(new Date().getFullYear()-1, new Date().getFullYear()+1);
		this.travelerService.getBedTypeList(0).subscribe((types) => {
			this.allbedTypeList=types;							
		  });	
	}

	changeYear()
	{
		this.tripId=''
		this.travelerService.getTripCodeList().subscribe((result) => {
			if (result) {
				this.tripwithYearList=result;
				if(result.length>0)
				this.tripOptionList=this.tripwithYearList.filter(t=> t.years===this.years);
			}	
			this.travelerList(false);			
		});
	}

	changeTrip()
	{
		this.groups='' ;this.bedTypeId=''		
		let group=this.tripwithYearList.filter(t=> t.id===this.tripId)[0];
		if(group)
		{
		 this.groupList =this._utilityService.fillGroup(group.groups);
		 this.bedTypeList=this.allbedTypeList.filter(t=> t.tripId===this.tripId);
		}
	     this.travelerList(false);			
	}

	travelerList(isExport:boolean){
		if(!isExport)
		{
          this.data = new ServerDataSource(this.http, {
			endPoint: this.baseUrl + 'api/Traveler/TravelerByGroupList?years='+this.years+'&groups='+this.groups +'&tripId='+this.tripId+ '&bedTypeId='+this.bedTypeId +'&isExport='+isExport,
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});

		this.data.onChanged().subscribe((change) => {		
		this.from=((change.paging.page-1)*change.paging.perPage)+1;
		this.to=this.data.lastRequestCount <=change.paging.page*change.paging.perPage?this.data.lastRequestCount:change.paging.page*change.paging.perPage;
	});
	}
	else
	{
		let filterData = this.data.getFilter();let extraSearch='';
		if(filterData.filters.length>0)
		{
			filterData.filters.forEach(element => {
				extraSearch+= '&'+element.field+'='+element.search;				
			});
		}		
		window.open(this.baseUrl + 'api/Traveler/TravelerByGroupList?years='+this.years+'&groups='+this.groups +'&tripId='+this.tripId + '&bedTypeId='+this.bedTypeId +  '&isRoom='+this.isRoom+ '&isExport='+isExport+extraSearch);
	}
	}
}

