import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { AirlineService } from '../../services/airline.service';
import { Router } from '@angular/router';
import { AirlineComponent } from '../airline/airline.component';

@Component({
  selector: 'app-airline-list',
  templateUrl: './airline-list.component.html',
  styleUrls: ['./airline-list.component.css']
})
export class AirlineListComponent implements OnInit {
	public displayedColumns = [ 'airlineCode', 'airlineName','isActiveText', 'action' ];
	public dataSource = new MatTableDataSource<AirlineEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	constructor(private _airlineService: AirlineService, private _router: Router, private matDialog: MatDialog) {}

	ngOnInit() {
		this.airlineList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	airlineList() {
		//debugger;
		this._airlineService.getAirlineList().subscribe((result) => {
			this.dataSource.data = result as AirlineEntity[];
			//console.log(this.dataSource.data);
			this.dataSource.paginator = this.paginator;
		});
	}
	addUpdateAirline(id: number) {
		this._airlineService.getAirlineDetail(id).subscribe((result) => {
			if (result) this.model = result;
			else this.model = {};
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '500px';
			dialogConfig.panelClass="dap-model"
			dialogConfig.data = this.model;
			const modalDialog = this.matDialog.open(AirlineComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					this.airlineList();
				}
			});
		});
	}
	deleteAirline(id: number) {
		if (confirm('Are you sure to delete the current record?')) {
			this._airlineService.deleteAirline(id).subscribe((result) => {
				this.response = result;
				if (result && result.messageCode==1) {
					this.airlineList();
				}
			});
		}
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	  }
}
export interface AirlineEntity {
	id: number;
	airlineCode: string;
	airlineName: string;
	isActiveText:string;
}
