import { Component, OnInit } from '@angular/core';
import { ReportLogService } from 'src/app/services/report-log.service';
import { UtilityService } from 'src/app/services/utility.service';


@Component({
  selector: 'app-report-log-historical-point',
  templateUrl: './report-log-historical-point.component.html',
  styleUrls: ['./report-log-historical-point.component.css']
})
export class ReportLogHistoricalPointComponent implements OnInit {
  model: any[] = [];
  historicalPointsModel: any = {};
  displayData = false;
  yearList: any[] = [];
  years = new Date().getFullYear();

  constructor(
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService
  ) { }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.getHistoricalPoint();
  }

  getHistoricalPoint() {
    this.reportLogService.getHistoricalPoint()
      .subscribe((result) => {
        console.log(result);
        if (result) {
          //this.model = result;
          this.historicalPointsModel = result;
        }
      });
  }
  ExportDate() {

    this.reportLogService.exportHistoricalPoint();
  }
}
