import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { ReportLogService } from 'src/app/services/report-log.service';
import { TravelerService } from 'src/app/services/traveler.service';
import { UtilityService } from 'src/app/services/utility.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-log-traveler-flight-update',
  templateUrl: './report-log-traveler-flight-update.component.html',
  styleUrls: ['./report-log-traveler-flight-update.component.css'],
})
export class ReportLogTravelerFlightUpdateComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = '';
  tripId = '';
  searchText = '';
  isSearch = false;
  previousPageSizeOptions: number[] = [];
  pageSizeOptions: number[] = [5, 10, 20, 50];

  public displayedColumns = [
    //'updatedOnDisplay',
    'formattedDate',
    'formattedTime',
    'updatedByName',
    'tripCode',
    'ppeWhsCode',
    'salesmanCode',
    'customerNumber',
    'companyName',
    'groups',
    'legalName',
    'gender',
    'nickName',
    'dateofBirthDisplay',
    'cellPhone',
    'emailAddress',
    'flightType',
    'flightRowNumber',
    'recordLocator',
    'recordLocatorOld',
    'ticketNumber',
    'ticketNumberOld',
    'ticketCost',
    'ticketCostOld',
    'airlineCode',
    'airlineCodeOld',
    'flightNumber',
    'flightNumberOld',
    'departureAirport',
    'departureAirportOld',
    'departureDateDisplay',
    'departureDateOldDisplay',
    'departureTime',
    'departureTimeOld',
    'arrivalAirport',
    'arrivalAirportOld',
    'arrivalDateDisplay',
    'arrivalDateOldDisplay',
    'arrivalTime',
    'arrivalTimeOld',
  ];

  public dataSource = new MatTableDataSource<UpdatedTravelerEntity>();
  // resultsLength = 0;
  // pageSize = 10;
  // pageIndex = 1;
  // pageEvent: PageEvent;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true })
  // sort: MatSort;
  @ViewChild(MatPaginator, { static: true })
  resultsLength = 0;
  pageSize = 25;
  pageEvent: PageEvent;

  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};

  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService
  ) { }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
    //this.dataSource.sort = this.sort;
    //this.previousPageSizeOptions = [...this.pageSizeOptions];
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  updatedFlightList(searchText) {
    this.dataSource.data = [];
    let pageIndex = this.pageEvent ? this.pageEvent.pageIndex + 1 : 1;
    const pageSize = this.pageEvent ? this.pageEvent.pageSize : this.pageSize;

    if (searchText) {
      pageIndex = 1;
    }
    if (+this.tripId > 0 && +this.years > 0) {
      this.reportLogService
        .getTravelerFlightDetailUpdateList(+this.years, +this.tripId, pageIndex, this.pageSize, this.searchText)
        .subscribe(result => {
          this.model = this.dataSource.data = result.data as UpdatedTravelerEntity[];
          this.resultsLength = result.totalCount;
        if (this.paginator) {
          this.paginator.pageSize = pageSize;
          this.paginator.length = this.resultsLength;
        }
          // this.resultsLength = result.totalCount;

          // if (this.paginator, searchText, this.isSearch) {
          //   console.log("call this.dataSource.paginator", this.pageIndex)
          //   this.paginator.length = this.resultsLength;
          //   this.paginator.pageIndex = 0;
          //   // this.pageIndex= 1;
          //   this.paginator.pageSize = this.pageSize;
          //   this.paginator.firstPage();
          // }
        });
    }
  }
  loadPage() {
    this.updatedFlightList(this.searchText);
  }

  // loadPage(event: PageEvent) {
  //   //this.pageSizeOptions = this.pageSizeOptions;
  //   console.log("next event", event.previousPageIndex, event.pageIndex, event);

  //   if (event.previousPageIndex < event.pageIndex) {
  //     var currentPageIndex = (this.resultsLength / this.pageSize);
  //     return currentPageIndex;
  //   }
  //   if (!this.areArraysEqual(this.previousPageSizeOptions, this.pageSizeOptions)) {
  //     console.log("pageSizeOptions has changed");
  //     this.previousPageSizeOptions = [...this.pageSizeOptions]; // Update previousPageSizeOptions

  //     // Reset or update the pageIndex as needed when pageSizeOptions changes
  //     ++this.pageIndex; // or any logic to update pageIndex based on your requirements
  //   }

  //   if (event.previousPageIndex > event.pageIndex) {
  //      console.log("previousPageIndex", this.pageIndex,this.pageSizeOptions)
  //     --this.pageIndex;
  //     // this.pageIndex = --event.pageIndex;
  //     // console.log("previousPageIndex", event.pageIndex, this.pageIndex)
  //   } else {
  //     //  if (event.previousPageIndex < event.pageIndex){
  //     //  // console.log("nextPageIndex")

  //     //    var currentPageIndex = (this.resultsLength / this.pageSize);
  //     ++this.pageIndex
  //     // == currentPageIndex
  //     //this.pageIndex = ++event.pageIndex;
  //     //  console.log("nextPageIndex", event.pageIndex, this.pageIndex)
  //   }
  //   this.pageSize = event.pageSize;
  //   // console.log("next event  this.pageIndex", this.pageIndex, this.pageSize);
  //   this.isSearch = false;
  //   this.updatedFlightList(this.searchText, event);
  // }
  areArraysEqual(array1: number[], array2: number[]): boolean {
    if (array1.length !== array2.length) return false;
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) return false;
    }
    return true;
  }

  changeYear() {
    this.tripId = '';
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
  }

  changeTrip() {
    //this.pageIndex = 1;
    this.updatedFlightList(this.searchText);
  }
  applyFilter(filterValue: string) {
    this.searchText = filterValue.trim().toLowerCase();
    this.updatedFlightList(this.searchText);
  }

  // applyFilter(filterValue: string) {
  //   this.pageIndex = 1;
  //   if (filterValue) {
  //     this.isSearch = true;
  //   } else {
  //     this.isSearch = false;
  //   }
  //   this.searchText = filterValue.trim().toLowerCase();
  //   this.updatedFlightList(this.searchText);
  // }
  exportToExcel(): void {
    const pageIndex = 1;
    const pageSize = this.resultsLength;

    if (+this.tripId > 0 && +this.years > 0) {
      this.reportLogService.getTravelerFlightDetailUpdateList(+this.years, +this.tripId, pageIndex, pageSize, this.searchText)
        .subscribe((result) => {
          // const data = result.data.map(item => {
          //   return {
            const sortedData = this.dataSource.sortData(result.data as UpdatedTravelerEntity[], this.sort);

            const data = sortedData.map(item => {
              return {
              //'Updated On': item.updatedOnDisplay,
              'Date': item.formattedDate,
              'Time': item.formattedTime + ' ' + 'Hrs',
              'Updated By': item.updatedByName,
              'Trip Code': item.tripCode,
              'Whs': item.ppeWhsCode,
              'Salesman': item.salesmanCode,
              'Customer#': item.customerNumber,
              'Company Name': item.companyName.replace(/&#39;/g, "'"),
              'Groups': item.groups,
              'Legal Name': item.legalName,
              'Gender': item.gender == 1 ? 'M' : 'F',
              'Nick Name': item.nickName,
              'Date of Birth': item.dateofBirthDisplay,
              'Cell Phone': item.cellPhone,
              'Email': item.emailAddress,
              'Flight Type': item.flightType,
              'Flight': item.flightRowNumber,
              'Record Locator': item.recordLocator,
              'Old Record Locator': item.recordLocatorOld,
              'Ticket Number': item.ticketNumber,
              'Old Ticket Number': item.ticketNumberOld,
              'Cost Of flight': item.ticketCost,
              'Old Cost Of flight': item.ticketCostOld,
              'Airline': item.airlineCode,
              'Old Airline': item.airlineCodeOld,
              'Flight Number': item.flightNumber,
              'Old Flight Number': item.flightNumberOld,
              'Airport (Departure / Return)': item.departureAirport,
              'Old Airport (Departure / Return)': item.departureAirportOld,
              'Date (Departure / Return)': item.departureDateDisplay,
              'Old Date (Departure / Return)': item.departureDateOldDisplay,
              'Time (Departure / Return)': item.departureTime,
              'Old Time (Departure / Return)': item.departureTimeOld,
              'Arrival Airport': item.arrivalAirport,
              'Old Arrival Airport': item.arrivalAirportOld,
              'Arrival Date': item.arrivalDateDisplay,
              'Old Arrival Date': item.arrivalDateOldDisplay,
              'Arrival Time': item.arrivalTime,
              'Old Arrival Time': item.arrivalTimeOld,
            };
          });
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Traveler-Flight-Update');
          XLSX.writeFile(wb, `Traveler-Flight-Update-Report_${this._utilityService.getCurrentDateTime()}.xlsx`);
        });
    }
  }
}

export interface UpdatedTravelerEntity {
  id: number;
  updatedOnDisplay: string;
  updatedByName: string;
  tripCode: string;
  ppeWhsCode: string;
  salesmanCode: string;
  customerNumber: string;
  companyName: string;
  groups: number;
  legalName: string;
  gender: number;
  nickName: string;
  dateofBirthDisplay: string;
  cellPhone: string;
  emailAddress: string;
  flightRowNumber: number;
  recordLocator: string;
  recordLocatorOld: string;
  ticketNumber: string;
  ticketNumberOld: string;
  ticketCost: number;
  ticketCostOld: number;
  airlineCode: string;
  airlineCodeOld: string;
  flightNumber: string;
  flightNumberOld: string;
  departureAirport: string;
  departureAirportOld: string;
  departureDateDisplay: string;
  departureDateOldDisplay: string;
  departureTime: string;
  departureTimeOld: string;
  arrivalAirport: string;
  arrivalAirportOld: string;
  arrivalDateDisplay: string;
  arrivalDateOldDisplay: string;
  arrivalTime: string;
  arrivalTimeOld: string;
  flightType: string;
  formattedDate:string;
  formattedTime:string;
}
