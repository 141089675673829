import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { TravelerService } from "../../services/traveler.service";
import { UtilityService } from "../../services/utility.service";

@Component({
  selector: "app-report-traveler-special-requests",
  templateUrl: "./report-traveler-special-requests.component.html",
  styleUrls: ["./report-traveler-special-requests.component.css"],
})
export class ReportTravelerSpecialRequestsComponent implements OnInit {
  // model: any = {};
  // yearList: any = [];
  // tripOptionList = [];
  // alltripList = [];
  // years = '';
  // tripId = '';
  // searchText = '';

  // public displayedColumns = [
  //   'ppeWhsCode',
  //   'customerNumber',
  //   'companyName',
  //   'groups',
  //   'firstName',
  //   'middleName',
  //   'lastName',
  //   'nickName',
  //   'gender',
  //   'dateOfBirth',
  //   'cellPhone',
  //   'emailAddress',
  //   'isDepartureFlight',
  //   'isReturnFlight',
  //   'miscGroupRequest',
  //   'notes',
  //   'wheelChairRequest',
  //   'companyOwnerText',
  //   'salesmanName',
  //   'trip',
  // ];

  // public dataSource = new MatTableDataSource<TravelerEntity>();
  // @ViewChild(MatPaginator, { static: true })
  // paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true })
  // sort: MatSort;
  // response: any = {};
  constructor() // private travelerService: TravelerService,
  // public _utilityService: UtilityService
  {}

  ngOnInit() {
    // this.yearList = this._utilityService.fillYear(
    //   new Date().getFullYear() - 1,
    //   new Date().getFullYear() + 1
    // );
    // this.travelerService.getTripCodeList().subscribe((result) => {
    //   this.alltripList = result;
    // });
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  // travelerList() {
  //   this.dataSource.data = [];
  //   if (+this.tripId > 0 && +this.years > 0) {
  //     this.travelerService
  //       .reportTravelerWithGroupList(+this.tripId, +this.years)
  //       .subscribe((result) => {
  //         this.model = this.dataSource.data = result as TravelerEntity[]; //.fltier(e=>e.isTripAdded)
  //         this.dataSource.paginator = this.paginator;
  //       });
  //   }
  // }

  // changeYear() {
  //   this.tripId = '';
  //   this.tripOptionList = [];
  //   if (this.alltripList && this.alltripList.length > 0)
  //     this.tripOptionList = this.alltripList.filter(
  //       (t) => t.years === this.years
  //     );
  //   this.dataSource.data = [];
  // }

  // changeTrip() {
  //   this.travelerList();
  // }

  // applyFilter(filterValue: string) {
  //   filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  //   this.searchText = filterValue;
  //   this.dataSource.filter = filterValue;
  // }
}

// export interface TravelerEntity {
//   trip: string;
//   ppeWhsCode: string;
//   customerNumber: string;
//   companyName: string;
//   salesmanName: string;
//   groups: string;
//   firstName: string;
//   middleName: string;
//   lastName: string;
//   nickName: string;
//   traveler: string;
//   gender: string;
//   legalSuffix: string;
//   dateOfBirth: string;
//   cellPhone: string;
//   emailAddress: string;
//   isDepartureFlight: boolean;
//   isReturnFlight: boolean;
//   rentACar: string;
//   wheelChairRequest: string;
//   miscGroupRequest: string;
//   notes: string;
//   companyOwnerText:string;
// }
