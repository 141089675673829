import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { AirportService } from '../services/airport.service';
import { Router } from '@angular/router';
import { AirportAddEditModelPopupComponent } from '../airport-add-edit-model-popup/airport-add-edit-model-popup.component';

@Component({
	selector: 'app-airport-list',
	templateUrl: './airport-list.component.html',
	styleUrls: [ './airport-list.component.css' ]
})
export class AirportListComponent implements OnInit {
	public displayedColumns = [ 'airportCode', 'airportName', 'city', 'states','isActiveText', 'action','airportLayoverHotelCount' ];
	public dataSource = new MatTableDataSource<OrderListEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	constructor(private _airportService: AirportService, private _router: Router, private matDialog: MatDialog) {}

	ngOnInit() {
		this.airportList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	airportList() {
		//debugger;
		this._airportService.getAirportList().subscribe((result) => {
			this.dataSource.data = result as OrderListEntity[];
			//console.log(this.dataSource.data);
			this.dataSource.paginator = this.paginator;
		});
	}
	addUpdateAirport(id: number) {
		this._airportService.getAirportDetail(id).subscribe((result) => {
			if (result) this.model = result;
			else this.model = {};
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '500px';
			dialogConfig.panelClass="dap-model"
			dialogConfig.data = this.model;
			const modalDialog = this.matDialog.open(AirportAddEditModelPopupComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					this.airportList();
				}
			});
		});
	}
	deleteAirport(id: number) {
		if (confirm('Are you sure to delete the current record?')) {
			this._airportService.deleteAirport(id).subscribe((result) => {
				this.response = result;
				if (result && result.messageCode==1) {
					this.airportList();
				}
			});
		}
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	  }
}
export interface OrderListEntity {
	id: number;
	airportCode: string;
	airportName: string;
	states: string;
	city: string;
	isActiveText:string;
  airportLayoverHotelCount:number;
}
