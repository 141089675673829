import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";

let myAppUrl = "";
@Injectable({
  providedIn: "root",
})
export class EnrollmentNotesService {
  constructor(private _http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    myAppUrl = baseUrl;
  }

  save(data) {
    return this._http
      .post<any>(myAppUrl + "api/EnrollmentNotes/Save", data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getList(enrollmentId) {
    return this._http
      .get<any>(myAppUrl + "api/EnrollmentNotes/GetList/" + enrollmentId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
