import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { AllocatePointsService} from '../../services/allocate-points.service';
import { UtilityService } from '../../services/utility.service';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-issue-certificates',
  templateUrl: './issue-certificates.component.html',
  styleUrls: ['./issue-certificates.component.css']
})
export class IssueCertificatesComponent implements OnInit {
  model:any={};
  yearList :any=[];
  selected = new FormControl(1);
  years=new Date().getFullYear()-1;
  searchText='';
  selectAll: boolean = false;
  title:string='Issue Certificate';
  status:number=2;
  public displayedColumns =
   [ 'isSelected',
   'customerNumber',
   'companyName',
   'balancePoints',
   'status',
   'issueDate',
   'exportedOn'
  ];

	public dataSource = new MatTableDataSource<CustomerEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
  constructor(private allocatePointsService: AllocatePointsService, private _router: Router,
    private matDialog: MatDialog, private _utilityService:UtilityService) {

      if (localStorage.getItem('Certificates') != null) {
        this.response = JSON.parse(localStorage.getItem('Certificates'));
        localStorage.removeItem('Certificates');
      }
    let url=this._router.url
		if(url=='/issue-certificates')
		{
         this.status=2;
         this.title='Issue Certificate'
    }
    else if(url=='/export-certificates')
    {
      this.status=3;
      this.title='Export Certificates'
    }
    else if(url=='/exported-certificates')
    {
      this.status=4;
      this.title='Exported Certificates'
    }
    }

	ngOnInit() {
    this.yearList=this._utilityService.fillYear(new Date().getFullYear()-3, new Date().getFullYear()-1);
		this.customerList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	customerList() {
		this.allocatePointsService.getPointAllocation(this.years, this.status).subscribe((result) => {
      this.model=	this.dataSource.data = result.data as CustomerEntity[]; //.fltier(e=>e.isTripAdded)
      //console.log(this.model);
			this.dataSource.paginator = this.paginator;
		});
  }

  changeYear(){
	this.customerList() ;
  }

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText=filterValue;
		this.dataSource.filter = filterValue;
    }

    exportCerificate() {
    this.response={ };
     var issueData= this.model.filter(res=>res.isSelected);
     if(issueData.length>0)
     {
     var saveData={CustomerList:issueData, Status:this.status, Years: this.years}
     this.allocatePointsService.updateAllocationStatus(saveData).subscribe((result) => {

      if(result.messageCode==1)
      {
        localStorage.setItem('Certificates', JSON.stringify(result));
        this._router.navigate([ '/exported-certificates' ]);
        let { sheetName, fileName } = getFileName('Certificates');
        const exportData: Partial<CustomerEntity>[] = issueData.map(x => ({
          'Company Number': x.customerNumber,
          'Company Name': x.companyName,
          'Year':x.years,
          'Issue Date':x.issueDate,
          'Certificate Points':x.balancePoints
        }));

        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(exportData);

        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      }
      else this.response=	result;
      //console.log(this.response);
		//	this.dataSource.paginator = this.paginator;
		});
    }
    else{
    this.response={  isShow: true,
          message: "Please select at least one customer to issue certificate.",
          messageCode: 2}
    }
     // this.enrollmentService.getEnrollmentList_Export(this.years,this.searchText);
    }

    isAllChecked() {
     if(this.model && this.model.length>0)
      return this.model.every(obj => obj.isSelected);
     // console.log(this.model);
    }

    checkAll() {
      this.response={ };
       this.model.forEach(obj => obj.isSelected = this.selectAll);
       //console.log(this.model);
    }

    issueCerificate()
    {
      this.response={ };
     var issueData= this.model.filter(res=>res.isSelected);
     if(issueData.length>0)
     {
     var saveData={CustomerList:issueData, Status:this.status, Years: this.years}
     this.allocatePointsService.updateAllocationStatus(saveData).subscribe((result) => {
      this.response=	result;
      if(this.response.messageCode==1)
      {
      localStorage.setItem('Certificates', JSON.stringify(result));
      this._router.navigate([ '/export-certificates' ]);
      }
		});
    }
    else{
    this.response={  isShow: true,
          message: "Please select at least a customer for issue the certificate.",
          messageCode: 2}
    }
    }
    export() {
          var filteredData:any = this.dataSource.filteredData;
          let { sheetName, fileName } = getFileName('Exported Certificates');
          const exportData: Partial<CustomerEntity>[] = filteredData.map(x => ({
            'Company Number': x.customerNumber,
            'Company Name': x.companyName,
            'Year':x.years,
            'Certificate Points':x.balancePoints,
            'Issue Date':x.issueDate,
            'Exported On':x.exportedOn,
          }));
          var wb = XLSX.utils.book_new();
          var ws = XLSX.utils.json_to_sheet(exportData);
          XLSX.utils.book_append_sheet(wb, ws, sheetName);
          XLSX.writeFile(wb, `${fileName}.xlsx`);
      }
}

const getFileName = (name: string) => {
  let timeSpan = new Date().toISOString();
  let sheetName = name || "";
  let fileName = `${sheetName}-${timeSpan}`;
  return {
    sheetName,
    fileName
  };
};
export interface CustomerEntity {
  id: number;
  isSelected: boolean;
  customerNumber: string;
  companyName : string;
   balancePoints   : string;
   aRStatus : string;
   exportedOn:string;
   issueDate:string;
}



