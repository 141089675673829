import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { FormControl, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { EnrollmentService } from '../../services/enrollment.service';
import { TripSelectionComponent } from '../../Components/trip-selection/trip-selection.component';
import { TravelerComponent } from '../../Components/traveler/traveler.component';
import {
  MatDialog,
  MatDialogConfig,
  MatTabChangeEvent,
  MatTableDataSource,
} from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, switchMap } from 'rxjs/operators';
import { ItineraryComponent } from '../../Components/itinerary/itinerary.component';
import { AllocatePointsService } from 'src/app/services/allocate-points.service';
import { PointsOptionBreakUpComponent } from '../../Components/points-option-break-up/points-option-break-up.component';
import { PointsBreakUpComponent } from '../../Components/points-break-up/points-break-up.component';
import { TripEnrollmentNotesComponent } from '../popup/trip-enrollment-notes/trip-enrollment-notes.component';
import { PostTripMiscPointsComponent } from '../popup/post-trip-misc-points/post-trip-misc-points.component';
// import { TripenrolmentCustomerPointsStatusComponent } from "../tripenrolment-customer-points-status/tripenrolment-customer-points-status.component";

@Component({
  selector: 'app-trip-enrollment',
  templateUrl: './trip-enrollment.component.html',
  styleUrls: ['./trip-enrollment.component.css'],
})
export class TripEnrollmentComponent implements OnInit {
  model: any = {};
  pointModel: any = {};
  customerList: any = [];
  yearList: any = [];
  selected = new FormControl(1);
  selectedtab = new FormControl(1);
  response: any = {};
  myControl = new FormControl();
  customerId: number = 0;
  parmId = 0;
  enrollmentId: number = 0;
  isProceed = false;
  isEditDAPAdd = false;
  isEditDAP = false;
  isTripAdded = 1;
  sub: any;
  role = +localStorage.getItem('role');
  isEditCompanyInfo = false;
  commonMethodList = [];
  pCustomerList = [];
  custId;
  public dataSource = new MatTableDataSource<PointsEntity>();

  parentCompanyId: string = '';

  lastToLastYear = new Date().getFullYear() - 2;
  displayPostTripButton = false;
  displayLinkButton = false;
  selectedTabYear: number;
  public mask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  dapAddressModel: {
    dapAddress1: '';
    dapAddress2: '';
    dapAddress3: '';
    dapCity: '';
    dapState: '';
    dapZip: '';
  };
  years = new Date().getFullYear();
  totalRequiredPoints: any = 0.0;
  shortfallPoints: any = 0.0;
  achieved: any = 0.0;
  currentYear = new Date().getFullYear();
  lastYear = new Date().getFullYear() - 1;
  futureYear = new Date().getFullYear() + 1;
  balancePoints: any = 0.0;
  subjectCompanyName = '';
  allEmailTo = '';
  displayPendingAllocationText = false;
  isAllocationPending = 0;
  displayCurrentYearPointInfo = true;
  constructor(
    private customerService: CustomerService,
    private _router: Router,
    private enrollmentService: EnrollmentService,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private _utilityService: UtilityService,
    private fb: FormBuilder,
    private _allocatePointsService: AllocatePointsService
  ) { }

  ngOnInit() {
    this.commonMethodList = this._utilityService.getCommonMethod();
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 2,
      new Date().getFullYear() + 1
    );
    this.sub = this.route.params.subscribe((params) => {
      this.enrollmentId = this.parmId = +params['id']; // (+) converts string 'id' to a number
      this.isTripAdded = +params['isTripAdded'];
      if (params['isAllocationPending']) {
        this.isAllocationPending = +params['isAllocationPending'];
      } else {
        this.isAllocationPending = 0;  // Default value or any other logic
      }
      //console.log(this.isAllocationPending,this.isTripAdded,this.enrollmentId);
      
      // if (params['isAllocationPending']) {
      //   this.isAllocationPending = +params['isAllocationPending'];
      // }
    });
    if (this.enrollmentId > 0) {
      this.enrollmentService
        .getEnrollmentDetail(this.enrollmentId)
        .subscribe((result) => {
          this.model = result;
          this.custId = this.model.customerId;
          this.parentCompanyId = this.model.parentCompany;


          this.yearList = this.yearList.filter(function (y) {
            return y == result.years;
          });
          this.years = this.yearList[0];
          if (!this.years) {
            this.years = result.years;
          }
          this.getCurrentYearPointDetails();
          this.getARStatus3LinkDisplay(this.years, this.custId);

          this.subjectCompanyName = this.model.companyName.replace('&', 'and');

          if (this.model.salesmanEmail) {
            this.allEmailTo = this.model.salesmanEmail;
          }

          if (this.allEmailTo && this.model.branchManageEmail) {
            this.allEmailTo += '; ' + this.model.branchManageEmail;
          } else {
            this.allEmailTo = this.model.branchManageEmail;
          }

          if (this.allEmailTo && this.model.groupManagerEmail) {
            this.allEmailTo += '; ' + this.model.groupManagerEmail;
          } else {
            this.allEmailTo = this.model.groupManagerEmail;
          }

          if (this.allEmailTo) {
            this.allEmailTo += '; ' + 'awards@preferredpump.com';
          } else {
            this.allEmailTo = 'awards@preferredpump.com';
          }
         // console.log(result);
        });

      // If Page Comes from Pending AR Release Page then we will not check pending allocation count
      // debugger;
      if (this.isAllocationPending == 1) {
        // remove last year from year list
        this.displayCurrentYearPointInfo = false;
      }

      if (this.isAllocationPending == 0) {
        this.checkPendingAllocationForPreviousYear();
      }
    } else {
      this.enrollmentId = 0;
      this.enrollmentService.getCustomerNameList('').subscribe((result) => {
        this.customerList = result;
      });
    }
    if (this.isTripAdded == 0) {
      this.addTripSelection();
    }
    this.customerService
      .getCustomerNameList('', this.model.parentCompanyId)
      .subscribe((result) => {
        this.pCustomerList = result.filter(
          (x) => x.id != this.model.customerId
        );
      });
    if (+localStorage.getItem('isPoints')) {
      this.selectedtab.setValue(1);
      //localStorage.removeItem('isPoints');
    } else this.selectedtab.setValue(0);
  }

  checkPendingAllocationForPreviousYear() {
    this._allocatePointsService
      .getPendingArReleasedCount()
      .subscribe((result: any) => {
        if (result > 0) {
          this.displayPendingAllocationText = true;
        } else {
          this.displayPendingAllocationText = false;
        }
      });
  }

  tripenrollpopup_click() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = '700px';
    dialogConfig.data = {
      EnrollmentId: this.enrollmentId,
    };
    const modalDialog = this.matDialog.open(
      TripEnrollmentNotesComponent,
      dialogConfig
    );
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.response = result;
        this.response.isShow = true;

        // var regex_newline = new RegExp("(\r\n|\r|\n)");

        this.model.enrollmenNotes = result.notes.replace(
          /(?:\r\n|\r|\n)/g,
          '<br>'
        );
      }
    });
  }

  changeTab(index: number) {
    this.response = { isShow: false };
    if (this.parmId) {
    } else {
      this.years = this.yearList[index];
      this.enrollmentId = 0;
      this.customerId = 0;
      this.selected.setValue(index);
    }
  }

  private typed(value: string) {
    if (value.length >= 3) {
      var emptlist = [{ id: 0, customerName: 'No data found' }];
      this.customerList = [];
      this.enrollmentService.getCustomerNameList(value).subscribe((result) => {
        if (result && result.length > 0) this.customerList = result;
        else this.customerList = emptlist;
      });
    }
  }

  private select(value: number) {
    if (value > 0) {
      this.customerId = value;
      this.custId = value;
      this.isProceed = true;
    }
  }

  proceed() {
    this.enrollmentService
      .proceedEnrollment(this.customerId, this.years)
      .subscribe((result) => {
        if (result.messageCode == '1') {
          this.model = result.data;
          this.enrollmentId = this.model.id;
          this.isProceed = false;
          this.getCurrentYearPointDetails();
          this.getARStatus3LinkDisplay(this.years, this.customerId);
        } else {
          this.response = result;
        }
      });
  }
  addDAPAddress() {
    this.dapAddressModel = this.model;
    this.model.dapAddress1 = '';
    this.model.dapAddress2 = '';
    this.model.dapAddress3 = '';
    this.model.dapCity = '';
    this.model.dapState = '';
    this.model.dapZip = '';
    this.isEditDAPAdd = true;
  }
  cancelDAPAddress() {
    // this.dapAddressModel =this.model;
    this.model.dapAddress1 = this.dapAddressModel.dapAddress1;
    this.model.dapAddress2 = this.dapAddressModel.dapAddress2;
    this.model.dapAddress3 = this.dapAddressModel.dapAddress3;
    this.model.dapCity = this.dapAddressModel.dapCity;
    this.model.dapState = this.dapAddressModel.dapState;
    this.model.dapZip = this.dapAddressModel.dapZip;
    this.isEditDAPAdd = false;
  }
  updateDAPAddress(form: NgForm) {
    if (form.valid) {
      //this.enrollmentService.updateDAPAddress(this.model).subscribe((result) => {
      this.customerService
        .updateCustomerDAPAddress(this.model)
        .subscribe((result) => {
          if (result) {
            this.response = result;
            if (result.messageCode == 1) {
              //this.dapAddressModel = this.model;
              this.isEditDAPAdd = false;
            }
          }
        });
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
  }

  updatedCustomerDAP(form: NgForm) {
    if (form.valid) {
      this.customerService
        .updatedCustomerDAP(this.model)
        .subscribe((result) => {
          if (result) {
            this.response = result;
            if (result.messageCode == 1) {
              this.isEditDAP = false;
            }
          }
        });
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
  }
  addTripSelection() {
    this.response = { isShow: false };
    this.enrollmentService
      .getTripSelection(this.enrollmentId)
      .subscribe((result: any) => {
        //this.model = result;

        result.displayLinkButton = this.displayLinkButton; // GET AR Status
        result.years = this.years;
        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        // dialogConfig.id = "modal-component";
        dialogConfig.width = '650px';
        dialogConfig.data = result;
        const modalDialog = this.matDialog.open(
          TripSelectionComponent,
          dialogConfig
        );

        modalDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.response = result;
            if (result.messageCode == '1') {
              // && !this.parmId

              if (+localStorage.getItem('isPoints')) {
                this._router.navigate([
                  '/trip-enrollment-detail/' + this.enrollmentId,
                ]);
              } else {
                localStorage.setItem(
                  'tripOptionUpdate',
                  JSON.stringify(result)
                );
                this._router.navigate(['/trip-enrollment']);
              }
            }
          }
        });
      });
  }

  addTraveler() {
    this.response = { isShow: false };
    this.enrollmentService
      .getTripList(this.enrollmentId)
      .subscribe((result) => {
        //this.model = result;
        //added on 27/09
        result.displayLinkButton = this.displayLinkButton;
        result.years = this.years;

        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        // dialogConfig.id = "modal-component";
        dialogConfig.width = '1250px';
        dialogConfig.data = result;
        const modalDialog = this.matDialog.open(
          TravelerComponent,
          dialogConfig
        );

        modalDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.response = result;
            if (result.messageCode == 1 && this.parmId) {
              localStorage.setItem('tripOptionUpdate', JSON.stringify(result));
              this._router.navigate(['/trip-enrollment']);
            }
          }
        });
      });
  }

  addItinerary() {
    this.response = { isShow: false };
    this.enrollmentService
      .getTripList(this.enrollmentId)
      .subscribe((result) => {
        //this.model = result;

        //added on 27/09
        result.displayLinkButton = this.displayLinkButton;
        result.years = this.years;
        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        // dialogConfig.id = "modal-component";
        dialogConfig.width = '1250px';
        dialogConfig.data = result;
        const modalDialog = this.matDialog.open(
          ItineraryComponent,
          dialogConfig
        );

        modalDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.response = result;
            if (result.messageCode == 1 && this.parmId) {
              localStorage.setItem('tripOptionUpdate', JSON.stringify(result));
              this._router.navigate(['/trip-enrollment']);
            }
          }
        });
      });
  }

  deleteEnrollment() {
    this.response = { isShow: false };
    if (confirm('Are you sure you want to delete this trip enrollment?')) {
      this.enrollmentService
        .deleteEnrollment(this.enrollmentId, this.custId)
        .subscribe((result) => {
          this.response = result;
          if (this.response.messageCode == 1) {
            // debugger;
            localStorage.setItem('deleteEnroll', '1');
            this._router.navigate(['/trip-enrollment']);
          }
        });
    }
  }

  removeDAPAddress() {
    this.response = { isShow: false };
    if (confirm('Are you sure you want to delete this dap address?')) {
      this.model.dapAddress1 = '';
      this.model.dapAddress2 = '';
      this.model.dapAddress3 = '';
      this.model.dapCity = '';
      this.model.dapState = '';
      this.model.dapZip = '';
      this.model.IsDeleteDapAddress = true;
      this.customerService
        .updateCustomerDAPAddress(this.model)
        .subscribe((result) => {
          this.response = result;
          if (result) {
            if (result.messageCode == 1) {
              this.isEditDAPAdd = false;
            }
          }
        });
    }
  }

  updatedCompanyInfo(form: NgForm) {
    if (form.valid) {
      if (!this.model.lastYearSales) this.model.lastYearSales = 0;
      // console.log(this.model);
      this.model.LogUserId = this._utilityService.getCurrentUserId();
      this.customerService
        .updatedCompanyInfo(this.model)
        .subscribe((result) => {
          if (result) {
            this.response = result;
            if (result.messageCode == 1) {
              this.isEditCompanyInfo = false;
              this.enrollmentService
                .getEnrollmentDetail(this.enrollmentId)
                .subscribe((result) => {
                  this.model = result;
                });
            }
          }
        });
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
  }

  private typedP(value: string) {
    if (value.length >= 3) {
      var emptlist = [{ id: 0, customerName: 'No data found' }];
      this.pCustomerList = [];
      this.customerService
        .getCustomerNameList(value, this.model.parentCompanyId)
        .subscribe((result) => {
          if (result && result.length > 0) this.customerList = result;
          else this.pCustomerList = emptlist;
        });
    }
  }
  public displayedColumns = [
    'category',
    'destination',
    'trips',
    'points',
    'pointsOption',
    'total',
  ];
  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    // if (tabChangeEvent.index == 1) {
    //   debugger;
    //   this.getCurrentYearPointDetails();
    // }
    // Display Post Trip /Misc. Point Button for Last & Current Year
    this.selectedTabYear = 0;
    var _currentYear = new Date().getFullYear();
    var _lastYear = _currentYear - 1;
    if (tabChangeEvent.index == 2) {
      this.selectedTabYear = _lastYear;
    }
    if (tabChangeEvent.index == 3) {
      this.selectedTabYear = _currentYear;
    }
    if (tabChangeEvent.index == 3) {
      this.getARStatus(this.selectedTabYear, this.custId);
      this.getCurrentYearPointDetails();
    } else {
      this.displayPostTripButton = false;
    }
  }

  getCurrentYearPointDetails() {
    this._allocatePointsService
      .getCustomerWisePointsAllocation(this.enrollmentId, this.currentYear)
      .subscribe((result) => {
        this.pointModel = result;

        this.dataSource.data = this.pointModel
          .allocatedPointList as PointsEntity[];
        if (this.pointModel && this.pointModel.allocatedPointList.length > 0) {
          this.totalRequiredPoints = this.pointModel.allocatedPointList
            .map((a) => a.total)
            .reduce(function (a, b) {
              return (parseFloat(a) + parseFloat(b)).toFixed(2);
            });
        }
        //debugger;
        this.balancePoints = (
          +this.pointModel.lastYearRollOverPoints +
          +this.pointModel.lastYearEarnPoints -
          +this.pointModel.postTripPoints
        ).toFixed(2);

        this.shortfallPoints = (
          this.totalRequiredPoints -
          this.balancePoints -
          this.pointModel.postTripPoints
        ).toFixed(2);
        let achievedPT = (this.pointModel.lastYearBalance * 100) / this.totalRequiredPoints;
        achievedPT = isNaN(achievedPT) ? 0 : achievedPT;
        this.achieved = achievedPT > 100 ? 100.0 : achievedPT.toFixed(2);
        // If Achieved Point is in -ve
        if (this.achieved < 0) {
          this.achieved = 100.0;
        }
      });
  }
  getPointsOptionBreakUp(tripId: number, destination: string) {
    this._allocatePointsService
      .getPointsOptionBreakUp(tripId, this.pointModel.customerId)
      .subscribe((result) => {
        // console.log(result);
        let data = { destination: destination, pointList: result };
        //this.model = result;
        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        // dialogConfig.id = "modal-component";
        dialogConfig.width = '650px';
        dialogConfig.data = data;
        this.matDialog.open(PointsOptionBreakUpComponent, dialogConfig);
      });
  }

  getPointsBreakUp() {
    let data = {
      lastYearRollOverPoints: this.pointModel.lastYearRollOverPoints,
      lastYearEarnPoints: this.pointModel.lastYearEarnPoints,
      postTripPoints: this.pointModel.postTripPoints,
      lastYearBalance: this.pointModel.lastYearBalance,
      lastYearChildPoints: this.pointModel.lastYearChildPoints,
      isCustomerChildCompany: this.pointModel.isCustomerChildCompany,
      parentCustomerNumber: this.pointModel.parentCustomerNumber,
    };
    //this.model = result;
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = '350px';
    dialogConfig.data = data;
    this.matDialog.open(PointsBreakUpComponent, dialogConfig);
  }

  getARStatus(years, customerId) {
    this._allocatePointsService
      .getARStatusByCustomerIdAndYear(years, customerId)
      .subscribe((result: any) => {
        if (result.messageCode == 1) {
          this.displayPostTripButton = !result.data;
        } else {
          this.displayPostTripButton = true;
        }
      });
  }

  getARStatus3LinkDisplay(years, customerId) {
    //debugger;
    this._allocatePointsService
      .getARStatusByCustomerIdAndYear(years, customerId)
      .subscribe((result: any) => {
        if (result.messageCode == 1) {
          this.displayLinkButton = !result.data;
        } else {
          this.displayLinkButton = true;
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  SavePostTripAndMiscPoint() {
    // debugger;
    let data = {
      years: this.selectedTabYear,
      customerId: this.custId,
      ppeWhsCode: this.model.ppewhsCodeString,
    };

    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = data;
    const modalDialog = this.matDialog.open(
      PostTripMiscPointsComponent,
      dialogConfig
    );

    modalDialog.afterClosed().subscribe({
      next: (data) => {
        if (data) {
          this.response = data;
          // Update the AR Release status to released
          if (this.response.messageCode === 1) {
            this.getCurrentYearPointDetails();
          }
        }
      },
    });
  }
}
export interface PointsEntity {
  category: string;
  destination: string;
  trips: string;
  points: string;
  pointsOption: string;
  total: string;
  balance: string;
  pointType: number;
}
