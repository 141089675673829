import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AllocatePointsService } from 'src/app/services/allocate-points.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-catalog-point-import',
  templateUrl: './catalog-point-import.component.html',
  styleUrls: ['./catalog-point-import.component.css']
})
export class CatalogPointImportComponent implements OnInit {
	isSaved;
	isError;
	fileData: any = {};
	response: any = {};
	isImportSaveDisplay = false;
	yearList: any = [];
	submitted: boolean = false;
	importFileName;
	currentYear;
	public importForm: FormGroup;
	constructor(
		private _utilityService: UtilityService,
		private _allocatePointsService: AllocatePointsService,
		private _router: Router,
		private fb: FormBuilder
	) {
		this.currentYear = new Date().getFullYear()-1;

		this.importForm = this.fb.group({
			Year: [ this.currentYear, Validators.required],
			ImportFileName: [ '', Validators.required ]
		});
	}
	get f() {
		return this.importForm.controls;
	}
	ngOnInit() {
		this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear());
	}

	receiveMessage($event) {
		this.response = {};
		this.isError = 0;
		this.isSaved = 0;
		this.fileData = $event;
		if (this.fileData.messageCode == 1 && this.fileData.fileType == 5) {
			this.importFileName = this.fileData.data;
			this.f.ImportFileName.setValue(this.importFileName);
			this.fileData = {};
		}
	}

	importCatalogPoints() {
		this.submitted = true;

		if (this.importForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		if (this.importForm.invalid) {
			return;
		}

		let dataModel={
			ARYear: String(this.f.Year.value),
			ImportFileName: this.f.ImportFileName.value,
		};
		console.log(dataModel);
		this._allocatePointsService.importCatalogPoints(dataModel).subscribe((result) => {
			if (result) {
				this.submitted = false;
				this.response = result;
				this.importFileName = '';
				this.fileData = {};
				if (this.response.messageCode == '1') {
					this.response.Year = this.f.Year.value;
				 localStorage.setItem('CatalogPoint', JSON.stringify(result));
					this._router.navigate([ '/catalog-points' ]);
				}
			}
		});
	}
	sampleFileDownload(filename) {
		this._allocatePointsService.sampleFileDownload(filename);
	}
}
