import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { L } from '@angular/cdk/keycodes';

let myAppUrl = '';
@Injectable({
  providedIn: 'root',
})
export class ItineraryService {
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }

  getItineraryDetailList(tripId: number) {
    return this._http
      .get<any>(myAppUrl + 'api/Itinerary/ItineraryDetailList?tripId=' + tripId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getRegTravelerList(tripId: number) {
    return this._http
      .get<any>(myAppUrl + 'api/Itinerary/TravelerList?tripId=' + tripId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getRegisteredItineraryList(tripId: number) {
    return this._http
      .get<any>(
        myAppUrl + 'api/Itinerary/RegisteredItineraryList?tripId=' + tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getItineraryDetail(
    id: number,
    customerId: number,
    tripId: number,
    groups: number,
    isCopy: boolean,
    fromtraveler
  ) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/Itinerary/GetItineraryDetail?id=' +
          id +
          '&customerId=' +
          customerId +
          '&tripId=' +
          tripId +
          '&groups=' +
          groups +
          '&isCopy=' +
          isCopy +
          '&fromtraveler=' +
          fromtraveler
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  saveItinerary(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Itinerary/AddUpdateItinerary', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteItinerary(id: number, logUserId) {
    return this._http
      .post<any>(
        myAppUrl + 'api/Itinerary/ItineraryDelete/' + id + '/' + logUserId,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  copyItinerary(id: number) {
    return this._http
      .post<any>(myAppUrl + 'api/Itinerary/CopyItinerary?id=' + id, null)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getFlightTime(flightTimeparam) {
    return this._http
      .post<any>(myAppUrl + 'api/Itinerary/GetFlightTime', flightTimeparam)
      .pipe(
        map((data) => {
          return data;
        })
      );
    //return this._http.get<any>(myAppUrl + 'api/Itinerary/GetFlightTime?airportCodeId=' + airportCodeId+'&airline='+airline +'&flightNumber='+flightNumber+'&departureDate='+departureDate+'&isReturn='+isReturn)
  }
  getItineraryCopyList(
    tripId: number,
    customerId: number,
    groups: number,
    travelerId: number
  ) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/Itinerary/ItineraryForCopyList?tripId=' +
          tripId +
          '&customerId=' +
          customerId +
          '&groups=' +
          groups +
          '&travelerId=' +
          travelerId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getReportItineraryDeparture(year: number, tripId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/Itinerary/GetReportItineraryDeparture?year=' +
          year +
          '&tripId=' +
          tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getReportItineraryDepartureByEmployee(year: number, tripId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/Itinerary/GetReportItineraryDepartureByEmployee?year=' +
          year +
          '&tripId=' +
          tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getReportItineraryReturn(year: number, tripId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/Itinerary/GetReportItineraryReturn?year=' +
          year +
          '&tripId=' +
          tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getReportItineraryReturnByEmployee(year: number, tripId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/Itinerary/GetReportItineraryReturnByEmployee?year=' +
          year +
          '&tripId=' +
          tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  //added on 20/08/2024
  getReportItineraryDepartureReturn(year: number, tripId: number,IsReturn:number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/Itinerary/GetReportItineraryDeparture?year=' +
          year +
          '&tripId=' +
          tripId +
          '&IsReturn=' +
          IsReturn
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getMultipleItineraryHeader(year, tripId, isReturn, hotelId='') {
   //debugger;
    //console.log(isReturn);
    return this._http
      .get<any>(
        myAppUrl +
        'api/Itinerary/GetReportItineraryDepartureReturnColumns?year=' +
        year +
        '&tripId=' +
        tripId +
        '&isReturn=' +
        isReturn +
        (hotelId ? '&hotelId=' + hotelId : ''),
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
