import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
let myAppUrl = '';
@Injectable({
  providedIn: 'root',
})
export class AirportLayoverHotelService {
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  saveAirportLayoverHotel(model) {
    return this._http
      .post<any>(
        myAppUrl + 'api/AirportLayoverHotel/AddUpdateAirportLayoverHotel',
        model
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getAirportLayoverHotelDetail(id: number) {
    return this._http
      .get<any>(
        myAppUrl + 'api/AirportLayoverHotel/GetAirportLayoverHotelDetail/' + id
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getAirportLayoverHotelList(airportId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AirportLayoverHotel/AirportLayoverHotelList/' +
          airportId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteAirportLayoverHotel(id: number) {
    return this._http
      .post<any>(
        myAppUrl + 'api/AirportLayoverHotel/AirportLayoverHotelDelete/' + id,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAirportLayoverHotelForDropdownList(airportIds: any) {
    return this._http
      .post<any>(
        myAppUrl +
          'api/AirportLayoverHotel/AirportLayoverHotelForDropdownList/',
        airportIds
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
