import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-points-and-rewards-status-with-previous-year',
  templateUrl: './points-and-rewards-status-with-previous-year.component.html',
  styleUrls: ['./points-and-rewards-status-with-previous-year.component.css'],
})
export class PointsAndRewardsStatusWithPreviousYearComponent implements OnInit {
  data: any = {};
  response: any = {};
  settings: any;
  model: any = {};
  from: number = 1;
  to: number = 25;
  totalRecord: number;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private _utilityService: UtilityService
  ) {
    let yearMinusThree = new Date().getFullYear() - 3;
    let yearMinusTwo = new Date().getFullYear() - 2;
    let yearMinusOne = new Date().getFullYear() - 1;
    let currentYear = new Date().getFullYear();
    let PointsEarnedText = 'Points Earned (' + currentYear + ')';
    let RolledOverText = 'Rolled over (' + currentYear + ')';
    let PointsUsedText = 'Points Used (' + yearMinusOne + ')';
    this.settings = {
      columns: {
        customerNumber: {
          title: 'Company No.',
          sort: false,
        },
        companyName: {
          title: 'Company Name',
          type: 'html',
          sort: false,
        },
        yearMinuesThreePoints: {
          title: yearMinusThree,
          filter: false,
          sort: false,
        },
        yearMinuesTwoPoints: {
          title: yearMinusTwo,
          filter: false,
          sort: false,
        },
        yearMinuesOnePoints: {
          title: yearMinusOne,
          filter: false,
          sort: false,
        },

        previousRollOverPoints: {
          title: 'Rolled over from Prior Years',
          filter: false,
          sort: false,
        },
        totalPoints: {
          title: 'Total',
          filter: false,
          sort: false,
        },
        usedPoints: {
          title: PointsUsedText,
          filter: false,
          sort: false,
        },
        balancePoints: {
          title: 'Balance (' + yearMinusOne + ')',
          filter: false,
          sort: false,
        },
        futureRollOverPoints: {
          title: RolledOverText,
          filter: false,
          sort: false,
        },
        totalEarnPoints: {
          title: PointsEarnedText,
          filter: false,
          sort: false,
        },
        requiredPoints: {
          title: 'Required Points (' + currentYear + ')',
          filter: false,
          sort: false,
        },
        availablePoints: {
          title: 'Available Points (' + currentYear + ')',
          filter: false,
          sort: false,
        },
      },

      actions: {
        edit: false,
        add: false,
        delete: false,

        position: 'right',
      },
      noDataMessage: 'No record found.',
      pager: {
        display: true,
        perPage: 25,
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },
    };
    this.bindPointsList();
  }

  ngOnInit() {
    this.data.onChanged().subscribe((change) => {
      this.from = (change.paging.page - 1) * change.paging.perPage + 1;
      this.to =
        this.data.lastRequestCount <= change.paging.page * change.paging.perPage
          ? this.data.lastRequestCount
          : change.paging.page * change.paging.perPage;
    });
  }
  dropdownYearChange() {
    this.bindPointsList();
  }
  bindPointsList() {
    this.data = new ServerDataSource(this.http, {
      endPoint:
        this.baseUrl +
        'api/PointsAndRewards/GetCustomerPointsAndRewardsStatusWithPreviousYear',
      dataKey: 'data.data',
      totalKey: 'totalCount',
      pagerLimitKey: 'recordCount',
      pagerPageKey: 'pageNumber',
      filterFieldKey: '#field#',
      sortFieldKey: 'sortColumn',
      sortDirKey: 'sortOrder',
    });

    this.data.setPaging(1, 25, true);
  }

  exportPointAndRewards() {
    let filterData = this.data.getFilter();
    let extraSearch = '';
    if (filterData.filters.length > 0) {
      filterData.filters.forEach((element) => {
        extraSearch += '&' + element.field + '=' + element.search;
      });
    }
    window.open(
      this.baseUrl +
        'api/PointsAndRewards/GetCustomerPointsAndRewardsStatusWithPreviousYearToExport' +
        extraSearch
    );
  }
}
