import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { AllocatePointsService} from '../../services/allocate-points.service';
import { UtilityService } from '../../services/utility.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-catalog-point',
  templateUrl: './catalog-point.component.html',
  styleUrls: ['./catalog-point.component.css']
})
export class CatalogPointComponent implements OnInit {
  model:any={};
  yearList :any=[];
  selected = new FormControl(1);
  years=new Date().getFullYear()-1;
  searchText='';
  public displayedColumns =
   [
    'code',
    'item',
    'ppeCost',
    'points',
    'action'
  ];

	public dataSource = new MatTableDataSource<CatalogPointEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
  constructor(private allocatePointsService: AllocatePointsService, private _router: Router,
    private matDialog: MatDialog, private _utilityService:UtilityService) {

      if (localStorage.getItem('CatalogPoint') != null) {
        this.response = JSON.parse(localStorage.getItem('CatalogPoint'));
        localStorage.removeItem('CatalogPoint');
      }
    }

	ngOnInit() {
    this.yearList=this._utilityService.fillYear(new Date().getFullYear()-3, new Date().getFullYear());
		this.catalogPointList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	catalogPointList() {
		this.allocatePointsService.getCatalogPoint(this.years).subscribe((result) => {
      	this.dataSource.data = result as CatalogPointEntity[]; //.fltier(e=>e.isTripAdded)
			this.dataSource.paginator = this.paginator;
		});
  }

  changeYear(){
	this.catalogPointList() ;
  }
  deletePoint(id:number)
  {
    if (confirm('Are you sure to delete the current record?')) {
      this.allocatePointsService.deleteCatalogPoint(id).subscribe((result) => {
      	this.response= result;
        if(this.response.messageCode===1)
        {
        this.catalogPointList();
        }
		});
		}
  }
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText=filterValue;
		this.dataSource.filter = filterValue;
    }
}

export interface CatalogPointEntity {
  id: number;
  code: string;
  item : string;
  ppeCost   : string;
  points : string;
}

