import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ItineraryService } from '../../services/itinerary.service';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-copy-itinerary',
  templateUrl: './copy-itinerary.component.html',
  styleUrls: ['./copy-itinerary.component.css']
})
export class CopyItineraryComponent implements OnInit {
	public displayedColumns = [ 'traveler', 'companyName','ppeWhsCode', 'groups', 'action' ];
	public dataSource = new MatTableDataSource<ItineraryEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	constructor(
    private _itineraryService: ItineraryService,
    private _router: Router,
    public dialogRef: MatDialogRef<CopyItineraryComponent>,
	@Inject(MAT_DIALOG_DATA) public data,
    private matDialog: MatDialog) {}

	ngOnInit() {
		//this.airlineList();
		//console.log(this.data);
		this.dataSource.data = this.data as ItineraryEntity[];
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	onCloseClick(): void {
		this.dialogRef.close();
	}
	// airlineList() {
	// 	//debugger;
	// 	this._itineraryService.getAirlineList().subscribe((result) => {
	// 		this.dataSource.data = result as ItineraryEntity[];
	// 		//console.log(this.dataSource.data);
	// 		this.dataSource.paginator = this.paginator;
	// 	});
	// }
	selectItinerary(
		id: number,
		customerId: number,
		tripId: number,
		groups: number,
		fromTraveler: string

		) {
		// this._itineraryService.getAirlineDetail(id).subscribe((result) => {
		// 	this.dialogRef.close(result);
		// });
         let copyData={
		id: id,
		customerId: customerId,
		tripId: tripId,
		groups: groups,
        isCopy:false
		 }
		const confirmDialog = this.matDialog.open(ConfirmationDialogComponent, {
			data: {
				title: 'Copy Itinerary',
				message: 'Copy itinerary details from ' + fromTraveler + '?'
			}
		});

		confirmDialog.afterClosed().subscribe((result) => {
			if (result === true) {
				copyData.isCopy=true;
			}
			this.dialogRef.close(copyData);
		});

	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	  }
}
export interface ItineraryEntity {
   id: number;
   customerId:number;
  legalFirstName: string;
  legalMiddleName: string;
  legalLastName: string;
  legalSuffix: string;
  groups:number;
  companyName:string;
  ppeWhsCode:string;
  tripOptionsId: number;
}

