import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { TravelerService } from '../../services/traveler.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-report-room-roster',
  templateUrl: './report-room-roster.component.html',
  styleUrls: ['./report-room-roster.component.css'],
})
export class ReportRoomRosterComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = '';
  tripId = '';
  searchText = '';

  public displayedColumns = [
    'trip',
    'ppeWhsCode',
    'salesmanName',
    'customerNumber',
    'companyName',
    'groups',
    'traveler',
    'legalName',
    'nickName',
    'gender',
    'dateOfBirth',
    'preferredBedType',
    'specialRequests',
    'companyOwner',
    'wheelChairRequest',
    'drinkChoice',
    'datesOfStay',
    'totalEarnPoints',
  ];

  public dataSource = new MatTableDataSource<TravelerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  travelerList() {
    this.travelerService
      .reportRoomRoster(+this.tripId, +this.years)
      .subscribe((result) => {
        this.model = this.dataSource.data = result as TravelerEntity[]; //.fltier(e=>e.isTripAdded)
        this.dataSource.paginator = this.paginator;
      });
  }

  changeYear() {
    this.tripId = '';
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.travelerList();
  }

  changeTrip() {
    this.travelerList();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
}

export interface TravelerEntity {
  trip: string;
  ppeWhsCode: string;
  customerNumber: string;
  companyName: string;
  salesmanName: string;
  groups: string;
  nickName: string;
  traveler: string;
  legalName: string;
  gender: number;
  legalSuffix: string;
  companyOwner: boolean;
  totalEarnPoints: string;
  preferredBedType: string;
  specialRequests: string;
  wheelChairRequest: boolean;
  drinkChoice: boolean;
  datesOfStay: string;
}
