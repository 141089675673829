import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.css']
})
export class ReportDashboardComponent implements OnInit {

  currentYear: number;
  constructor() { 
    this.currentYear = new Date().getFullYear();
  }
  ngOnInit() {
  }

}
