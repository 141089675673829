import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  model: any = {};
  response: any = {};

  constructor(private userService: UserService, private router: Router) {

  }
  ngOnInit() {
    
  }

  getPasswordDetail(form: NgForm) {
    this.response= {};
    if (form.valid) {
      this.userService.sendPasswordDetail(this.model).subscribe(
        (result => {       
          if (result) {           
            this.response = result;         
          }  
          else
          this.response= {message:"Something went wrong, please try again.", messageCode:2,isShow:true};    
        })
      )
    } else {
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus();
    }
  }
}
