import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { TravelerService} from '../../services/traveler.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-report-mobile-app-attendees',
  templateUrl: './report-mobile-app-attendees.component.html',
  styleUrls: ['./report-mobile-app-attendees.component.css']
})
export class ReportMobileAppAttendeesComponent implements OnInit {
  model:any={};
  yearList :any=[];
  tripOptionList=[]
  years='';
  tripId='';
  searchText='';

  public displayedColumns =
   [ 'tripCode',
   'customerNumber',
   'firstName',
   'lastName',
   'nickName',
    'emailAddress', 
    'cellPhone',
    'groupManager',
    'ppeWhsCode',
    'checkInDate',
    'checkOutDate'
    ];

    

	public dataSource = new MatTableDataSource<TravelerEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
  constructor(
    private travelerService:TravelerService,
    public _utilityService:UtilityService,
    private _router: Router,
    ) {}
    
	ngOnInit() {
    this.yearList=this._utilityService.fillYear(new Date().getFullYear()-3, new Date().getFullYear()+1);
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	travelerList() {
    if(+this.years>0 && +this.tripId>0)
    {
		this.travelerService.getMobileAppReport(+this.years, +this.tripId).subscribe((result) => {
     this.model= this.dataSource.data = result as TravelerEntity[]; //.fltier(e=>e.isTripAdded)
     // console.log(result)
			this.dataSource.paginator = this.paginator;
		});
    }
     else this.dataSource.data=[];
  }
  

  changeYear()
	{
		this.tripId=''
		this.travelerService.getTripCodeList().subscribe((result) => {
			if (result) {
				if(result.length>0)
				this.tripOptionList=result.filter(t=> t.years===this.years);
			}				
      this.travelerList();
		});
	}

	changeTrip()
	{
	     this.travelerList();			
	}

  applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText=filterValue;
		this.dataSource.filter = filterValue;
    }
}

export interface TravelerEntity {
  trip:string
  cellPhone:string
  checkInDate:string
  checkOutDate:string
  city :string 
  state:string
  emailAddress:string
  nickName:string
  ppeWhsCode:string
  customerNumber:string,
  firstName:string,
  lastName:string,
  groupManager:string,
}


 