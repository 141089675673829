import { Injectable, Inject } from '@angular/core';   
import { map } from "rxjs/operators"; 
import {HttpClient} from '@angular/common/http'; 
let myAppUrl =""; 

@Injectable({
  providedIn: 'root'
})

export class RoomTypesService {

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {  
    myAppUrl = baseUrl;  
}

getRoomTypeDetail(id: number) {
  return this._http.get<any>(myAppUrl + 'api/RoomType/GetRoomType?id=' + id)
    .pipe(map(data => { return data; }))
}

saveRoomType(model)
{
  return this._http.post<any>(myAppUrl + 'api/RoomType/AddUpdateRoomType',model)
  .pipe(map(data => { return data;}))
}

getRoomTypeList() {     
  return this._http.get<any>(myAppUrl + 'api/RoomType/RoomTypeList')
  .pipe(map(data => { return data;}))
} 

deleteRoomType(id: number) {
  return this._http.post<any>(myAppUrl + 'api/RoomType/DeleteRoomType?id=' + id, null)
    .pipe(map(data => { return data; }))
}

}