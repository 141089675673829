import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilityService } from '../../services/utility.service';
import { EnrollmentService } from '../../services/enrollment.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'app-trip-selection',
	templateUrl: './trip-selection.component.html',
	styleUrls: [ './trip-selection.component.css' ]
})
export class TripSelectionComponent implements OnInit {
	public tripSelectionForm: FormGroup;
	response: any = {};
	submitted = false;
	isYesNos: any = [];
	isValid = 1;
	notSelected = 0;
	isValidGroup = true;
	tripNomessage="";
	LastYear = new Date().getFullYear() - 1;
	displaySaveButton = true;
	constructor(
		private enrollmentService: EnrollmentService,
		private utilityService: UtilityService,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<TripSelectionComponent>,
		@Inject(MAT_DIALOG_DATA) public model
	) {}

	ngOnInit() {
		this.isYesNos = this.utilityService.getIsYesNo();
		this.model.forEach((obj) => {
			if (obj._Id <= 0) {
				if (obj._NoOfTrips != 1) obj._NoOfTrips = null;
				//if (obj._Destination == 'Catalog - ' + new Date().getFullYear()) obj._NoOfTrips = 1;
			}
		});
		//this.airportList=this.data.airportList;
		this.model._NoOfTrips = 1;

		if(!this.model.displayLinkButton && this.LastYear==this.model.years)
		{
			this.displaySaveButton=false;
		}
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	onCloseClick(): void {
		this.dialogRef.close();
	}
	saveTripSelection() {
		this.submitted = true;
		this.isValidGroup=true;
		this.isValid = 1;
		var arr: any = [];
		this.model.forEach((obj) => {
			var arrModel: any = {
				_Id: obj._Id,
				_EnrollmentId: obj._EnrollmentId,
				_Destination: obj._Destination,
				_IsOptional: obj._IsOptional,
				_TripOptionsId: obj._TripOptionsId,
				_CustomerNumber: obj._CustomerNumber,
				_CompanyName: obj._CompanyName,
				_NoOfTrips: +obj._NoOfTrips
			};
			arr.push(arrModel);
		});
		this.model.forEach((sel) => {
			if(sel._NoOfTrips < sel._TravelerGroupCount)
			  {
				this.isValidGroup=false;
				this.tripNomessage="Please enter at least "+sel._TravelerGroupCount+" in "+sel._Destination+"."
				  return;
			  }
			});
		this.isValid = arr.filter((o) => o._NoOfTrips > 0).length;
		if (this.isValid)
			this.notSelected = this.model.filter((o) => o._NoOfTrips == null && o._IsOptional === true).length;
		var objModel = {
			_EnrollmentId: arr[0]._EnrollmentId,
			_TripSelections: arr,
      _LogUserId: this.utilityService.getCurrentUserId(),
		};
		if (this.isValid > 0 && this.notSelected == 0 && this.isValidGroup) {

			this.enrollmentService.saveTripSelection(objModel).subscribe((result) => {
				if (result) {
					if (result.messageCode == 1) {
						this.dialogRef.close(result);
					} else {
						this.response = result;
					}
				}
			});
		}
	}
}
