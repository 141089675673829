import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-traveler-update-confirmation',
  templateUrl: './traveler-update-confirmation.component.html',
  styleUrls: ['./traveler-update-confirmation.component.css'],
})
export class TravelerUpdateConfirmationComponent implements OnInit {
  pageTitle = '';
  constructor(
    public dialogRef: MatDialogRef<TravelerUpdateConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.pageTitle = data.pageTitle;
  }

  ngOnInit() {}
  onCloseClick(data: any): void {
    this.dialogRef.close(data);
  }
}
