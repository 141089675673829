import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { AllocatePointsService } from "src/app/services/allocate-points.service";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { CustomValidators } from "src/app/Directives/custom-validators";
import { UtilityService } from "src/app/services/utility.service";

@Component({
  selector: "app-post-trip-misc-points",
  templateUrl: "./post-trip-misc-points.component.html",
  styleUrls: ["./post-trip-misc-points.component.css"],
})
export class PostTripMiscPointsComponent implements OnInit {
  years: number;
  submitted: boolean = false;
  tripForm: FormGroup;
  model: any;
  customerId: any;
  tripWhsDefaultList: any = [];
  yearList: any = [];
  lastYear: number;
  responsePoint: any = {};
  constructor(
    public dialogRef: MatDialogRef<PostTripMiscPointsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private allocatePointsService: AllocatePointsService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.years = this.data.years;
    this.customerId = this.data.customerId;
    this.GetTripWhsDefaultDropdowns(this.years, this.data.ppeWhsCode);
    this.yearList.push(this.years);
    this.getPostTripPoints();
  }

  getPostTripPoints() {
    this.allocatePointsService
      .getPostTripPointByCustomer(this.years, this.data.customerId)
      .subscribe((result: any[]) => {
        if (result.length > 0) {
          this.generateForm();
          result.forEach((trip) => {
            this.addMoreTrip(trip);
          });
        } else {
          this.generateForm();
          this.addMoreTrip(null);
        }
      });
  }

  get f() {
    return this.tripForm.controls;
  }

  savePostTripPoint() {
    this.submitted = true;
    if (this.a_tripPoints.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName("ng-invalid")
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
    let dataSave = this.tripForm.value;
    dataSave.CustomerId = this.customerId;
    dataSave.Years = this.years;
    dataSave.UpdatedBy = this.utilityService.getCurrentUserId();
    this.allocatePointsService
      .savePostTripPoints(dataSave)
      .subscribe((result) => {
        this.responsePoint = result;

        if (this.responsePoint.messageCode === 1) {
          // this.getAllocationDetail();
          this.dialogRef.close(result);
        } else {
          this.responsePoint = {
            messageCode: 2,
            isShow: true,
            message: "Some thing went wrong, please try again.",
          };
        }
      });
  }

  generateForm() {
    this.tripForm = this.fb.group({
      tripPoints: this.fb.array([]),
    });
  }
  get a_tripPoints() {
    return this.f.tripPoints as FormArray;
  }
  addMoreTrip(trip): void {
    this.submitted = false;
    if (trip) this.a_tripPoints.push(this.editTrip(trip));
    else this.a_tripPoints.push(this.createTrip());
  }

  removeTrip(i: number) {
    if (confirm("Are you sure to delete the current record?")) {
      this.a_tripPoints.removeAt(i);
    }
  }

  createTrip() {
    return this.fb.group({
      TripWhsDefaultId: [
        "",
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Id: [0],
      Descriptions: ["", [Validators.required, CustomValidators.checkEmpty]],
      Points: ["", [Validators.required, CustomValidators.checkEmpty]],
      PointsValue: ["", [Validators.required, CustomValidators.checkEmpty]],
      //Years: [this.model.balancePoints >= 0 ? this.lastYear : this.currentYear],
      Years: [this.years],
    });
  }
  bindWHSCodeValue(event: any, index: number): void {
    // console.log(event.target.value);

    var selectedId = event.target.value;
    if (selectedId == "") {
      return;
    }

    if (selectedId > 0) {
      var selectedInfo = this.tripWhsDefaultList.filter(
        (x) => x.id == selectedId
      );

      // Bind Data into Post Trip New Row
      this.a_tripPoints.controls[index].patchValue({
        TripWhsDefaultId: selectedInfo[0].id,
        Points: selectedInfo[0].pointsPerTrip.toString(),
        PointsValue: selectedInfo[0].pointsValue,
      });
    } else {
      this.a_tripPoints.controls[index].patchValue({
        TripWhsDefaultId: 0,
        Points: null,
        PointsValue: null,
      });
    }
  }

  editTrip(trip) {
    return this.fb.group({
      TripWhsDefaultId: [
        trip.tripWhsDefaultId,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Id: [trip.id],
      Descriptions: [
        trip.descriptions,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Points: [trip.points, [Validators.required, CustomValidators.checkEmpty]],
      PointsValue: [
        trip.pointsValue,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Years: [trip.years],
    });
  }
  GetTripWhsDefaultDropdowns(years: number, ppeWhsCode: string) {
    this.allocatePointsService
      .getTripWhsDefaultDropdowns(years, ppeWhsCode)
      .subscribe((result) => {
        this.tripWhsDefaultList = result;
      });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 45) {
      return true;
    }
    else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberOnlyWithDecimal(event): boolean {

    console.log(event.keyCode)
    // allow negative key code
    if (event.keyCode == 189 || event.keyCode == 109) {
      return true;
    }

    var dotCount = 0;
    var checkNumberOnly = '';
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
      dotCount += 1;
      checkNumberOnly = event.target.value;
      var numericCheck = event.target.value.toString();
      if (numericCheck.includes('.')) {
        dotCount += 1;
      }
      if (dotCount > 1) {
        dotCount = 0;
        return false;
      }
    }
    if (charCode > 31 && (charCode < 45 || charCode > 57 || charCode == 47)) {
      return false;
    }

    checkNumberOnly = event.target.value;
    var checkString = '';
    if (checkNumberOnly != null) {
      var numeric = event.target.value.toString();
      if (numeric.includes('.')) {
        var checkNumeric = numeric.split('.');
        if (checkNumeric.length > 2) {
          return false;
        }
        // checkString = checkNumeric[1].split("");
        // if (checkString.length > 1) {
        //   return false;
        // }
      }
    }
    dotCount = 0;
    return true;
  }
  onCloseClick() {
    this.dialogRef.close();
  }
}
