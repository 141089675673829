import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
  MatDialogConfig,
} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AirportLayoverHotelService } from 'src/app/services/airport-layover-hotel.service';
import { AirportService } from 'src/app/services/airport.service';
import { AirportLayoverHotelSaveComponent } from '../airport-layover-hotel-save/airport-layover-hotel-save.component';

@Component({
  selector: 'app-airport-layover-hotels',
  templateUrl: './airport-layover-hotels.component.html',
  styleUrls: ['./airport-layover-hotels.component.css'],
})
export class AirportLayoverHotelsComponent implements OnInit {
  public displayedColumns = [
    'airportCode',
    'airportName',
    'layoverHotelName',
    'layoverHotelAddress',
    'layoverHotelPhone',
    'numberOfRoomsContracted',
    'recommendDisplay',
    'action',
  ];
  public dataSource = new MatTableDataSource<AirportLayoverHotelEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  airportModel: any = {};
  airportLayoverHotelModel: any = {};
  airportId = 0;
  constructor(
    private _airportLayoverHotelService: AirportLayoverHotelService,
    private _airportService: AirportService,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.airportId = +params['airportId'];
      this.getAirportDetail();
      this.airportLayoverHotelList();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getAirportDetail() {
    this._airportService
      .getAirportDetail(this.airportId)
      .subscribe((result) => {
        if (result) {
          this.airportModel = result;
        }
      });
  }

  airportLayoverHotelList() {
    this._airportLayoverHotelService
      .getAirportLayoverHotelList(this.airportId)
      .subscribe((result) => {
        this.dataSource.data = result as AirportLayoverHotelEntity[];
        this.dataSource.paginator = this.paginator;
      });
  }
  addUpdateAirportLayoverHotel(id: number) {
    //  //11/09/2023
    // id = 2;
    // console.log(id);
    //  id=0;
    this._airportLayoverHotelService
      .getAirportLayoverHotelDetail(id)
      .subscribe((result) => {
        if (result) {
          this.airportLayoverHotelModel = result;
          this.airportLayoverHotelModel.airportId = this.airportId;
          this.airportLayoverHotelModel.airportCode =
            this.airportModel.airportCode;
          this.airportLayoverHotelModel.airportName =
            this.airportModel.airportName;
        } else {
          this.airportLayoverHotelModel = {
            id: 0,
            airportId: this.airportId,
            airportCode: this.airportModel.airportCode,
            airportName: this.airportModel.airportName,
            recommend: 1,
            numberOfRoomsContracted:null,
          };
        }
        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.width = '650px';
        dialogConfig.panelClass = 'dap-model';
        dialogConfig.data = this.airportLayoverHotelModel;
        const modalDialog = this.matDialog.open(
          AirportLayoverHotelSaveComponent,
          dialogConfig
        );
        modalDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.response = result;
            this.airportLayoverHotelList();
          }
        });
      });
  }
  deleteAirportLayoverHotel(id: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this._airportLayoverHotelService
        .deleteAirportLayoverHotel(id)
        .subscribe((result) => {
          this.response = result;
          if (result && result.messageCode == 1) {
            this.airportLayoverHotelList();
          }
        });
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
export interface AirportLayoverHotelEntity {
  id: number;
  airportCode: string;
  airportName: string;
  layoverHotelName: string;
  layoverHotelAddress: string;
  layoverHotelPhone: string;
  numberOfRoomsContracted: number;
  recommendDisplay: string;
}
