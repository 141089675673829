import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AllocatePointsService } from "src/app/services/allocate-points.service";

@Component({
  selector: "app-post-trip-points",
  templateUrl: "./post-trip-points.component.html",
  styleUrls: ["./post-trip-points.component.css"],
})
export class PostTripPointsComponent implements OnInit {
  postTripPointList: any = [];
  years: number;
  constructor(
    public dialogRef: MatDialogRef<PostTripPointsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private allocatePointsService: AllocatePointsService
  ) {}
  ngOnInit() {
    //this.model = this.data;
    this.years = this.data.years;
    this.getPointDetail(this.data.years, this.data.customerId);
  }

  getPointDetail(years, customerId) {
    this.allocatePointsService
      .getPostTripPointByCustomer(years, customerId)
      .subscribe((result) => {
        this.postTripPointList = result;
      });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
