import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TravelerService} from '../../services/traveler.service';
import { UtilityService } from '../../services/utility.service';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-report-traveler-list',
  templateUrl: './report-traveler-list.component.html',
  styleUrls: ['./report-traveler-list.component.css']
})
export class ReportTravelerListComponent implements OnInit {
  model:any={};
  yearList :any=[];
  tripOptionList=[]
  years='';
  tripId='';
  searchText='';

  public displayedColumns =
   [ 'customerNumber',
    'companyName',
    'ppeWhsCode',
    'trip',
    'groups',
    'traveler',
    'firstName',
    'lastName',
    'nickName',
    'dateOfBirth',
    'cellPhone',
    'emailAddress',
    ];

	public dataSource = new MatTableDataSource<TravelerEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
  constructor(private travelerService: TravelerService, private _router: Router,
    private matDialog: MatDialog, public _utilityService:UtilityService) {}

	ngOnInit() {
    this.yearList=this._utilityService.fillYear(new Date().getFullYear()-1, new Date().getFullYear()+1);
		this.travelerList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	travelerList() {
		this.travelerService.reportTravelerList(+this.tripId,+this.years).subscribe((result) => {
     this.model= this.dataSource.data = result as TravelerEntity[]; //.fltier(e=>e.isTripAdded)
      // console.log(result)
			this.dataSource.paginator = this.paginator;
		});
  }


  changeYear()
	{
		this.tripId=''
		this.travelerService.getTripCodeList().subscribe((result) => {
			if (result) {
				if(result.length>0)
				this.tripOptionList=result.filter(t=> t.years===this.years);
			}
			this.travelerList();
		});
	}

	changeTrip()
	{
	     this.travelerList();
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText=filterValue;
		this.dataSource.filter = filterValue;
    }
}

export interface TravelerEntity {
    customerNumber: string
    companyName: string
    ppeWhsCode: string
    trip: string
    groups: string
    traveler: string
    firstName: string
    lastName: string
    nickName: string
    dateOfBirth: string
    cellPhone: string
    emailAddress: string
}


