import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { HotelDesignationService } from 'src/app/services/hotel-designation.service';
import { HotelDesignationAddEditPopupComponent } from './hotel-designation-add-edit-popup/hotel-designation-add-edit-popup.component';

@Component({
  selector: 'app-hotel-designation',
  templateUrl: './hotel-designation.component.html',
  styleUrls: ['./hotel-designation.component.css']
})
export class HotelDesignationComponent implements OnInit {

  public displayedColumns = [
    'hotelName',
    'hotelAddress',
    'hotelContactPersonName',
    'hotelContactNumber',
    'tripDestinationName',
    'isActiveText',
    'action'
  ];
  public dataSource = new MatTableDataSource<HotelDesignationModel>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  model = {};
  constructor(private _hotelService: HotelDesignationService,
    private _router: Router,
    private matDialog: MatDialog) { }

  ngOnInit() {
    this.hotelDesignationList();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  hotelDesignationList() {
    this._hotelService.getHotelDesignationList().subscribe((result) => {
      this.dataSource.data = result as HotelDesignationModel[];
      this.dataSource.paginator = this.paginator;
    })
  }
  addUpdateHotelDesignation(id: number) {
    this._hotelService.getHotelDesignationDetail(id).subscribe((result) => {
      if (result) {
        this.model = result;
      }
      else {
        this.model = {};
      }
      const dialogConfig = new MatDialogConfig();
      // The user can't close the dialog by clicking outside its body
      dialogConfig.disableClose = true;
      // dialogConfig.id = "modal-component";
      dialogConfig.width = '580px';
      dialogConfig.panelClass = 'dap-model';
      dialogConfig.data = this.model;
      const modalDialog = this.matDialog.open(HotelDesignationAddEditPopupComponent, dialogConfig);

      modalDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.response = result;
          this.hotelDesignationList();
        }
      });
    });
  }

  deleteHotelDesignation(id: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this._hotelService.deleteHotelDesignation(id).subscribe((result) => {
        this.response = result;
        if (result && result.messageCode == 1) {
          this.hotelDesignationList();
        }
      });
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
export interface HotelDesignationModel {
  id: number;
  tripCategoryId: number;
  tripDestinationName: string;
  destination: string;
  hotelName: string;
  hotelAddress: string;
  hotelContactPersonName: string;
  hotelContactNumber: string;
  tripDestinationID: number;
  isActive: boolean;
  isActiveText: string;
}