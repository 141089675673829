import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TripwhsdefaultsService } from "../services/tripwhsdefaults.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";

@Component({
  selector: "app-tripwhsdefaults-add-edit-popup",
  templateUrl: "./tripwhsdefaults-add-edit-popup.component.html",
  styleUrls: ["./tripwhsdefaults-add-edit-popup.component.css"],
})
export class TripwhsdefaultsAddEditPopupComponent implements OnInit {
  public tripwhsForm: FormGroup;
  whsList: any = [];
  tripOptionList: any = [];
  airlineList: any = [];
  response: any = {};
  submitted = false;
  showDeleteButton = false;
  role = +localStorage.getItem("role");
  showDDWhsCode = false;
  submitButtonText = "Save";
  minCheckOutDate;
  minReturnDate;
  title = "";
  model = {};
  dateMask = MaskedDate;
  minDate =
    (this.minReturnDate = this.minCheckOutDate =
      new Date(new Date().setFullYear(new Date().getFullYear() - 100)));
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100));
  constructor(
    private _tripwhsService: TripwhsdefaultsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TripwhsdefaultsAddEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    //this.airportList=this.data.airportList;
    this.generateForm();
  }
  get f() {
    return this.tripwhsForm.controls;
  }

  generateForm() {
    // console.log(this.data);
    if (this.data.id > 0) {
      this.title = "Edit Trip WHS Detail";
      this.showDeleteButton = this.role <= 2;
      this.submitButtonText = "Update";
      this.showDDWhsCode = true;
      if (this.data.checkInDateString)
        this.data.checkInDate = this.minCheckOutDate = new Date(
          this.data.checkInDate
        );
      else this.data.checkInDate = "";
      if (this.data.checkOutDateString)
        this.data.checkOutDate = new Date(this.data.checkOutDate);
      else this.data.checkOutDate = "";

      if (this.data.outgoingFlightDateString)
        this.data.outgoingFlightDate = this.minReturnDate = new Date(
          this.data.outgoingFlightDate
        );
      else this.data.outgoingFlightDate = "";
      if (this.data.returnFlightDateString)
        this.data.returnFlightDate = new Date(this.data.returnFlightDate);
      else this.data.returnFlightDate = "";

      if (this.data.weekDesignation == 0) {
        this.data.weekDesignation = "";
      }
    } else {
      this.title = "Add Trip WHS Detail";
      this.showDeleteButton = false;
      this.data.tripID = "";
      this.data.checkInDate = "";
      this.data.checkOutDate = "";
      this.data.outgoingFlightDate = "";
      this.data.returnFlightDate = "";
      this.data.pointsPerTrip = "";
      this.data.airLine = "";
      this.data.isActive = true;
      this.data.weekDesignation = 1;
    }
    this.whsList = this.data.whsList;
    this.tripOptionList = this.data.tripOptionList;
    this.airlineList = this.data.airLineList;
    this.tripwhsForm = this.fb.group({
      Id: [this.data.id, Validators.required],
      TripID: [this.data.tripID, Validators.required],
      WhsCodeID: [this.data.whsCodeID, [Validators.required]],
      CheckInDate: [this.data.checkInDate],
      CheckOutDate: [this.data.checkOutDate],
      OutgoingFlightDate: [this.data.outgoingFlightDate],
      ReturnFlightDate: [this.data.returnFlightDate],
      PointsPerTrip: [this.data.pointsPerTrip, [Validators.required]],
      Airline: [this.data.airLine ? this.data.airLine : ""], //[Validators.required],
      PointsValue: [this.data.pointsValue],
      IsActive: [this.data.isActive],
      WeekDesignation: [this.data.weekDesignation],
      WhsCodeList: this.fb.array([], minSelectedCheckboxes(1)),
    });
    this.addWhsCodeCheckbox();
  }
  private addWhsCodeCheckbox() {
    this.whsList.forEach((o, i) => {
      //const control = new FormControl(this.whsList[i].id == this.data.whsCodeID); // if first item set to true, else false
      const control = new FormControl(this.whsList[i].selected);
      (this.a_WhsCodeList as FormArray).push(control);
    });
  }
  get a_WhsCodeList() {
    return this.f.WhsCodeList as FormArray;
  }
  setMinCheckoutDate(filterValue: string) {
    if (filterValue) this.minCheckOutDate = filterValue;
  }
  setMinReturnDate(filterValue: string) {
    if (filterValue) this.minReturnDate = filterValue;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  saveTripWhs() {
    this.submitted = true;
    if (this.tripwhsForm.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName("ng-invalid")
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
    let dataSave = this.tripwhsForm.value;
    var id = dataSave.Id;
    if (!dataSave.Id) id = 0;

    // Get Selected Values
    // const selectedOrderIds = this.a_WhsCodeList.controls
    // 	.map((v, i) => (v ? this.whsList[i].id : null))
    // 	.filter((v) => v !== null && v.value===true);

    const selectedOrderIds = [];
    var count = 0;
    this.a_WhsCodeList.controls.forEach((element) => {
      //console.log(element);
      if (element.value) {
        selectedOrderIds.push(this.whsList[count].id);
      }
      count++;
    });

    //debugger;
    //console.log(selectedOrderIds);

    var checkInDate = "";
    var checkOutDate = "";

    var outgoingFlightDate = "";
    var returnFlightDate = "";

    if (dataSave.CheckInDate)
      checkInDate = new Date(dataSave.CheckInDate).toDateString();
    if (dataSave.CheckOutDate)
      checkOutDate = new Date(dataSave.CheckOutDate).toDateString();

    if (dataSave.OutgoingFlightDate)
      outgoingFlightDate = new Date(dataSave.OutgoingFlightDate).toDateString();
    if (dataSave.ReturnFlightDate)
      returnFlightDate = new Date(dataSave.ReturnFlightDate).toDateString();

    this.model = {
      Id: id,
      WhsCodeID: dataSave.WhsCodeID,
      TripID: dataSave.TripID,
      // CheckInDate: dataSave.CheckInDate,
      // CheckOutDate: dataSave.CheckOutDate,
      PointsPerTrip: Number(dataSave.PointsPerTrip),
      Airline: +dataSave.Airline,
      PointsValue: dataSave.PointsValue,
      IsActive: dataSave.IsActive,
      CheckInDateString: checkInDate,
      CheckOutDateString: checkOutDate,
      OutgoingFlightDateString: outgoingFlightDate,
      ReturnFlightDateString: returnFlightDate,
      WeekDesignation: Number(dataSave.WeekDesignation),
      WhsCodeIDs: selectedOrderIds,
    };

    // console.log(this.model);
    this._tripwhsService.saveTripWhs(this.model).subscribe((result) => {
      if (result) {
        if (result.messageCode == "1") {
          this.dialogRef.close(result);
        } else {
          this.response = result;
        }
      }
    });
  }

  deleteTripWhs() {
    var id = this.data.id;
    if (confirm("Are you sure to delete the current record?")) {
      this._tripwhsService.deleteTripWhs(id).subscribe((result) => {
        if (result.messageCode == "1") {
          this.dialogRef.close(result);
        } else {
          this.response = result;
        }
      });
    }
  }
  allWHSCode(event) {
    const checked = event.target.checked;
    this.a_WhsCodeList.controls.forEach((o, i) => {
      this.a_WhsCodeList.controls[i].setValue(checked);
    });
  }

  bindWHSCode() {
    let dataSave = this.tripwhsForm.value;
    //console.log(dataSave.TripID);
    this._tripwhsService
      .getWHSCodeListNotAddedWithSelectedTripId(dataSave.TripID)
      .subscribe((result) => {
        //console.log(result);
        if (result) {
          this.a_WhsCodeList.controls = [];
          this.whsList = result;
          this.addWhsCodeCheckbox();
        }
      });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberOnlyWithDecimal(event): boolean {
    var dotCount = 0;
    var checkNumberOnly = "";
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
      dotCount += 1;
      checkNumberOnly = event.target.value;
      var numericCheck = event.target.value.toString();
      if (numericCheck.includes(".")) {
        dotCount += 1;
      }
      if (dotCount > 1) {
        dotCount = 0;
        return false;
      }
    }
    if (charCode > 31 && (charCode < 45 || charCode > 57 || charCode == 47)) {
      return false;
    }
    checkNumberOnly = event.target.value;
    var checkString = "";
    if (checkNumberOnly != null) {
      var numeric = event.target.value.toString();
      if (numeric.includes(".")) {
        var checkNumeric = numeric.split(".");
        if (checkNumeric.length > 2) {
          return false;
        }
        checkString = checkNumeric[1].split("");
        if (checkString.length > 1) {
          return false;
        }
      }
    }
    dotCount = 0;
    return true;
  }
}
function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map((control) => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => (next ? prev + next : prev), 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}

// function cmvCheckoutDate(control: AbstractControl): { [key: string]: any } | null {
//   const email: string = control.value;
//   const domain = email.substring(email.lastIndexOf('@') + 1);
//   if (email === '' || domain.toLowerCase() === 'pragimtech.com') {
//     return null;
//   } else {
//     return { 'emailDomain': true };
//   }
// }

export function MaskedDate(event: any) {
  var v = event.target.value;
  if (v.match(/^\d{2}$/) !== null) {
    event.target.value = v + "/";
  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
    event.target.value = v + "/";
  }
}
