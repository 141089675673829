import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from '../services/customer.service';

@Component({
	selector: 'app-customer-import',
	templateUrl: './customer-import.component.html',
	styleUrls: [ './customer-import.component.css' ]
})
export class CustomerImportComponent implements OnInit {
	
	isSaved;
	isError;
	fileData: any = {};
	response: any = {};
	model: any = {};
	isImportSaveDisplay = false;
	constructor(private _customerService: CustomerService, private _router: Router) {}
	ngOnInit() {}
	
	receiveMessage($event) {
		this.response = {};
		this.isError = 0;
		this.isSaved = 0;
		this.fileData = $event;
		if (this.fileData.messageCode == 1 && this.fileData.fileType == 2) {
			this.model.customer_importFile = this.fileData.data;
			//console.log(this.model.customer_importFile);
			this.fileData = {};
		}
	}
	importCustomer(form: NgForm) {
		//console.log(this.model.customer_importFile);
		if (form.valid) {
			this._customerService.Import(this.model.customer_importFile).subscribe((result) => {
				//console.log(result);
				if (result) {
					this.response = result;
					this.model.customer_importFile='';
					this.fileData = {};
					if (result.messageCode == '1') {
						localStorage.setItem('customerImport', JSON.stringify(result));
						this._router.navigate([ '/companies' ]);
					}
				}
			});
		} else {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
	}
	sampleFileDownload(filename)
	{
		//window.open('https://localhost:44382/api/Salesman/SampleFileDownload?filename=salesmanimport.xlsx', '_blank');
		this._customerService.sampleFileDownload(filename);
	}
}