import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-points-and-rewards',
	templateUrl: './points-and-rewards.component.html',
	styleUrls: [ './points-and-rewards.component.css' ]
})
export class PointsAndRewardsComponent implements OnInit {
	data: any = {};
	response: any = {};
	settings: any;
	model: any = {};
	from: number = 1;
	to: number = 25;
	totalRecord: number;
	years = new Date().getFullYear();
	yearList: any = [];
	constructor(
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string,
		private _utilityService: UtilityService
	) {
		this.settings = {
			columns: {
				customerNumber: {
					title: 'Company No.',
					sort: false
				},
				companyName: {
					title: 'Company Name',
					type: 'html',
					sort: false
				},

				pointsJanuary: {
					title: 'Jan',
					filter: false,
					sort: false
				},
				pointsFebruary: {
					title: 'Feb',
					filter: false,
					sort: false
				},
				pointsMarch: {
					title: 'Mar',
					filter: false,
					sort: false
				},
				pointsApril: {
					title: 'Apr',
					filter: false,
					sort: false
				},
				pointsMay: {
					title: 'May',
					filter: false,
					sort: false
				},
				pointsJun: {
					title: 'Jun',
					filter: false,
					sort: false
				},
				pointsJuly: {
					title: 'Jul',
					filter: false,
					sort: false
				},
				pointsAugust: {
					title: 'Aug',
					filter: false,
					sort: false
				},
				pointsSeptember: {
					title: 'Sep',
					filter: false,
					sort: false
				},
				pointsOctober: {
					title: 'Oct',
					filter: false,
					sort: false
				},
				pointsNovember: {
					title: 'Nov',
					filter: false,
					sort: false
				},
				pointsDecember: {
					title: 'Dec',
					filter: false,
					sort: false
				}
			},

			actions: {
				edit: false,
				add: false,
				delete: false,

				position: 'right'
			},
			noDataMessage: 'No record found.',
			pager: {
				display: true,
				perPage: 25
			},
			attr: {
				class: 'table table color-table dark-table table-striped'
			}
		};
		this.bindPointsList(this.years);
	}

	ngOnInit() {
		this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear() + 1);
		this.model.Year = this.years;

		this.data.onChanged().subscribe((change) => {
			this.from = (change.paging.page - 1) * change.paging.perPage + 1;
			this.to =
				this.data.lastRequestCount <= change.paging.page * change.paging.perPage
					? this.data.lastRequestCount
					: change.paging.page * change.paging.perPage;
		});
	}
	dropdownYearChange() {
		this.bindPointsList(this.model.Year);
	}
	bindPointsList(year) {
		this.data = new ServerDataSource(this.http, {
			endPoint: this.baseUrl + 'api/PointsAndRewards/PointsAndRewardsList?year=' + year,
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});

		this.data.setPaging(1, 10, true);
	}
}
