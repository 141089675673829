import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-customer-statement-common',
  templateUrl: './report-customer-statement-common.component.html',
  styleUrls: ['./report-customer-statement-common.component.css'],
})
export class ReportCustomerStatementCommonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
