import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { TravelerService } from 'src/app/services/traveler.service';
import { UtilityService } from 'src/app/services/utility.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-no-airfare-needed',
  templateUrl: './report-no-airfare-needed.component.html',
  styleUrls: ['./report-no-airfare-needed.component.css'],
})
export class ReportNoAirfareNeededComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = '';
  tripId = '';
  searchText = '';

  public displayedColumns = [
    'ppeWhsCode',
    'customerNumber',
    'companyName',
    'groups',
    'firstName',
    'middleName',
    'lastName',
    'gender',
    'dateOfBirth',
    'isDepartureFlight',
    'isReturnFlight',
    'options1',
    'options2',
    'options3',
    'options4',
    'options5',
    'options6',
    'miscGroupRequest',
    'notes',
    'salesmanName',
    'trip',
  ];

  public dataSource = new MatTableDataSource<TravelerNoAirfareEntity>();

  @ViewChild(MatPaginator, { static: true })
  resultsLength = 0;
  pageSize = 25;
  pageEvent: PageEvent;

  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // ngAfterViewInit() {
  //   this.paginator.page.subscribe((event: PageEvent) => {
  //     this.pageEvent = event;
  //     this.travelerList(this.searchText);
  //   });
  // }

  travelerList(searchText) {
    this.dataSource.data = [];
    let pageIndex = this.pageEvent ? this.pageEvent.pageIndex + 1 : 1;
    const pageSize = this.pageEvent ? this.pageEvent.pageSize : this.pageSize;

    if (searchText) {
      pageIndex = 1;
    }

    this.travelerService
      .reportTravelerNoAirfareNeeded(+this.tripId, +this.years, pageIndex, pageSize, this.searchText)
      .subscribe((result) => {
        this.dataSource.data = result.data as TravelerNoAirfareEntity[]; //.fltier(e=>e.isTripAdded)
        this.resultsLength = result.totalCount;
        if (this.paginator) {
          this.paginator.pageSize = pageSize;
          this.paginator.length = this.resultsLength;
        }
      });
  }
  loadPage() {
    this.travelerList(this.searchText);
  }
  changeYear() {
    this.tripId = '';
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
    //this.travelerList();
  }
  changeTrip() {
    this.travelerList(this.searchText);
  }
  applyFilter(filterValue: string) {
    this.searchText = filterValue.trim().toLowerCase();
    this.travelerList(this.searchText);
  }
  exportToExcel(): void {
    const pageIndex = 1;
    const pageSize = this.resultsLength;

    if (+this.tripId > 0 && +this.years > 0) {
      this.travelerService
        .reportTravelerNoAirfareNeeded(+this.tripId, +this.years, pageIndex, pageSize, this.searchText)
        .subscribe((result) => {
          const data = result.data.map(item => {
            return {
              'Whs': item.ppeWhsCode,
              'Company#': item.customerNumber,
              'Company Name': item.companyName,
              'Groups': item.groups,
              'First Name': item.firstName,
              'Middle Name': item.middleName,
              'Last Name': item.lastName,
              //'Nick Name': item.nickName,
              'Gender': item.gender == 1 ? 'M' : 'F',
              'Date Of Birth': item.dateOfBirth,
              'Departure Flight': item.isDepartureFlight ? 'Y' : 'N',
              'Return Flight': item.isReturnFlight ? 'Y' : 'N',
              'Options1': item.options1,
              'Options2': item.options2,
              'Options3': item.options3,
              'Options4': item.options4,
              'Options5': item.options5,
              'Options6': item.options6,
              'Misc. Group Request': item.miscGroupRequest,
              'Notes': item.notes,
              'Salesman': item.salesmanName,
              'Trip#': item.trip
            };
          });
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'No-Airfare-Needed');
          XLSX.writeFile(wb, `No-Airfare-Needed${this._utilityService.getCurrentDateTime()}.xlsx`);
        });
    }
  }
}

export interface TravelerNoAirfareEntity {
  trip: string;
  ppeWhsCode: string;
  customerNumber: string;
  companyName: string;
  salesmanName: string;
  groups: string;
  nickName: string;
  traveler: string;
  firstName: string;
  middleName: string;
  lastName: string;
  legalSuffix: string;
  gender: string;
  dateOfBirth: string;
  cellPhone: string;
  isDepartureFlight: boolean;
  isReturnFlight: boolean;
  notes: string;
  miscGroupRequest: string;
  options1: string;
  options2: string;
  options3: string;
  options4: string;
  options5: string;
  options6: string;
}
