import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReportLogService } from 'src/app/services/report-log.service';
import { TravelerService } from 'src/app/services/traveler.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-report-log-deleted-flight',
  templateUrl: './report-log-deleted-flight.component.html',
  styleUrls: ['./report-log-deleted-flight.component.css'],
})
export class ReportLogDeletedFlightComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = '';
  tripId = '';
  searchText = '';

  public displayedColumns = [
    //'updatedOnDisplay',
    'formattedDate',
    'formattedTime',
    'updatedByName',
    'tripCode',
    'ppeWhsCode',
    'salesmanCode',
    'customerNumber',
    'companyName',
    'groups',
    'legalName',
    'gender',
    'nickName',
    'dateofBirthDisplay',
    'cellPhone',
    'emailAddress',
    'flightType',
    'flightRowNumber',
    'recordLocator',
    'ticketNumber',
    'ticketCost',
    'airlineCode',
    'flightNumber',
    'departureAirport',
    'departureDateDisplay',
    'departureTime',
    'arrivalAirport',
    'arrivalDateDisplay',
    'arrivalTime',
  ];

  public dataSource = new MatTableDataSource<DeletedTravelerFlightEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  deletedFlightList() {
    this.dataSource.data = [];
    if (+this.tripId > 0 && +this.years > 0) {
      this.reportLogService
        .getTravelerFlightDeletedList(this.years, this.tripId)
        .subscribe((result) => {
          this.model = this.dataSource.data =
            result as DeletedTravelerFlightEntity[];
          this.dataSource.paginator = this.paginator;
        });
    }
  }

  changeYear() {
    this.tripId = '';
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
  }

  changeTrip() {
    this.deletedFlightList();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
}

export interface DeletedTravelerFlightEntity {
  id: number;
  updatedOnDisplay: string;
  updatedByName: string;
  tripCode: string;
  ppeWhsCode: string;
  salesmanCode: string;
  customerNumber: string;
  companyName: string;
  groups: number;
  legalName: string;
  gender: number;
  nickName: string;
  dateofBirthDisplay: string;
  cellPhone: string;
  emailAddress: string;
  flightRowNumber: number;
  recordLocator: string;
  ticketNumber: string;
  ticketCost: number;
  airlineCode: string;
  flightNumber: string;
  departureAirport: string;
  departureDateDisplay: string;
  departureTime: string;
  arrivalAirport: string;
  arrivalDateDisplay: string;
  arrivalTime: string;
  flightType: string;
  formattedDate:string;
  formattedTime:string;
}
