import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ServerDataSource } from 'ng2-smart-table';
import { ViewlinkComponent } from '../viewlink/viewlink.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pending-ar-release',
  templateUrl: './pending-ar-release.component.html',
  styleUrls: ['./pending-ar-release.component.css'],
})
export class PendingArReleaseComponent implements OnInit {
  public dataSource = new MatTableDataSource<PendingArReleaseEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  data: any = {};
  settings: any;
  years = new Date().getFullYear();
  from: number = 1;
  to: number = 25;
  totalRecord: number;
  constructor(
    private http: HttpClient,
    private _router: Router,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.settings = {
      columns: {
        customerNumber: {
          title: 'Company No.',
          sort: false,
          type: 'custom',
          renderComponent: ViewlinkComponent,
        },
        CompanyName: {
          title: 'Company Name',
          sort: false,
          filter: false,
        },
        //Added on 20/10/2023
        ParentCustomerNumber: {
          title: 'Parent Company Number',
          sort: false,
          filter: false,
        },      
        EarnedPoints: {
          title: 'Total Earned Points',
          sort: false,
          filter: false,
          position: 'right',
        },
        RollOverPoints: {
          title: 'Rollover Points',
          sort: false,
          filter: false,
          position: 'right',
        },
        AvailablePoints: {
          title: 'Available Points',
          sort: false,
          filter: false,
          position: 'right',
        },
        PointsRequired: {
          title: 'Points Required',
          sort: false,
          filter: false,
          position: 'right',
        },
        TotalTransferredPoints: {
          title: 'Total Transferred Points',
          sort: false,
          filter: false,
        },
        PointsBalance: {
          title: 'Points Balance',
          sort: false,
          filter: false,
          position: 'right',
        },
      },
      noDataMessage: 'No record found.',
      actions: {
        edit: false,
        add: false,
        delete: false,
        position: 'right',
      },
      pager: {
        display: true,
        perPage: 25,
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },
    };
  }

  ngOnInit() {
    this.years = this.years - 1;
    this.pendingArReleaseList(this.years);
  }
  onCustom(event: any) {
    if (event.action == 'Edit') {
      this._router.navigate([
        '/trip-enrollment-detail-pending-allocation/' + event.data.id + '/' + 1,
      ]);
    }
  }

  pendingArReleaseList(year) {
    this.data = new ServerDataSource(this.http, {
      endPoint:
        this.baseUrl + 'api/TripEnrollment/PendingArReleasedList?year=' + year,
      dataKey: 'data.data',
      totalKey: 'totalCount',
      pagerLimitKey: 'recordCount',
      pagerPageKey: 'pageNumber',
      filterFieldKey: '#field#',
      sortFieldKey: 'sortColumn',
      sortDirKey: 'sortOrder',
    });
  }
  exportCustomer() {
    let filterData = this.data.getFilter();
    let extraSearch = '';
    if (filterData.filters.length > 0) {
      filterData.filters.forEach((element) => {
        extraSearch += '&' + element.field + '=' + element.search;
      });
    }
    window.open(
      this.baseUrl +
        'api/TripEnrollment/PendingArReleasedListExport?year=' +
        this.years +
        extraSearch
    );
  }
}

export interface PendingArReleaseEntity {
  customerNumber: string;
  companyName: string;
  earnPoints: number;
  totalTripPoints: number;
  balancePoints: number;
  parentCustomerNumber:string;
  totalTransferredPoints:number;
}
