import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm } from '@angular/forms';
import { UtilityService } from '../../services/utility.service';

@Component({
	selector: 'app-add-user-popup',
	templateUrl: './add-user-popup.component.html',
	styleUrls: [ './add-user-popup.component.css' ]
})
export class AddUserPopupComponent implements OnInit {
	//model: any = {roles:''};
	response: any = {};
	roleList = [];
	title: string = 'Add New User';
	role=+localStorage.getItem('role');
	public mask = [ '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];
	constructor(
		private _userService: UserService,
		public dialogRef: MatDialogRef<AddUserPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public model,
		private _utilityService: UtilityService
	) {}
	ngOnInit() {

		this.roleList = this._utilityService.getRoles();
		if (this.model.id > 0) this.title = 'Update User';
		else this.model.isActive = true;
	}
	onCloseClick(): void {
		this.dialogRef.close();
	}
	saveUserDetail(form: NgForm) {
		if (form.valid) {
      this.model.LogUserId = this._utilityService.getCurrentUserId();
			this._userService.saveUserDetail(this.model).subscribe((result) => {
				if (result) {
					if (result.messageCode == 1) {
						this.dialogRef.close(result);
					} else {
						this.response = result;
					}
				}
			});
		} else {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
	}
	deleteUser(id: number) {
		this.response = { isShow: false };
		this.model = { id: id };
		if (confirm('Are you sure to delete the current record?')) {
			this._userService.deleteUser(this.model).subscribe((result) => {
				this.response = result;
				if (result && result.messageCode == 1) {
					this.dialogRef.close(result);
				}
			});
		}
	}
}
