import { Component, OnInit } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { CustomerService } from '../../services/customer.service';
import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { CustomerDetailComponent } from '../../Components/customer-detail/customer-detail.component';

@Component({
	selector: 'app-new-customer-list',
	templateUrl: './new-customer-list.component.html',
	styleUrls: [ './new-customer-list.component.css' ]
})
export class NewCustomerListComponent implements OnInit {
	data: any={};
	response: any = {};
	settings: any;
	model: any;
	from :number=1; to:number=25; totalRecord:number;
	//myAppUrl
	constructor(
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string,
		private matDialog: MatDialog,
		private customerService: CustomerService
	) {
		this.data = new ServerDataSource(http, {
			endPoint: baseUrl + 'api/Customer/CustomerListPaging',
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});

		this.settings = {
			columns: {
				customerNumber: {
					title: 'Company Number',
					sort: false
				},
				companyName: {
					title: 'Company Name',
					type: 'html',
					sort: false
				},

				ppewhsCodeString: {
					title: 'WHS Code',
					sort: false
				},
				ppeSalesmanCodeString: {
					title: 'Salesman Code',
					sort: false
				},
				companyTypeString: {
					title: 'Company Type',
					filter: false,
					sort: false
				},
				sxeAddress: {
					title: 'Sxe Address',
					filter: false,
					sort: false
				}
			},

			actions: {
				edit: false,
				add: false,
				delete: false,
				custom: [
					{
						name: 'Edit',
						title: `<span  class="rounded-circle px-2 btn-gray py-1 border mr-2 text-secondary"><i aria-hidden="true" class="mb-0 fa fa-pencil"></i> </span>`
					}
				],
				position: 'right'
			},
			noDataMessage: "No record found.",
			pager: {
				display: true,
				perPage: 25
			},
			attr: {
				class: 'table table color-table dark-table table-striped'
			}
		};
		this.data.setPaging(1, 10, true);
	}

	ngOnInit() {
		if (localStorage.getItem('customerImport') != null) {
			this.response = JSON.parse(localStorage.getItem('customerImport'));
			localStorage.removeItem('customerImport');
		}
		this.data.onChanged().subscribe((change) => {			
			this.from=((change.paging.page-1)*change.paging.perPage)+1;
			this.to=this.data.lastRequestCount <=change.paging.page*change.paging.perPage?this.data.lastRequestCount:change.paging.page*change.paging.perPage;
		});
	}
	onCustom(event: any) {
		if (event.action == 'Edit') {
			this.updateCustomer(event.data.id);
		}
	}
	updateCustomer(id: number) {
		this.customerService.getCustomerDetail(id).subscribe((result) => {
			if (result) this.model = result;
			else this.model = {};
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '1000px';
			dialogConfig.panelClass = 'dap-model';
			dialogConfig.data = this.model;
			const modalDialog = this.matDialog.open(CustomerDetailComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					this.data = new ServerDataSource(this.http, {
						endPoint: this.baseUrl + 'api/Customer/CustomerListPaging',
						dataKey: 'data.data',
						totalKey: 'totalCount',
						pagerLimitKey: 'recordCount',
						pagerPageKey: 'pageNumber',
						filterFieldKey: '#field#',
						sortFieldKey: 'sortColumn',
						sortDirKey: 'sortOrder'
					});
			
				}
			});
		});
	}
}
