import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PointsAndRewardsService } from 'src/app/services/points-and-rewards.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-import-points-and-rewards',
	templateUrl: './import-points-and-rewards.component.html',
	styleUrls: [ './import-points-and-rewards.component.css' ]
})
export class ImportPointsAndRewardsComponent implements OnInit {
	isSaved;
	isError;
	fileData: any = {};
	response: any = {};
	isImportSaveDisplay = false;
	yearList: any = [];
	monthList: any = [];
	submitted: boolean = false;
	importFileName;
	currentMonth;
	currentYear;
	public importForm: FormGroup;
	constructor(
		private _utilityService: UtilityService,
		private _pointsAndRewardsService: PointsAndRewardsService,
		private _router: Router,
		private fb: FormBuilder
	) {
		this.currentYear = new Date().getFullYear();
		this.currentMonth = new Date().getMonth() + 1;

		this.importForm = this.fb.group({
			PointYear: [ this.currentYear, Validators.required ],
			PointMonth: [ this.currentMonth, Validators.required ],
			ImportFileName: [ '', Validators.required ]
		});
	}
	get f() {
		return this.importForm.controls;
	}
	ngOnInit() {
		this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear() + 1);
		this.monthList = this._utilityService.getMonthList();
	}

	receiveMessage($event) {
		this.response = {};
		this.isError = 0;
		this.isSaved = 0;
		this.fileData = $event;
		if (this.fileData.messageCode == 1 && this.fileData.fileType == 3) {
			this.importFileName = this.fileData.data;
			this.f.ImportFileName.setValue(this.importFileName);
			this.fileData = {};
		}
	}

	importCustomer() {
		this.submitted = true;

		if (this.importForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		if (this.importForm.invalid) {
			return;
		}

		let dataModel={
			PointYear: String(this.f.PointYear.value),
			PointMonth: String(this.f.PointMonth.value),
			ImportFileName: this.f.ImportFileName.value,
		};
		// console.log(dataModel);
		this._pointsAndRewardsService.Import(dataModel).subscribe((result) => {
			if (result) {
				this.submitted = false;
				this.response = result;
				this.importFileName = '';
				// this.f.ImportFileName.setValue('');
				// this.f.PointYear.setValue(this.currentYear);
				// this.f.PointMonth.setValue(this.currentMonth);
				this.fileData = {};
				if (this.response.messageCode == '1') {
					this.response.PointYear = this.f.PointYear.value;
					this.response.PointMonth = this.f.PointMonth.value;
					localStorage.setItem('PointsAndRewardImport', JSON.stringify(result));
					this._router.navigate([ '/imported-points-and-rewards' ]);
				}
			}
		});
	}
	sampleFileDownload(filename) {
		this._pointsAndRewardsService.sampleFileDownload(filename);
	}
}
