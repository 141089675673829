import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

let myAppUrl = '';
@Injectable({
	providedIn: 'root'
})
export class PointsAndRewardsService {
	constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		myAppUrl = baseUrl;
	}

	Import(data) {
		return this._http.post<any>(myAppUrl + 'api/PointsAndRewards/Import', data).pipe(
			map((data) => {
				return data;
			})
		);
	}
	getPointsAndRewardsList(pointYear, pointMonth) {
		return this._http
			.get<any>(myAppUrl + 'api/PointsAndRewards/GetPointsAndRewardsList/' + pointYear + '/' + pointMonth)
			.pipe(
				map((data) => {
					return data;
				})
			);
	}
	sampleFileDownload(model) {
		window.open(myAppUrl + 'api/PointsAndRewards/SampleFileDownload?filename=' + model, '_blank');
	}

	ImportAR(data) {
		return this._http.post<any>(myAppUrl + 'api/AllocatePoints/ImportAR', data).pipe(
			map((data) => {
				return data;
			})
		);
	}
}
