import { Injectable, Inject } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

let myAppUrl = "";
@Injectable({
  providedIn: 'root'
})
export class PointOptionsService {

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  savePointOptions(model) {
    return this._http.post<any>(myAppUrl + 'api/PointOptions/AddUpdatePointOptions', model)
      .pipe(map(data => { return data; }))
  }
  getPointOptionsDetail(id: number) {
    return this._http.get<any>(myAppUrl + 'api/PointOptions/GetPointOptionsDetail?id=' + id)
      .pipe(map(data => { return data; }))
  }
  getPointOptionsList(tripId:number) {
    return this._http.get<any>(myAppUrl + 'api/PointOptions/PointOptionsList?tripId=' + tripId)
      .pipe(map(data => { return data; }))
  }
  
  deletePointOptions(id: number) {
    return this._http.post<any>(myAppUrl + 'api/PointOptions/PointOptionsDelete?id=' + id, null)
      .pipe(map(data => { return data; }))
  }
}