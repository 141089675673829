import { Injectable, Inject } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

let myAppUrl = "";
@Injectable({
  providedIn: 'root'
})
export class TripoptionsService {

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  saveTripOption(model) {
    return this._http.post<any>(myAppUrl + 'api/TripOption/AddUpdateTripOption', model)
      .pipe(map(data => { return data; }))
  }
  getTripOptionDetail(id: number) {
    return this._http.get<any>(myAppUrl + 'api/TripOption/GetTripOptionDetail?id=' + id)
      .pipe(map(data => { return data; }))
  }
  getTripOptionList() {
    return this._http.get<any>(myAppUrl + 'api/TripOption/TripOptionList')
      .pipe(map(data => { return data; }))
  }
  getTripOptionListActive() {
    return this._http.get<any>(myAppUrl + 'api/TripOption/TripOptionListActive')
      .pipe(map(data => { return data; }))
  }
  deleteTripOption(id: number) {
    return this._http.post<any>(myAppUrl + 'api/TripOption/TripOptionDelete?id=' + id, null)
      .pipe(map(data => { return data; }))
  }

  saveTripOptionDestinationHotels(model){
    return this._http.post<any>(myAppUrl + 'api/TripOption/SaveTripOptionHotels', model)
      .pipe(map(data => { return data; }))
  }
}
