import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AirportListComponent } from "./airport-list/airport-list.component";
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";


import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatNativeDateModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
} from "@angular/material";
import { MatTableExporterModule } from "mat-table-exporter";
import { AirportAddEditModelPopupComponent } from "./airport-add-edit-model-popup/airport-add-edit-model-popup.component";
import { NgxSelectModule } from "ngx-select-ex";
import { AuthGuard } from "./auth.guard";
import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogService } from "./services/confirmation-dialog.service";
import { TextMaskModule } from "angular2-text-mask";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { AppRoutingModule } from "./app-routing.module";
import { SecureComponent } from "./layouts/secure/secure.component";
import { CustomerImportComponent } from "./customer-import/customer-import.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { AddUserComponent } from "./Components/add-user/add-user.component";
import { PageNotFoundComponent } from "./Components/page-not-found/page-not-found.component";
import { LoaderComponent } from "./Components/loader/loader.component";
import { LoaderInterceptor } from "./Interceptors/loader.interceptor";
import { LoaderService } from "./services/loader.service";
import { UserListComponent } from "./Components/user-list/user-list.component";
import { AddUserPopupComponent } from "./Components/add-user-popup/add-user-popup.component";
import { WhsListComponent } from "./whs-list/whs-list.component";
import { SubmenuListComponent } from "./nav-menu/submenu-list/submenu-list.component";
import { WhsAddEditPopupComponent } from "./whs-add-edit-popup/whs-add-edit-popup.component";
import { CustomerListComponent } from "./Components/customer-list/customer-list.component";
import { CustomerDetailComponent } from "./Components/customer-detail/customer-detail.component";
import { SalesmanAddEditPopupComponent } from "./salesman-add-edit-popup/salesman-add-edit-popup.component";
import { TripOptionAddEditPopupComponent } from "./trip-option-add-edit-popup/trip-option-add-edit-popup.component";
import { SalesmanListComponent } from "./salesman-list/salesman-list.component";
import { TripOptionsComponent } from "./trip-options/trip-options.component";
import { TripwhsdefaultsAddEditPopupComponent } from "./tripwhsdefaults-add-edit-popup/tripwhsdefaults-add-edit-popup.component";
import { TripwhsdefaultsListComponent } from "./tripwhsdefaults-list/tripwhsdefaults-list.component";
import { RoomTypesListComponent } from "./Components/room-types-list/room-types-list.component";
import { RoomTypesComponent } from "./Components/room-types/room-types.component";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { TripEnrollmentComponent } from "./Components/trip-enrollment/trip-enrollment.component";
import { TripEnrollmentListComponent } from "./Components/trip-enrollment-list/trip-enrollment-list.component";
import { FileuploadComponent } from "./fileupload/fileupload.component";
import { SalesmanImportComponent } from "./salesman-import/salesman-import.component";
import { NewCustomerListComponent } from "./Components/new-customer-list/new-customer-list.component";
import { TripSelectionComponent } from "./Components/trip-selection/trip-selection.component";
import { ReportEnrolledComponent } from "./Components/report-enrolled/report-enrolled.component";
import { EmptyNameValidatorDirective } from "./Directives/emptyValidator.directive";
import { ReportNotEnrolledComponent } from "./Components/report-not-enrolled/report-not-enrolled.component";
import { AirlineListComponent } from "./Components/airline-list/airline-list.component";
import { AirlineComponent } from "./Components/airline/airline.component";
import { TravelerComponent } from "./Components/traveler/traveler.component";
import { NewTripEnrollmentListComponent } from "./Components/new-trip-enrollment-list/new-trip-enrollment-list.component";
import { ReportCutomerStatementComponent } from "./Components/report-cutomer-statement/report-cutomer-statement.component";
import { TripEnrollmentTestComponent } from "./Components/trip-enrollment-test/trip-enrollment-test.component";
import { ReportTravelerComponent } from "./Components/report-traveler/report-traveler.component";
import { ViewlinkComponent } from "./Components/viewlink/viewlink.component";
//import { DateMaskDirective } from './Directives/date-mask.directive';
import { PointOptionsComponent } from "./Components/point-options/point-options.component";
import { PointOptionsListComponent } from "./Components/point-options-list/point-options-list.component";
import { GroupOptiosComponent } from "./Components/group-optios/group-optios.component";
import { ItineraryComponent } from "./Components/itinerary/itinerary.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { TimezonePickerModule } from "ng2-timezone-selector";
import { TimeMaskDirective } from "./Directives/time-mask.directive";
import { CopyItineraryComponent } from "./Components/copy-itinerary/copy-itinerary.component";
import { PointsAndRewardsComponent } from "./Components/points-and-rewards/points-and-rewards.component";
import { ImportPointsAndRewardsComponent } from "./Components/points-and-rewards/import-points-and-rewards/import-points-and-rewards.component";
import { ImportPointsListComponent } from "./Components/points-and-rewards/import-points-list/import-points-list.component";
import { PointsAndRewardsStatusComponent } from "./Components/points-and-rewards/points-and-rewards-status/points-and-rewards-status.component";
import { PointsAndRewardsStatusWithPreviousYearComponent } from "./Components/points-and-rewards/points-and-rewards-status-with-previous-year/points-and-rewards-status-with-previous-year.component";
import { AllocatePointsComponent } from "./Components/allocate-points/allocate-points.component";
import { ImportArReleaseComponent } from "./Components/import-ar-release/import-ar-release.component";
import { IssueCertificatesComponent } from "./Components/issue-certificates/issue-certificates.component";
import { CustomerArReleasedComponent } from "./Components/customer-ar-released/customer-ar-released.component";
import { TripenrolmentCustomerPointsStatusComponent } from "./Components/tripenrolment-customer-points-status/tripenrolment-customer-points-status.component";
import { PointsOptionBreakUpComponent } from "./Components/points-option-break-up/points-option-break-up.component";
import { CatalogPointImportComponent } from "./Components/catalog-point-import/catalog-point-import.component";
import { CatalogPointComponent } from "./Components/catalog-point/catalog-point.component";
import { PointsBreakUpComponent } from "./Components/points-break-up/points-break-up.component";
import { CustomerListPointsComponent } from "./Components/customer-list-points/customer-list-points.component";
import { ReportTravelerListComponent } from "./Components/report-traveler-list/report-traveler-list.component";
import { ReportTravelerWithGroupComponent } from "./Components/report-traveler-with-group/report-traveler-with-group.component";
import { ReportTravelerSpecialRequestsComponent } from "./Components/report-traveler-special-requests/report-traveler-special-requests.component";
import { ReportRoomRosterComponent } from "./Components/report-room-roster/report-room-roster.component";
import { ReportLayoverHotelsComponent } from "./Components/report-layover-hotels/report-layover-hotels.component";
import { ReportRoomRosterGroupWiseComponentComponent } from "./Components/report-room-roster-group-wise-component/report-room-roster-group-wise-component.component";
import { ReportShippingComponent } from "./Components/report-shipping/report-shipping.component";
import { ReportAirfareItineraryComponent } from "./Components/report-airfare-itinerary/report-airfare-itinerary.component";
import { ReportItineraryDepartureComponent } from "./Components/report-itinerary-departure/report-itinerary-departure.component";
import { ReportItineraryReturnComponent } from "./Components/report-itinerary-return/report-itinerary-return.component";
import { ReportMobileAppSpeakerComponent } from "./Components/report-mobile-app-speaker/report-mobile-app-speaker.component";
import { ReportMobileAppAttendeesComponent } from "./Components/report-mobile-app-attendees/report-mobile-app-attendees.component";
import { ReportItineraryWithMultipleFlightComponent } from "./Components/report-itinerary-with-multiple-flight/report-itinerary-with-multiple-flight.component";
import { ReportYtdCustomerStatementComponent } from "./Components/report-ytd-customer-statement/report-ytd-customer-statement.component";
import { ReportTravelerPointsOptionComponent } from "./Components/report-traveler-points-option/report-traveler-points-option.component";
import { ReportCustomerStatementCommonComponent } from "./Components/report-customer-statement-common/report-customer-statement-common.component";
import { ReportItineraryDepartureByEmployeeComponent } from "./Components/report-itinerary-departure-by-employee/report-itinerary-departure-by-employee.component";
import { ReportItineraryReturnByEmployeeComponent } from "./Components/report-itinerary-return-by-employee/report-itinerary-return-by-employee.component";
import { ReportDashboardComponent } from "./Components/report-dashboard/report-dashboard.component";
import { ReportNoAirfareNeededComponent } from "./Components/report-no-airfare-needed/report-no-airfare-needed.component";
import { ReportLogDeletedTravelerComponent } from "./Components/ReportLog/report-log-deleted-traveler/report-log-deleted-traveler.component";
import { ReportLogUpdatedTravelerComponent } from "./Components/ReportLog/report-log-updated-traveler/report-log-updated-traveler.component";
import { ReportLogDeletedFlightComponent } from "./Components/ReportLog/report-log-deleted-flight/report-log-deleted-flight.component";
import { ReportLogDeletedLayoverHotelsComponent } from "./Components/ReportLog/report-log-deleted-layover-hotels/report-log-deleted-layover-hotels.component";
import { ReportLogGroupDetailUpdateComponent } from "./Components/ReportLog/report-log-group-detail-update/report-log-group-detail-update.component";
import { ReportLogTravelerHotelUpdateComponent } from "./Components/ReportLog/report-log-traveler-hotel-update/report-log-traveler-hotel-update.component";
import { ReportLogTravelerLayoverHotelUpdateComponent } from "./Components/ReportLog/report-log-traveler-layover-hotel-update/report-log-traveler-layover-hotel-update.component";
import { ReportLogTravelerFlightUpdateComponent } from "./Components/ReportLog/report-log-traveler-flight-update/report-log-traveler-flight-update.component";
import { ReportTravelFormInfoByRoomComponent } from "./Components/report-travel-form-info-by-room/report-travel-form-info-by-room.component";
import { PointsDetailBreakupComponent } from "./Components/popup/points-detail-breakup/points-detail-breakup.component";
import { TripEnrollmentNotesComponent } from "./Components/popup/trip-enrollment-notes/trip-enrollment-notes.component";
import { UsedInCatalogComponent } from "./Components/popup/used-in-catalog/used-in-catalog.component";
import { PostTripPointsComponent } from "./Components/popup/post-trip-points/post-trip-points.component";
import { PurchasedCatalogPointsComponent } from "./Components/popup/purchased-catalog-points/purchased-catalog-points.component";
import { PostTripMiscPointsComponent } from "./Components/popup/post-trip-misc-points/post-trip-misc-points.component";
import { ReportTravelerCommonComponent } from './Components/report-traveler-common/report-traveler-common.component';
import { TravelersByUserComponent } from './travelers-by-user/travelers-by-user.component';
import { ReportYearEndCustomerStatementsComponent } from "./Components/report-year-end-customer-statements/report-year-end-customer-statements.component";
import { CustomerSxeReportComponent } from "./Components/customer-sxe-report/customer-sxe-report.component";
import { ReportAirfareManifestComponent } from './Components/report-airfare-manifest/report-airfare-manifest.component';
import { ReportMasterTravelerManifestComponent } from './Components/report-master-traveler-manifest/report-master-traveler-manifest.component';

//Added on 08/09/23 By Chandan
import { ReportLogCustomerParentChildRelationComponent } from "./Components/ReportLog/report-log-customer-parent-child-relation/report-log-customer-parent-child-relation.component";
import { ReportLogTripSelectionComponent } from "./Components/ReportLog/report-log-trip-selection/report-log-trip-selection.component";
import { ReportLogRolloverPointsComponent } from "./Components/ReportLog/report-log-rollover-points/report-log-rollover-points.component";
import { AirportLayoverHotelsComponent } from './airport-list/airport-layover-hotels/airport-layover-hotels.component';
import { AirportLayoverHotelSaveComponent } from './airport-list/airport-layover-hotel-save/airport-layover-hotel-save.component';
import { PendingArReleaseComponent } from "./Components/pending-ar-release/pending-ar-release.component";
import { TravelerUpdateConfirmationComponent } from './Components/popup/traveler-update-confirmation/traveler-update-confirmation.component';
import { HotelDesignationComponent } from "./Components/hotel-designation/hotel-designation.component";
import { HotelDesignationAddEditPopupComponent } from './Components/hotel-designation/hotel-designation-add-edit-popup/hotel-designation-add-edit-popup.component';
import { ReportLogOriginalTripSelectionComponent } from "./Components/ReportLog/report-log-original-trip-selection/report-log-original-trip-selection.component";
import { ReportLogOriginalGroupRegistrationComponent } from "./Components/ReportLog/report-log-original-group-registration/report-log-original-group-registration.component";
import { ReportLogNameBadgeComponent } from "./Components/ReportLog/report-log-name-badge/report-log-name-badge.component";
import { ReportLogHistoricalPointComponent } from "./Components/ReportLog/report-log-historical-point/report-log-historical-point.component";
import { ReportLogHistoricalTravelerComponent } from "./Components/ReportLog/report-log-historical-traveler/report-log-historical-traveler.component";
import { ReportRoomLetterListComponent } from "./Components/ReportLog/report-room-letter-list/report-room-letter-list.component";
import { ReportCatalogPointComponent } from "./Components/report-catalog-point/report-catalog-point.component";
import { ReportNightlyCountComponent } from "./Components/report-nightly-count/report-nightly-count.component";
import { CommonModule, DatePipe } from "@angular/common";
import { ReportInitialFlightPurchasedComponent } from "./Components/report-initial-flight-purchased/report-initial-flight-purchased.component";
import { ReportItineraryDeparturesReturnComponent } from "./Components/report-itinerary-departures-return/report-itinerary-departures-return.component";




//import {NgxMaskModule, IConfig} from 'ngx-mask';
//export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    ConfirmationDialogComponent,
    FooterComponent,
    HeaderComponent,
    DashboardComponent,
    LoginComponent,
    SecureComponent,
    ForgotPasswordComponent,
    PageNotFoundComponent,
    AddUserComponent,
    AirportListComponent,
    AirportAddEditModelPopupComponent,
    LoaderComponent,
    UserListComponent,
    AddUserPopupComponent,
    WhsListComponent,
    SubmenuListComponent,
    WhsAddEditPopupComponent,
    CustomerListComponent,
    CustomerDetailComponent,
    SalesmanListComponent,
    SalesmanAddEditPopupComponent,
    TripOptionsComponent,
    TripOptionAddEditPopupComponent,
    TripwhsdefaultsAddEditPopupComponent,
    TripwhsdefaultsListComponent,
    RoomTypesListComponent,
    RoomTypesComponent,
    TripEnrollmentComponent,
    TripEnrollmentListComponent,
    FileuploadComponent,
    SalesmanImportComponent,
    NewCustomerListComponent,
    TripSelectionComponent,
    CustomerImportComponent,
    ReportEnrolledComponent,
    EmptyNameValidatorDirective,
    ReportNotEnrolledComponent,
    AirlineListComponent,
    AirlineComponent,
    TravelerComponent,
    NewTripEnrollmentListComponent,
    ReportCutomerStatementComponent,
    TripEnrollmentTestComponent,
    ReportTravelerComponent,
    ViewlinkComponent,
    //DateMaskDirective,
    PointOptionsComponent,
    PointOptionsListComponent,
    GroupOptiosComponent,
    ItineraryComponent,
    TimeMaskDirective,
    CopyItineraryComponent,
    PointsAndRewardsComponent,
    ImportPointsAndRewardsComponent,
    ImportPointsListComponent,
    PointsAndRewardsStatusComponent,
    PointsAndRewardsStatusWithPreviousYearComponent,
    AllocatePointsComponent,
    ImportArReleaseComponent,
    IssueCertificatesComponent,
    CustomerArReleasedComponent,
    TripenrolmentCustomerPointsStatusComponent,
    PointsOptionBreakUpComponent,
    CatalogPointImportComponent,
    CatalogPointComponent,
    PointsBreakUpComponent,
    CustomerListPointsComponent,
    ReportTravelerListComponent,
    ReportTravelerWithGroupComponent,
    ReportTravelerSpecialRequestsComponent,
    ReportRoomRosterComponent,
    ReportLayoverHotelsComponent,
    ReportRoomRosterGroupWiseComponentComponent,
    ReportShippingComponent,
    ReportAirfareItineraryComponent,
    ReportItineraryDepartureComponent,
    ReportItineraryReturnComponent,
    ReportMobileAppSpeakerComponent,
    ReportMobileAppAttendeesComponent,
    ReportItineraryWithMultipleFlightComponent,
    ReportYtdCustomerStatementComponent,
    ReportTravelerPointsOptionComponent,
    ReportCustomerStatementCommonComponent,
    ReportItineraryDepartureByEmployeeComponent,
    ReportItineraryReturnByEmployeeComponent,
    ReportDashboardComponent,
    ReportNoAirfareNeededComponent,
    ReportLogDeletedTravelerComponent,
    ReportLogUpdatedTravelerComponent,
    ReportLogDeletedFlightComponent,
    ReportLogDeletedLayoverHotelsComponent,
    ReportLogGroupDetailUpdateComponent,
    ReportLogTravelerHotelUpdateComponent,
    ReportLogTravelerLayoverHotelUpdateComponent,
    ReportLogTravelerFlightUpdateComponent,
    ReportTravelFormInfoByRoomComponent,
    PointsDetailBreakupComponent,
    TripEnrollmentNotesComponent,
    UsedInCatalogComponent,
    PostTripPointsComponent,
    PurchasedCatalogPointsComponent,
    PostTripMiscPointsComponent,
    ReportTravelerCommonComponent,
    TravelersByUserComponent,
    ReportYearEndCustomerStatementsComponent,
    CustomerSxeReportComponent,
    ReportAirfareManifestComponent,
    ReportMasterTravelerManifestComponent,
    ReportLogCustomerParentChildRelationComponent,
    ReportLogRolloverPointsComponent,
    ReportLogTripSelectionComponent,
    AirportLayoverHotelsComponent,
    AirportLayoverHotelSaveComponent,
    PendingArReleaseComponent,
    TravelerUpdateConfirmationComponent,
    HotelDesignationComponent,
    HotelDesignationAddEditPopupComponent,
    ReportLogOriginalTripSelectionComponent,
    ReportLogOriginalGroupRegistrationComponent,
    ReportLogNameBadgeComponent,
    ReportLogHistoricalPointComponent,
    ReportLogHistoricalTravelerComponent,
    ReportRoomLetterListComponent,
    ReportCatalogPointComponent,
    ReportNightlyCountComponent,
    ReportInitialFlightPurchasedComponent,
    ReportItineraryDeparturesReturnComponent
  ],
  imports: [  
    BrowserModule,
    HttpClientModule,
    FormsModule,
    NgxSelectModule,
    TextMaskModule,
    AppRoutingModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    Ng2SmartTableModule,
    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatTableExporterModule,
    NgxMaterialTimepickerModule,
    TimezonePickerModule,
    FormsModule,
    CommonModule
  ],
  providers: [
    AuthGuard,
    LoaderService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  entryComponents: [
    CustomerDetailComponent,
    TripSelectionComponent,
    RoomTypesComponent,
    ConfirmationDialogComponent,
    WhsAddEditPopupComponent,
    AirportAddEditModelPopupComponent,
    WhsAddEditPopupComponent,
    SalesmanAddEditPopupComponent,
    TripOptionAddEditPopupComponent,
    AddUserPopupComponent,
    TripwhsdefaultsAddEditPopupComponent,
    ViewlinkComponent,
    AirlineComponent,
    TravelerComponent,
    PointOptionsComponent,
    GroupOptiosComponent,
    ItineraryComponent,
    ConfirmationDialogComponent,
    CopyItineraryComponent,
    PointsOptionBreakUpComponent,
    PointsBreakUpComponent,
    PointsDetailBreakupComponent,
    TripEnrollmentNotesComponent,
    UsedInCatalogComponent,
    PostTripPointsComponent,
    PurchasedCatalogPointsComponent,
    PostTripMiscPointsComponent,
    AirportLayoverHotelSaveComponent,
    TravelerUpdateConfirmationComponent,
    HotelDesignationAddEditPopupComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
