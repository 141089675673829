import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  PageEvent,
} from '@angular/material';
import { EnrollmentService } from '../../services/enrollment.service';
import { UtilityService } from '../../services/utility.service';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-report-enrolled',
  templateUrl: './report-enrolled.component.html',
  styleUrls: ['./report-enrolled.component.css'],
})
export class ReportEnrolledComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  selected = new FormControl(1);
  years = 0;
  searchText = '';
  public displayedColumns = [
    'ppewhsCodeString',
    'customerNumber',
    'companyName',
    'enrolledText',
    'lastYearSales',
    'ppeSalesmanCodeString',
    'tripEnrolled',
  ];

  public dataSource = new MatTableDataSource<CustomerEntity>();
  @ViewChild(MatPaginator, { static: true })
  resultsLength = 0;
  pageSize = 25;
  pageEvent: PageEvent;
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  customerNumber: string;
  constructor(
    private enrollmentService: EnrollmentService,
    private _utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  customerList(searchText: string) {
    let pageIndex = this.pageEvent ? this.pageEvent.pageIndex + 1 : 1;
    let pageSize = this.pageEvent ? this.pageEvent.pageSize : this.pageSize;

    if (searchText) {
      pageIndex = 1;
    }

    this.enrollmentService
      .getEnrollmentListReport(+this.years, pageIndex, pageSize, searchText)
      .subscribe((result) => {
        this.dataSource.data = result.data as CustomerEntity[];
        this.dataSource.paginator = this.paginator;
        this.resultsLength = result.totalCount;

        if (this.paginator) {
          this.paginator.length = this.resultsLength;
        }
      });
  }
  changeYear() {
    this.customerList(this.searchText);
  }
  loadPage() {
    this.customerList(this.searchText);
  }

  applyFilter(filterValue: string) {
    this.searchText = filterValue.trim().toLowerCase();
    if (this.paginator) {
      if (this.searchText) {
        this.paginator.firstPage();
      }
    }
    this.customerList(this.searchText);
  }
  exportCustomer() {
    // let timeSpan = new Date().toISOString();
    // let prefix = name || "Enrolled_Customer";
    // let fileName = `${prefix}-${timeSpan}`;
    // let targetTableElm = document.getElementById("tblEnrolledCustomer");
    // let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{ sheet: prefix });
    // XLSX.writeFile(wb, `${fileName}.xlsx`);

    this.enrollmentService.getEnrollmentList_Export(
      this.years,
      this.searchText
    );
  }

  exportToExcel(): void {
    const pageIndex = 1;
    const pageSize = this.resultsLength;

    this.enrollmentService
      .getEnrollmentListReport(+this.years, pageIndex, pageSize, this.searchText)
      .subscribe((result) => {
        const data = result.data.map(item => {
          return {
            'Whs': item.ppewhsCodeString,
            'Company#': item.customerNumber,
            'Company Name': item.companyName.replace(/&#39;/g, "'"),
            'Enrolled': item.enrolledText,
            "Last Year's Sales": item.lastYearSales,
            'Salesman Code': item.ppeSalesmanCodeString,
            'Trip Enrolled': item.tripEnrolled
          };
        });
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Enrolled-and-Unenrolled');
        XLSX.writeFile(wb, `Enrolled-and-Unenrolled-Customers${this._utilityService.getCurrentDateTime()}.xlsx`);
      });
  }
}
export interface CustomerEntity {
  id: number;
  customerNumber: string;
  companyName: string;
  dapContact: string;
  dapPhone: string;
  ppewhsCodeString: string;
  ppeSalesmanCodeString: string;
  tripEnrolled: string;
  lastYearSales: number;
  enrolledText: string;
}
