import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { ReportLogService } from "src/app/services/report-log.service";
import { TravelerService } from "src/app/services/traveler.service";
import { UtilityService } from "src/app/services/utility.service";

@Component({
  selector: "app-report-log-group-detail-update",
  templateUrl: "./report-log-group-detail-update.component.html",
  styleUrls: ["./report-log-group-detail-update.component.css"],
})
export class ReportLogGroupDetailUpdateComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = "";
  tripId = "";
  searchText = "";

  public displayedColumns = [
    //"updatedOnDisplay",
    'formattedDate',
    'formattedTime',
    "updatedByName",
    "tripCode",
    "ppeWhsCode",
    "salesmanCode",
    "customerNumber",
    "companyName",
    "groups",
    "checkInDate",
    "checkInDateOld",
    "checkOutDate",
    "checkOutDateOld",
    "prefRoomType",
    "prefRoomTypeOld",
    "miscGroupRequest",
    "miscGroupRequestOld",
    "miscGroupPoint",
    "miscGroupPointOld",
    "rentACar",
    "rentACarOld",
   // "wheelChairRequest",
    //"wheelChairRequestOld",

    // "companyOwner",
    // "companyOwnerOld",
    "firstClass",
    "firstClassOld",
    "weekDesignation",
    "weekDesignationOld",
    "hotelName",
    "hotelNameOld",
    "option1",
    "optionId1Old",
    "optionId2",
    "optionId2Old",
    "optionId3",
    "optionId3Old",
    "optionId4",
    "optionId4Old",
    "optionId5",
    "optionId5Old",
    "optionId6",
    "optionId6Old",
  ];

  public dataSource = new MatTableDataSource<UpdatedTravelerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  GroupDetailUpdatedList() {
    this.dataSource.data = [];
    if (+this.tripId > 0 && +this.years > 0) {
      this.reportLogService
        .getGroupDetailUpdateList(this.years, this.tripId)
        .subscribe((result) => {
          this.model = this.dataSource.data = result as UpdatedTravelerEntity[];
          this.dataSource.paginator = this.paginator;
        });
    }
  }

  changeYear() {
    this.tripId = "";
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
  }

  changeTrip() {
    this.GroupDetailUpdatedList();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
}

export interface UpdatedTravelerEntity {
  id: number;
  updatedOnDisplay: string;
  updatedByName: string;
  tripCode: string;
  ppeWhsCode: string;
  salesmanCode: string;
  customerNumber: string;
  companyName: string;
  groups: number;
  checkInDate: string;
  checkInDateOld: string;
  checkOutDate: string;
  checkOutDateOld: string;
  prefRoomType: string;
  prefRoomTypeOld: string;
  miscGroupRequest: string;
  miscGroupRequestOld: string;
  miscGroupPoint: string;
  miscGroupPointOld: string;
  rentACar: boolean;
  rentACarOld: boolean;
  wheelChairRequest: boolean;
  wheelChairRequestOld: boolean;
  option1: string;
  optionId1Old: string;
  optionId2: string;
  optionId2Old: string;
  optionId3: string;
  optionId3Old: string;
  optionId4: string;
  optionId4Old: string;
  optionId5: string;
  optionId5Old: string;
  optionId6: string;
  optionId6Old: string;
  companyOwner: boolean;
  companyOwnerOld: boolean;
  firstClass: boolean;
  firstClassOld: boolean;
  weekDesignation: number;
  weekDesignationOld: number;
  weekDesignationDisplay: string;
  weekDesignationDisplayOld: string;
  HotelDesignationID:number;
  HotelDesignationIDOld : number;
  HotelName : string;
  HotelNameOld:string;
  formattedDate:string;
  formattedTime:string;
}
