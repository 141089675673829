import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServerDataSource } from "ng2-smart-table";
import { ReportLogService } from "src/app/services/report-log.service";
import { UtilityService } from "src/app/services/utility.service";
import { Router } from "@angular/router";
import { TravelerService } from "src/app/services/traveler.service";
import { EnrollmentService } from "src/app/services/enrollment.service";

@Component({
  selector: 'app-report-log-historical-traveler',
  templateUrl: './report-log-historical-traveler.component.html',
  styleUrls: ['./report-log-historical-traveler.component.css']
})
export class ReportLogHistoricalTravelerComponent implements OnInit {

  categoryList: any = [];
  gridColumn = [];
  model: any = {};
  settings: any;
  mySettings: any;
  data: any = {};
  response: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  CategoryList = [];
  allCategoryList = [];
  tripId = '';
  categoryId: number = 0;
  from: number = 1;
  to: number = 25;
  totalRecord: number;
  years = new Date().getFullYear();
  constructor(
    private enrollmentService: EnrollmentService,
    private travelerService: TravelerService,
    private _reportLogService: ReportLogService,
    private _router: Router,
    private http: HttpClient,
    private _utilityService: UtilityService,
    @Inject('BASE_URL') private baseUrl: string

  ) {
    this.mySettings = {
      columns: {},
      actions: {
        edit: false,
        add: false,
        delete: false,
        position: 'right',
      },
      noDataMessage: 'No record found.',
      pager: {
        display: true,
        perPage: 25,
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },
    };
    this.settings = Object.assign({}, this.mySettings);
    this.travelerHeader(this.categoryId);
  }

  ngOnInit() {
    this._reportLogService.getCategoryList().subscribe((result) => {
      this.CategoryList = result;
      //console.log(this.CategoryList);
      // if (this.categoryId !== '') {
      //   this.allCategoryList = this.CategoryList.filter(t => t.id === this.categoryId);
      // } else {
      //   this.allCategoryList = this.CategoryList;
      // }
    });

    // this.data.onChanged().subscribe((change) => {
    //   this.from = (change.paging.page - 1) * change.paging.perPage + 1;
    //   this.to =
    //     this.data.lastRequestCount <= change.paging.page * change.paging.perPage
    //       ? this.data.lastRequestCount
    //       : change.paging.page * change.paging.perPage;
    // });
  }

  travelerList() {
    if (+this.categoryId > 0) {
      this.data = new ServerDataSource(this.http, {
        endPoint:
          this.baseUrl +
          'api/ReportLog/HistoricalTravelerList?CategoryID=' + this.categoryId,
        dataKey: 'data.data',
        totalKey: 'totalCount',
        pagerLimitKey: 'recordCount',
        pagerPageKey: 'pageNumber',
        filterFieldKey: '#field#',
        sortFieldKey: 'sortColumn',
        sortDirKey: 'sortOrder',
      });
    }
    else this.data = [];
    //console.log(this.data);
  }

  travelerHeader(categoryId: any) {
    this._reportLogService
      .getHistoricalTraveler(categoryId)
      .subscribe((result) => {
        //console.log(result);
        this.gridColumn = result;
        //console.log(this.gridColumn);
        this.mySettings.columns = {};
        for (let i = 0; i < this.gridColumn.length; i++) {
          this.mySettings.columns[this.gridColumn[i].columnName] = {
            title: this.gridColumn[i].title,
            sort: false,
            filter: this.gridColumn[i].filter,
          };
        }
        this.mySettings.pager = {
          display: true,
          perPage: 25,
        };
        this.mySettings.attr = {
          class: 'table table color-table dark-table table-striped',
        };
        this.mySettings.attr = {
          class: 'table table color-table dark-table table-striped',
        };

        this.mySettings.actions = {
          edit: false,
          add: false,
          delete: false,
          position: 'right',
        };
        this.settings = Object.assign({}, this.mySettings);

        this.travelerList();
      });
  }
  bindTravelerList() {
    this.travelerHeader(this.categoryId);
  }

  exportHistoricalTraveler() {
    debugger;
    let filterData = this.data.getFilter(); 
    let extraSearch = '';
    if (filterData.filters.length > 0) {
      filterData.filters.forEach((element) => {
        extraSearch += '&' + element.field + '=' + element.search; 
      });
    }
    window.open(
      this.baseUrl +
      'api/ReportLog/HistoricalTravelerListExport?categoryId=' + this.categoryId +
      extraSearch
    );
  }

  onCategoryClick() {
    this.travelerHeader(this.categoryId);
  }

  onCategoryChange() {
    this.travelerHeader(this.categoryId);
    this.travelerList();
  }

}
