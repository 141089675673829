import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { WhsAddEditPopupComponent } from '../whs-add-edit-popup/whs-add-edit-popup.component';
import { WhsService } from '../services/whs.service';

@Component({
  selector: 'app-whs-list',
  templateUrl: './whs-list.component.html',
  styleUrls: ['./whs-list.component.css']
})
export class WhsListComponent implements OnInit {

  	public displayedColumns = [ 'ppeWhsCode', 'whsDescription', 'branchManager', 'groupManager','airportCode','isActiveText', 'action' ];
	public dataSource = new MatTableDataSource<WHSEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	constructor(private _whsService: WhsService, private _router: Router, private matDialog: MatDialog) {}

	ngOnInit() {
		this.whsList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	whsList() {
		//debugger;
		this._whsService.getWHSList().subscribe((result) => {
      //console.log(result);

			this.dataSource.data = result as WHSEntity[];
			//console.log(this.dataSource.data);
			this.dataSource.paginator = this.paginator;
		});
	}
	addUpdateWHS(id: number) {
		this._whsService.getWHSDetail(id).subscribe((result) => {
			if (result) this.model = result;
			else this.model = {};
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '500px';
			dialogConfig.panelClass="dap-model"
			dialogConfig.data = this.model;
			const modalDialog = this.matDialog.open(WhsAddEditPopupComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					this.whsList();
				}
			});
		});
	}
	deleteWHS(id: number) {
		if (confirm('Are you sure to delete the current record?')) {
			this._whsService.deleteWHS(id).subscribe((result) => {
				this.response = result;
				if (result && result.messageCode==1) {
					this.whsList();
				}
			});
		}
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	  }

}
export interface WHSEntity {
	id: number;
	airportId: number;
	ppeWhsCode: string;
	whsDescription: string;
  branchManager: string;
  branchManageEmail: string;
  groupManager: string;
  groupManagerEmail: string;
  isActive: boolean;
  airportCode:string;
  isActiveText:string;
}