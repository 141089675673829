import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServerDataSource } from "ng2-smart-table";
import { ReportLogService } from "src/app/services/report-log.service";
import { UtilityService } from "src/app/services/utility.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-report-log-original-trip-selection",
  templateUrl: "./report-log-original-trip-selection.component.html",
  styleUrls: ["./report-log-original-trip-selection.component.css"],
})
export class ReportLogOriginalTripSelectionComponent implements OnInit {
  gridColumn = [];
  model: any = {};
  settings: any;
  mySettings: any;
  data: any = {};
  response: any = {};
  yearList: any = [];
  from: number = 1;
  to: number = 25;
  totalRecord: number;
  years = new Date().getFullYear()-1;
  CurrentYears = new Date().getFullYear();
  DisplayYTDCustomerStatementReport = false;
  constructor(
    private _reportLogService: ReportLogService,
    private _router: Router,
    private _utilityService: UtilityService,
    private http: HttpClient,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.model.Year = this.years;
    this.mySettings = {
      columns: {},

      actions: {
        edit: false,
        add: false,
        delete: false,
        position: "right",
      },
      noDataMessage: "No record found.",
      pager: {
        display: true,
        perPage: 25,
      },
      attr: {
        class: "table table color-table dark-table table-striped",
      },
    };
    this.settings = Object.assign({}, this.mySettings);
    this.customerHeader(this.years);
  }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      2021,
      new Date().getFullYear() + 1
    );

    this.DisplayYTDCustomerStatementReport = this.CurrentYears === this.years;
    // this.data.onChanged().subscribe((change) => {
    //   this.from = (change.paging.page - 1) * change.paging.perPage + 1;
    //   this.to =
    //     this.data.lastRequestCount <= change.paging.page * change.paging.perPage
    //       ? this.data.lastRequestCount
    //       : change.paging.page * change.paging.perPage;
    // });
  }
  customerStatementList(year) {
    this.data = new ServerDataSource(this.http, {
      endPoint:
        this.baseUrl + "api/ReportLog/OriginalTripSelectionList?years=" + year,
      dataKey: "data.data",
      totalKey: "totalCount",
      pagerLimitKey: "recordCount",
      pagerPageKey: "pageNumber",
      filterFieldKey: "#field#",
      sortFieldKey: "sortColumn",
      sortDirKey: "sortOrder",
    });
    console.log(this.data);
  }
  bindCustomerList() {
    this.customerHeader(this.model.Year);
  }

  customerHeader(year) {
    this._reportLogService
      .getOriginalTripSelectionColumns(year)
      .subscribe((result) => {
        //console.log(result);
        this.gridColumn = result;
        //console.log(this.gridColumn);
        this.mySettings.columns = {};
        for (let i = 0; i < this.gridColumn.length; i++) {
          this.mySettings.columns[this.gridColumn[i].columnName] = {
            title: this.gridColumn[i].title,
            sort: false,
            filter: this.gridColumn[i].filter,
          };
        }
        this.mySettings.pager = {
          display: true,
          perPage: 25,
        };
        this.mySettings.attr = {
          class: "table table color-table dark-table table-striped",
        };
        this.mySettings.attr = {
          class: "table table color-table dark-table table-striped",
        };

        this.mySettings.actions = {
          edit: false,
          add: false,
          delete: false,
          position: "right",
        };
        this.settings = Object.assign({}, this.mySettings);
        this.data = [];
        this.customerStatementList(this.model.Year);
      });
  }

  exportOriginalTripSelection() {
    let filterData = this.data.getFilter();let extraSearch='';
  	if(filterData.filters.length>0)
  	{
  		filterData.filters.forEach(element => {
  			extraSearch+= '&'+element.field+'='+element.search;
  		});
  	}
  	window.open(this.baseUrl + 'api/ReportLog/OriginalTripSelectionListExport?years='+this.model.Year +extraSearch);
  }
}
