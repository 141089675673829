import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PointOptionsService } from '../../services/point-options.service';
import { CustomValidators } from 'src/app/Directives/custom-validators';

@Component({
  selector: 'app-point-options',
  templateUrl: './point-options.component.html',
  styleUrls: ['./point-options.component.css']
})
export class PointOptionsComponent implements OnInit {
	public displayedColumns = [ 'pointsOption', 'pointAdjustment', 'action' ];
	public dataSource = new MatTableDataSource<PointEntity>();
	
	public optionForm: FormGroup;
	response: any = {};
	submitted = false;
	title = '';
	model = {};
	isSaveAndAdd:number=0
	role = +localStorage.getItem('role');
	constructor(
		private pointOptionsService: PointOptionsService,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<PointOptionsComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		this.generateForm(false);
		
	}
	get f() {
		return this.optionForm.controls;
	}

	generateForm(isSave:boolean) {
		if (this.data.id > 0) {
			this.title = 'Update Point Options';
		} 
		else if(isSave)
		{
			this.submitted = false;
			 this.f.PointsOption.setValue('');
			 this.f.PointAdjustment.setValue('');
			 this.data.tripId=this.f.TripId.value
			 this.optionList(this.f.TripId.value);
		}
		else {
			this.data.roomPerNightCost = '';
			this.title = 'Add Point Options';
			this.data.tripId = '';
			this.data.isActive = true;			
		}
		this.optionForm = this.fb.group({
		      Id: [ this.data.id, Validators.required ],
			  TripId: [ this.data.tripId, [ Validators.required ] ],
			  PointsOption: [ this.data.pointsOption, [ Validators.required, CustomValidators.checkEmpty ] ],
              PointAdjustment: [ this.data.pointAdjustment, [ Validators.required ] ],
			  IsActive: [ this.data.isActive ],
			  PointsValue:[this.data.pointsValue]
		});
	}

	onCloseClick(): void {
		this.dialogRef.close();
	}
	saveOption() {
		this.response = {}
		this.submitted = true;
		if (this.optionForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		let dataSave = this.optionForm.value;
		this.model = {
			Id: dataSave.Id,
			PointsOption: dataSave.PointsOption,
			TripId: dataSave.TripId,
			PointAdjustment: Number(dataSave.PointAdjustment),
			IsActive: dataSave.IsActive,
			PointsValue:dataSave.PointsValue,
		};
		this.pointOptionsService.savePointOptions(this.model).subscribe((result) => {
			if (result) {
				if (result.messageCode == 1) {
					if(this.isSaveAndAdd==1)
					{
						this.response = result;
						this.isSaveAndAdd=0
						this.generateForm(true)						
					}
					else
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			}
		});
	}
	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if(charCode==45)
		return true;
		else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	numberOnlyWithDecimal(event): boolean {

		var dotCount = 0;
		var checkNumberOnly = '';
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode == 46) {
			dotCount += 1;
			checkNumberOnly = event.target.value;
			var numericCheck = event.target.value.toString();
			if (numericCheck.includes('.')) {
				dotCount += 1;
			}
			if (dotCount > 1) {
				dotCount = 0;
				return false;
			}
		}
		if (charCode > 31 && (charCode < 45 || charCode > 57 || charCode == 47)) {
			return false;
		}
		checkNumberOnly = event.target.value;
		var checkString = '';
		if (checkNumberOnly != null) {
			var numeric = event.target.value.toString();
			if (numeric.includes('.')) {
				var checkNumeric = numeric.split('.');
				if (checkNumeric.length > 2) {
					return false;
				}
				checkString = checkNumeric[1].split('');
				if (checkString.length > 1) {
					return false;
				}
			}
		}
		dotCount = 0;
		return true;
	}

	deleteOption(id:number, isSave:boolean) {
		this.response = {}
		if (confirm('Are you sure to delete the current record?')) {
			this.pointOptionsService.deletePointOptions(id).subscribe((result) => {
				this.response = result;
				if (result.messageCode == 1) {
					if(!isSave)
					{
						this.dialogRef.close(result);
					}	
					else
					this.optionList(this.f.TripId.value);				
				} 
			});
		}
	}

	optionList(tripId:number, isChange:boolean=false) {
		if(isChange)
		this.response = {}
		if(tripId>0)
		{
		this.pointOptionsService.getPointOptionsList(tripId).subscribe((result) => {
			this.dataSource.data = result as PointEntity[];
		});
	}
	else
	{
		this.dataSource.data =[]
	}
	}
}
export interface PointEntity {
  id: number
  tripCode: string
  pointsOption: string
  pointAdjustment : string
  isActiveText:string  
}