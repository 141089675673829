import { Injectable, Inject } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';


let myAppUrl = "";
@Injectable({
  providedIn: 'root'
})
export class AirportService {

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  saveAirport(model) {
    return this._http.post<any>(myAppUrl + 'api/Airport/AddUpdateAirport', model)
      .pipe(map(data => { return data; }))
  }
  getAirportDetail(id: number) {
    return this._http.get<any>(myAppUrl + 'api/Airport/GetAirportDetail?id=' + id)
      .pipe(map(data => { return data; }))
  }
  getAirportList() {
    return this._http.get<any>(myAppUrl + 'api/Airport/AirportList')
      .pipe(map(data => { return data; }))
  }
  getAirportListActive() {
    return this._http.get<any>(myAppUrl + 'api/Airport/AirportListActive')
      .pipe(map(data => { return data; }))
  }
  deleteAirport(id: number) {
    return this._http.post<any>(myAppUrl + 'api/Airport/AirportDelete?id=' + id, null)
      .pipe(map(data => { return data; }))
  }
}
