import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-points-and-rewards-status',
	templateUrl: './points-and-rewards-status.component.html',
	styleUrls: [ './points-and-rewards-status.component.css' ]
})
export class PointsAndRewardsStatusComponent implements OnInit {
	data: any = {};
	response: any = {};
	settings: any;
	model: any = {};
	from: number = 1;
	to: number = 25;
	totalRecord: number;
	years = new Date().getFullYear();
	yearList: any = [];
	constructor(
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string,
		private _utilityService: UtilityService
	) {
		this.settings = {
			columns: {
				customerNumber: {
					title: 'Company No.',
					sort: false
				},
				companyName: {
					title: 'Company Name',
					type: 'html',
					sort: false
				},

				totalEarnPoints: {
					title: 'Points Earned',
					filter: false,
					sort: false
				},
				previousRollOverPoints: {
					title: 'Rolled over from Prior Years',
					filter: false,
					sort: false
				},
				totalPoints: {
					title: 'Total',
					filter: false,
					sort: false
				},
				usedPoints: {
					title: 'Points Used',
					filter: false,
					sort: false
				},
				balancePoints: {
					title: 'Balance',
					filter: false,
					sort: false
				},
				futureRollOverPoints: {
					title: 'Rolled over future year ',
					filter: false,
					sort: false
				}
			},

			actions: {
				edit: false,
				add: false,
				delete: false,

				position: 'right'
			},
			noDataMessage: 'No record found.',
			pager: {
				display: true,
				perPage: 25
			},
			attr: {
				class: 'table table color-table dark-table table-striped'
			}
		};
		this.bindPointsList(this.years);
	}

	ngOnInit() {
		this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear() + 1);
		this.model.Year = this.years;

		this.data.onChanged().subscribe((change) => {
			this.from = (change.paging.page - 1) * change.paging.perPage + 1;
			this.to =
				this.data.lastRequestCount <= change.paging.page * change.paging.perPage
					? this.data.lastRequestCount
					: change.paging.page * change.paging.perPage;
		});
	}
	dropdownYearChange() {
		this.bindPointsList(this.model.Year);
	}
	bindPointsList(year) {
		this.data = new ServerDataSource(this.http, {
			endPoint: this.baseUrl + 'api/PointsAndRewards/GetCustomerPointsAndRewardsStatus?year=' + year,
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});

		this.data.setPaging(1, 10, true);
	}
}
