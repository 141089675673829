import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-points-detail-breakup',
  templateUrl: './points-detail-breakup.component.html',
  styleUrls: ['./points-detail-breakup.component.css']
})
export class PointsDetailBreakupComponent implements OnInit {

  response: any = {};
	model = {};
	constructor(public dialogRef: MatDialogRef<PointsDetailBreakupComponent>,@Inject(MAT_DIALOG_DATA) public data) {}
	ngOnInit() {
		//console.log(this.data);
    this.model=this.data;
	}

	onCloseClick(): void {
		this.dialogRef.close();
	}
}
