import { Component, OnInit, Inject } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
})
export class CustomerDetailComponent implements OnInit {
  //model: any = {roles:''};
  public mask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  response: any = {};
  isEditDAP: boolean = false;
  role = +localStorage.getItem('role');
  commonMethodList = [];
  customerList = [];
  isEditDAPAddress = false;
  constructor(
    private customerService: CustomerService,
    public dialogRef: MatDialogRef<CustomerDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public model,
    private _utilityService: UtilityService
  ) {}
  ngOnInit() {
    // console.log(this.model.parentCompanyId);

    this.commonMethodList = this._utilityService.getCommonMethod();
    this.customerService
      .getCustomerNameList('', this.model.parentCompanyId)
      .subscribe((result) => {
        this.customerList = result.filter((x) => x.id != this.model.id);
        //console.log(this.customerList);
      });
  }
  onCloseClick(): void {
    this.dialogRef.close();
  }
  updatedCustomerDAP(form: NgForm) {
    if (form.valid) {
      this.customerService
        .updatedCustomerDAP(this.model)
        .subscribe((result) => {
          if (result) {
            if (result.messageCode == 1) {
              this.dialogRef.close(result);
            } else {
              this.response = result;
            }
          }
        });
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
  }

  removeDAPAddress() {
    this.response = { isShow: false };
    if (confirm('Are you sure you want to delete this dap address?')) {
      this.model.dapAddress1 = '';
      this.model.dapAddress2 = '';
      this.model.dapAddress3 = '';
      this.model.dapCity = '';
      this.model.dapState = '';
      this.model.dapZip = '';
      this.model.IsDeleteDapAddress = true;
      this.customerService
        .updateCustomerDAPAddress(this.model)
        .subscribe((result) => {
          this.response = result;
          if (result) {
            if (result.messageCode == 1) {
              this.isEditDAPAddress = false;
            }
          }
        });
    }
  }

  deleteCustomer() {
    var id = this.model.id;
    if (confirm('Are you sure to delete the current record?')) {
      this.customerService
        .deleteCustomer(id, this._utilityService.getCurrentUserId())
        .subscribe((result) => {
          if (result.messageCode == 1) {
            this.dialogRef.close(result);
          } else {
            this.response = result;
          }
        });
    }
  }
  updatedCompanyInfo(form: NgForm) {
    if (form.valid) {
      if (!this.model.lastYearSales) this.model.lastYearSales = 0;
      this.model.LogUserId = this._utilityService.getCurrentUserId();

      this.customerService
        .updatedCompanyInfo(this.model)
        .subscribe((result) => {
          if (result) {
            if (result.messageCode == 1) {
              this.dialogRef.close(result);
            } else {
              this.response = result;
            }
          }
        });
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
  }
  updatedDAPAddress(form: NgForm) {
    if (form.valid) {
      if (!this.model.lastYearSales) this.model.lastYearSales = 0;
      this.customerService
        .updateCustomerDAPAddress(this.model)
        .subscribe((result) => {
          if (result) {
            if (result.messageCode == 1) {
              this.dialogRef.close(result);
            } else {
              this.response = result;
            }
          }
        });
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
  }
  private typed(value: string) {
    if (value.length >= 3) {
      var emptlist = [{ id: 0, customerName: 'No data found' }];
      this.customerList = [];
      this.customerService
        .getCustomerNameList(value, this.model.parentCompanyId)
        .subscribe((result) => {
          if (result && result.length > 0) this.customerList = result;
          else this.customerList = emptlist;
        });
    }
  }

  // private select(value: number) {
  // 	if (value > 0) {
  // 		this.model.parentCompany = value.toString();

  // 	}
  // }
}
