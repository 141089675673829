import { Component, OnInit, Inject } from '@angular/core';
import { AirportService } from '../services/airport.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../Directives/custom-validators';

@Component({
	selector: 'app-airport-add-edit-model-popup',
	templateUrl: './airport-add-edit-model-popup.component.html',
	styleUrls: [ './airport-add-edit-model-popup.component.css' ]
})
export class AirportAddEditModelPopupComponent implements OnInit {
	response: any = {};
	public airportForm: FormGroup;
	model = {};
	submitted = false;
	showDeleteButton = false;
	role = +localStorage.getItem('role');
	title = '';
	submitButtonText = 'Save';
	constructor(
		private _airportService: AirportService,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AirportAddEditModelPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		this.generateForm();
	}

	get f() {
		return this.airportForm.controls;
	}

	generateForm() {
		if (this.data.id > 0) {
			this.title = 'Edit Airport';
			this.showDeleteButton = this.role <= 2;
			this.submitButtonText = 'Update';
		} else {
			this.title = 'Add Airport';
			this.showDeleteButton = false;
			this.data.isActive = true;
		}

		this.airportForm = this.fb.group({
			Id: [ this.data.id, Validators.required ],
			AirportCode: [ this.data.airportCode, [ Validators.required, CustomValidators.checkEmpty ] ],
			AirportName: [ this.data.airportName, [ Validators.required, CustomValidators.checkEmpty ] ],
			City: [ this.data.city, [ Validators.required, CustomValidators.checkEmpty ] ],
			States: [ this.data.states, [ Validators.required, CustomValidators.checkEmpty ] ],
			IsActive: [ this.data.isActive ]
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
	saveAirport() {  
		this.submitted = true;
		if (this.airportForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		let dataSave = this.airportForm.value;
		var id = dataSave.Id;
		if (!dataSave.Id) id = 0;

		this.model = {
			Id: id,
			AirportCode: dataSave.AirportCode,
			AirportName: dataSave.AirportName,
			City: dataSave.City,
			States: dataSave.States,
			IsActive: dataSave.IsActive
		};

		this._airportService.saveAirport(this.model).subscribe((result) => {
			if (result) {
				if (result.messageCode == '1') {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			}
		});
	}

	deleteAirport() {
		var id = this.data.id;
		if (confirm('Are you sure to delete the current record?')) {
			this._airportService.deleteAirport(id).subscribe((result) => {
				this.response = result;
				if (result.messageCode == '1') {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			});
		}
	}
}
