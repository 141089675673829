import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  model: any = {};
  response: any = {};
  iscaptchaSuccess = false;
  isCaptchaMsg = false;
  isError = false;
  constructor(private userService: UserService, private router: Router) {}
  ngOnInit() {
    // this.userService.getSchedulerTest().subscribe((result) => {
    //   console.log(result);
    // });
  }

  getUserDetail(form: NgForm) {
    this.isError = false;
    if (form.valid) {
      this.userService.getUserDetail(this.model).subscribe((result) => {
        // console.log(result);
        this.response = result;
        if (
          this.response.messageCode == 1 &&
          this.response.data &&
          this.response.data.id > 0
        ) {
          delete this.response.data.pass;
          localStorage.setItem('role', this.response.data.roles);
          localStorage.setItem('user', JSON.stringify(this.response.data));
          localStorage.setItem('isLoggedIn', 'true');
          this.router.navigate(['']);
        }
      });
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      invalidFields[1].focus();
    }
  }
}
