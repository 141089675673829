import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SalesmanService } from '../services/salesman.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';

@Component({
	selector: 'app-salesman-import',
	templateUrl: './salesman-import.component.html',
	styleUrls: [ './salesman-import.component.css' ]
})
export class SalesmanImportComponent implements OnInit {
	isSaved;
	isError;
	fileData: any = {};
	response: any = {};
	model: any = {};
	isImportSaveDisplay=false;
	constructor(private _salesmanService: SalesmanService, private _router: Router) {}
	ngOnInit() {}
	
	receiveMessage($event) {
		this.response = {};
		this.isError = 0;
		this.isSaved = 0;
		this.fileData = $event;
		if (this.fileData.messageCode == 1 && this.fileData.fileType == 1) {
			this.model.salesman_importFile = this.fileData.data;
		}
	}
	importSalesman(form: NgForm) {
		if (form.valid) {
			this._salesmanService.Import(this.model.salesman_importFile).subscribe((result) => {
				//console.log(result);
				if (result) {
					this.response = result;
					if (result.messageCode == '1') {
						this.model.salesman_importFile='';
						localStorage.setItem('salesmanImport', JSON.stringify(result));
						this._router.navigate([ '/salesman-list' ]);
					}
				}
			});
		} else {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
	}
	importSalesmanSave() {
		var dataSave=this.response.data;
		//console.log(dataSave);
		this._salesmanService.importSave(dataSave).subscribe((result) => {
			//console.log(result);
			if (result) {
				this.response = result;
				if (result.messageCode == '1') {
					this.model.salesman_importFile='';
					// debugger;
				
				}
			}
		});
	}
	sampleFileDownload(filename)
	{
		this._salesmanService.sampleFileDownload(filename);
	}
}

