import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ServerDataSource } from 'ng2-smart-table';
import { UtilityService } from 'src/app/services/utility.service';
import { AllocatePointsService } from 'src/app/services/allocate-points.service';

@Component({
  selector: 'app-allocate-points',
  templateUrl: './allocate-points.component.html',
  styleUrls: ['./allocate-points.component.css'],
})
export class AllocatePointsComponent implements OnInit {
  data: any = {};
  response: any = {};
  settings: any;
  model: any = {};
  from: number = 1;
  to: number = 25;
  totalRecord: number;
  years = new Date().getFullYear() - 1;
  yearList: any = [];
  IsAllocatePoints = false;
  IsAllocated = false;
  searchText = '';
  public displayedColumns = [
    'customerNumber',
    'companyName',
    'totalEarnPoints',
    'totalTripPoints',
    'balancePoints',
  ];
  public dataSource = new MatTableDataSource<CustomerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private _allocatePointsService: AllocatePointsService,
    private _utilityService: UtilityService,
    private _router: Router
  ) {
    // 	this.settings = {
    // 		columns: {
    // 	customerNumber: {
    // 				title: 'Company No.',
    // 				sort: false
    // 			},
    // 	companyName: {
    // 				title: 'Company Name',
    // 				type: 'html',
    // 				sort: false
    // 			},
    //     totalEarnPoints: {
    // 				title: 'Total Earn Points',
    // 				filter: false,
    // 				sort: false
    // 			},
    //    totalTripPoints: {
    // 				title: 'Trip Points',
    // 				filter: false,
    // 				sort: false
    // 			},
    //     balancePoints: {
    // 				title: 'Balance',
    // 				filter: false,
    // 				sort: false
    // 			},
    // 		},

    // 	actions: {
    // 			edit: false,
    // 			add: false,
    // 			delete: false,

    // 			position: 'right'
    // 		},
    // 		noDataMessage: 'No record found.',
    // 		pager: {
    // 			display: true,
    // 			perPage: 25
    // 		},
    // 		attr: {
    // 			class: 'table table color-table dark-table table-striped'
    // 		}
    // 	};
    let url = this._router.url;
    if (url == '/allocated-points') {
      this.IsAllocated = true;
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
  ngOnInit() {
    this.bindPointsList();

    //this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear() + 1);
    //this.model.Year = this.years;

    // this.data.onChanged().subscribe((change) => {
    // 	this.from = (change.paging.page - 1) * change.paging.perPage + 1;
    // 	this.to =
    // 		this.data.lastRequestCount <= change.paging.page * change.paging.perPage
    // 			? this.data.lastRequestCount
    // 			: change.paging.page * change.paging.perPage;
    // });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //   allocatePoints()
  //   {
  // 	this.IsAllocatePoints=true;
  // 	this._router.navigate([ '/confirm-points-allocation' ]);
  //   }
  confirmAllocatePoints() {
    this._allocatePointsService.savePointsAllocation().subscribe((result) => {
      this.response = result;
      if (this.response.messageCode == 1) {
        console.log(this.response);
        localStorage.setItem('Allocated', JSON.stringify(result));
        this._router.navigate(['/allocated-points']);
      }
    });
  }
  bindPointsList() {
    this._allocatePointsService
      .getAllocatePointsList(this.IsAllocated)
      .subscribe((result) => {
        this.response = result; //this.model=
        this.dataSource.data = this.response.data as CustomerEntity[]; //.fltier(e=>e.isTripAdded)
        //console.log(this.response);
        //   if(this.dataSource.data && this.response.data.length>0)
        //   this.years=this.response.data[0].years;
        this.dataSource.paginator = this.paginator;

        if (localStorage.getItem('Allocated') != null) {
          this.response = JSON.parse(localStorage.getItem('Allocated'));
          localStorage.removeItem('Allocated');
        }
      });

    // 	this.data = new ServerDataSource(this.http, {
    // 		endPoint: this.baseUrl + 'api/AllocatePoints/AllocatePointsList',
    // 		dataKey: 'data.data',
    // 		totalKey: 'totalCount',
    // 		pagerLimitKey: 'recordCount',
    // 		pagerPageKey: 'pageNumber',
    // 		filterFieldKey: '#field#',
    // 		sortFieldKey: 'sortColumn',
    // 		sortDirKey: 'sortOrder'
    // 	});
    //this.data.setPaging(1, 10, true);
  }
}
export interface CustomerEntity {
  customerId: number;
  customerNumber: string;
  companyName: string;
  totalEarnPoints: string;
  totalTripPoints: string;
  balancePoints: string;
}
