import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AllocatePointsService } from 'src/app/services/allocate-points.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-report-catalog-point',
  templateUrl: './report-catalog-point.component.html',
  styleUrls: ['./report-catalog-point.component.css']
})
export class ReportCatalogPointComponent implements OnInit {
  model: any = {};
  years = '';
  searchText = '';
  yearList: any[];

  public displayedColumns = [
    'dateCreated',
    'ppeWhsCode',
    'customerNumber',
    'companyName',
    'code',
    'item',
    'ppeCost',
    'points'
    //'years'
  ];

  public dataSource = new MatTableDataSource<CatalogPointsModel>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;


  constructor(
    private _allocatePoints: AllocatePointsService,
    public _utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.getCatalogPoint(this.years);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getCatalogPoint(years) {
    this._allocatePoints.getReportCatalogPoint(years).subscribe(result => {
      this.model = this.dataSource.data = result as CatalogPointsModel[];
      //console.log(result);
      this.dataSource.paginator = this.paginator;
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
  changeYear() {
    this.getCatalogPoint(this.years);
    }
}

export interface CatalogPointsModel {
  dateCreated: string,
  ppeWhsCode: string,
  customerNumber: string,
  companyName: string,
  code: string,
  item: string,
  ppeCost: string
  points: string,
  years: string
}
