import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
@Component({
  selector: 'app-viewlink',
  templateUrl: './viewlink.component.html',
  styleUrls: ['./viewlink.component.css'],
})
export class ViewlinkComponent implements ViewCell, OnInit {
  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;

  constructor(private route: Router) {}

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    let url = this.route.url;
    if (url == '/customer-points') {
      localStorage.setItem('isPoints', '1');
    } else {
      localStorage.removeItem('isPoints');
    }
    if (url == '/pending-ar-release') {
      this.route.navigate([
        '/trip-enrollment-detail-pending-allocation/' +
          this.rowData.id +
          '/' +
          1,
      ]);
    } else {
      this.route.navigate(['/trip-enrollment-detail/' + this.rowData.id]);
    }
  }
}
