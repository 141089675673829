import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { UserService } from '../../services/user.service'
import { Router } from '@angular/router';
import { AddUserPopupComponent} from '../../Components/add-user-popup/add-user-popup.component';

@Component({
	selector: 'app-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: [ './user-list.component.css' ]
})
export class UserListComponent implements OnInit {
	public displayedColumns = [ 'fullName', 'email', 'phone', 'rolesString','isActive', 'action' ];
	public dataSource = new MatTableDataSource<UserListEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	userData: any={}
	constructor(private userService: UserService, private _router: Router, private matDialog: MatDialog) {}

	ngOnInit() {
		this.usertList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	usertList() {
		this.userData = JSON.parse(localStorage.getItem('user'));
		this.userService.getUserList().subscribe((result) => {
			if(result && this.userData) result=result.filter( u=> u.id!=this.userData.id);
			this.dataSource.data = result as UserListEntity[];
			this.dataSource.paginator = this.paginator;
		});
	}
	addUpdateUser(id: number) {
		this.response={isShow:false};
		this.model={id:id}
		this.userService.getUserDetail(this.model).subscribe((result) => {
			if (result && id>0) this.model = result.data;
			else this.model = {roles:''};
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '500px';
			dialogConfig.data = this.model;
			const modalDialog = this.matDialog.open(AddUserPopupComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					this.usertList();
				}
			});
		});
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	  }
}
export interface UserListEntity {
	id: number;
	fullName: string;
	email: string;
	phone: string;
	rolesString: string;
	isActive:string;
}
