import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatTableDataSource,
  MatDialog,
  MatPaginator,
  MatSort,
  MatDialogConfig,
} from "@angular/material";
import { Router } from "@angular/router";
import { TripwhsdefaultsService } from "../services/tripwhsdefaults.service";
import { TripwhsdefaultsAddEditPopupComponent } from "../tripwhsdefaults-add-edit-popup/tripwhsdefaults-add-edit-popup.component";

@Component({
  selector: "app-tripwhsdefaults-list",
  templateUrl: "./tripwhsdefaults-list.component.html",
  styleUrls: ["./tripwhsdefaults-list.component.css"],
})
export class TripwhsdefaultsListComponent implements OnInit {
  public displayedColumns = [
    "ppeWhsCode",
    "tripCode",
    "checkInDate",
    "checkOutDate",
    "outgoingFlightDate",
    "returnFlightDate",
    "pointsPerTrip",
    "pointsValueText",
    "isActiveText",
    "action",
  ];
  public dataSource = new MatTableDataSource<TripWhsDefaultEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  model = {};
  constructor(
    private _tripwhsService: TripwhsdefaultsService,
    private _router: Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.tripWhsList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  tripWhsList() {
    //debugger;
    this._tripwhsService.getTripWhsList().subscribe((result) => {
      this.dataSource.data = result as TripWhsDefaultEntity[];
      //console.log(this.dataSource.data);
      this.dataSource.paginator = this.paginator;
    });
  }
  addUpdateTripWhs(id: number) {
    this._tripwhsService
      .getTripWhsDetailWithCodeList(id)
      .subscribe((result) => {
        if (result) this.model = result;
        else this.model = {};
        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        // dialogConfig.id = "modal-component";
        dialogConfig.width = "980px";
        dialogConfig.panelClass = "dap-model";
        dialogConfig.data = this.model;
        const modalDialog = this.matDialog.open(
          TripwhsdefaultsAddEditPopupComponent,
          dialogConfig
        );

        modalDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.response = result;
            this.tripWhsList();
          }
        });
      });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
export interface TripWhsDefaultEntity {
  id: number;
  whsCodeID: number;
  tripID: number;
  checkInDate: Date;
  returnFlightDate: Date;
  outgoingFlightDate: Date;
  checkOutDate: Date;
  pointsPerTrip: number;
  isActive: boolean;
  tripCode: string;
  ppeWhsCode: string;
  isActiveText: string;
  checkInDateString: string;
  checkOutDateString: string;
  returnFlightDateString: string;
  outgoingFlightDateString: string;
  pointsValueText: string;
}
