import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AllocatePointsService } from "src/app/services/allocate-points.service";

@Component({
  selector: "app-used-in-catalog",
  templateUrl: "./used-in-catalog.component.html",
  styleUrls: ["./used-in-catalog.component.css"],
})
export class UsedInCatalogComponent implements OnInit {
  // desc: any = "";
  // response: any = {};
  // model = {};
  customerCatalogPointList: any = [];
  constructor(
    public dialogRef: MatDialogRef<UsedInCatalogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private allocatePointsService: AllocatePointsService
  ) {}
  ngOnInit() {
    //this.model = this.data;

    this.getAllocationDetail(this.data.years, this.data.customerId);
  }

  getAllocationDetail(years, customerId) {
    // display last year points
    // debugger;

    this.allocatePointsService
      .getCatalogPointByCustomer(years, customerId)
      .subscribe((result) => {
        this.customerCatalogPointList = result;
      });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
