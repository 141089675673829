import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { HotelDesignationService } from "src/app/services/hotel-designation.service";
import { UtilityService } from "src/app/services/utility.service";

@Component({
  selector: "app-hotel-designation-add-edit-popup",
  templateUrl: "./hotel-designation-add-edit-popup.component.html",
  styleUrls: ["./hotel-designation-add-edit-popup.component.css"],
})
export class HotelDesignationAddEditPopupComponent implements OnInit {
  public hotelForm: FormGroup;
  categoryList: any = [];
  response: any = {};
  submitted = false;
  title = "";
  showDeleteButton = false;
  submitButtonText = "Save";
  role = +localStorage.getItem("role");
  model = {};
  isOptionalCat: boolean = false;
  tripDestinationList: any = [];
  public mask = [
    "(",
    /[0-9]/,
    /\d/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  userId = 0;
  constructor(
    private _hotelService: HotelDesignationService,
    private _utilityService: UtilityService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<HotelDesignationAddEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.userId = JSON.parse(localStorage.getItem("user")).id;
    this.generateForm();
  }
  get f() {
    return this.hotelForm.controls;
  }
  generateForm() {
    if (this.data.id > 0) {
      this.title = "Edit Destination Hotel";
      this.showDeleteButton = this.role <= 2;
      this.submitButtonText = "Update";
    } else {
      this.title = "Add Destination Hotel";
      this.showDeleteButton = true;
      this.data.isActive = true;
      this.data.tripDestinationID = "";
      this.data.tripCategoryId = "";
    }
    this.categoryList = this.data.categoryList;
    // this.tripDestinationList = this.data.tripDestinationList;
    this.ChangeCat(this.data.tripCategoryId);

    this.hotelForm = this.fb.group({
      Id: [this.data.id],
      CategoryId: [this.data.tripCategoryId],
      TripDestinationID: [this.data.tripDestinationID],
      Destination: [this.data.destination],
      HotelName: [this.data.hotelName, Validators.required],
      HotelContactPersonName: [
        this.data.hotelContactPersonName,
        Validators.required,
      ],
      HotelAddress: [this.data.hotelAddress, Validators.required],
      HotelContactNumber: [this.data.hotelContactNumber, Validators.required],
      IsActive: [this.data.isActive],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  saveHotelDesignation() {
    this.submitted = true;
    if (this.hotelForm.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName("ng-invalid")
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }

    let dataSave = this.hotelForm.value;
    var id = dataSave.Id;
    if (!dataSave.Id) {
      id = 0;
    }

    this.model = {
      Id: id,
      TripCategoryId: +dataSave.CategoryId,
      TripDestinationID: +dataSave.TripDestinationID,
      HotelName: dataSave.HotelName,
      HotelAddress: dataSave.HotelAddress,
      HotelContactNumber: dataSave.HotelContactNumber,
      HotelContactPersonName: dataSave.HotelContactPersonName,
      IsActive: dataSave.IsActive,
      CreatedBy: this.userId,
      UpdatedBy: this.userId,
    };
    this._hotelService.saveHotelDesignation(this.model).subscribe((result) => {
      if (result) {
        if (result.messageCode == "1") {
          this.dialogRef.close(result);
        } else {
          this.response = result;
        }
      }
    });
  }
  ChangeCat(categoryId) {
    if (categoryId > 0) {
      this.isOptionalCat = this.categoryList.filter(
        (c) => c.categoryId == categoryId
      )[0].isOptional;
      this.tripDestinationList = this.data.tripDestinationList.filter(
        (d) => d.tripCategoryID == categoryId
      );
    }
  }

  deletehotelDesignation() {
    var id = this.data.id;
    if (confirm("Are you sure to delete the current record?")) {
      this._hotelService.deleteHotelDesignation(id).subscribe((result) => {
        this.response = result;
        if (result.messageCode == "1") {
          this.dialogRef.close(result);
        } else {
          this.response = result;
        }
      });
    }
  }
}
