import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
  MatDialogConfig,
} from "@angular/material";
import { PointOptionsService } from "../../services/point-options.service";
import { Router } from "@angular/router";
import { PointOptionsComponent } from "../point-options/point-options.component";

@Component({
  selector: "app-point-options-list",
  templateUrl: "./point-options-list.component.html",
  styleUrls: ["./point-options-list.component.css"],
})
export class PointOptionsListComponent implements OnInit {
  public displayedColumns = [
    "tripCode",
    "pointsOption",
    "pointAdjustment",
    'pointsValueText',
    "isActiveText",
    "action",
  ];
  public dataSource = new MatTableDataSource<PointEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  model = {};
  constructor(
    private _pointOptionsService: PointOptionsService,
    private _router: Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.optionList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  optionList() {
    this._pointOptionsService.getPointOptionsList(0).subscribe((result) => {
      this.dataSource.data = result as PointEntity[];
      //console.log(this.dataSource.data);
      this.dataSource.paginator = this.paginator;
    });
  }
  addUpdatePointOptions(id: number) {
    this._pointOptionsService.getPointOptionsDetail(id).subscribe((result) => {
      if (result) this.model = result;
      else this.model = {};
      const dialogConfig = new MatDialogConfig();
      // The user can't close the dialog by clicking outside its body
      dialogConfig.disableClose = true;
      // dialogConfig.id = "modal-component";
      if (id > 0) dialogConfig.width = "500px";
      else dialogConfig.width = "900px";
      dialogConfig.panelClass = "dap-model";
      dialogConfig.data = this.model;
      const modalDialog = this.matDialog.open(
        PointOptionsComponent,
        dialogConfig
      );

      modalDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.response = result;
          this.optionList();
        }
      });
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
export interface PointEntity {
  id: number;
  tripCode: string;
  pointsOption: string;
  pointAdjustment: string;
  isActiveText: string;
  pointsValueText:String;
}
