import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TripoptionsService } from '../services/tripoptions.service';
import { TripOptionAddEditPopupComponent } from '../trip-option-add-edit-popup/trip-option-add-edit-popup.component';

@Component({
	selector: 'app-trip-options',
	templateUrl: './trip-options.component.html',
	styleUrls: [ './trip-options.component.css' ]
})
export class TripOptionsComponent implements OnInit {
	public displayedColumns = [
		'categoryName',
		'years',
		'destination',
		'tripCode',
		'defaultNameTypeString',
		'airportCode',
		'isActiveText',
		'action'
	];
	public dataSource = new MatTableDataSource<TripOptionEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	constructor(
		private _tripOptionService: TripoptionsService,
		private _router: Router,
		private matDialog: MatDialog
	) {}

	ngOnInit() {
		this.tripOptionList();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	tripOptionList() {
		//debugger;
		this._tripOptionService.getTripOptionList().subscribe((result) => {
			this.dataSource.data = result as TripOptionEntity[];
			this.dataSource.paginator = this.paginator;
		});
	}
	addUpdateTripOption(id: number) {
		this._tripOptionService.getTripOptionDetail(id).subscribe((result) => {
			if (result) this.model = result;
			else this.model = {};
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '580px';
			dialogConfig.panelClass = 'dap-model';
			dialogConfig.data = this.model;
			const modalDialog = this.matDialog.open(TripOptionAddEditPopupComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					this.tripOptionList();
				}
			});
		});
	}
	deleteTripOption(id: number) {
		if (confirm('Are you sure to delete the current record?')) {
			this._tripOptionService.deleteTripOption(id).subscribe((result) => {
				this.response = result;
				if (result && result.messageCode == 1) {
					this.tripOptionList();
				}
			});
		}
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}
export interface TripOptionEntity {
	id: number;
	categoryId: number;
	years: number;
	destination: string;
	tripCode: string;
	defaultNameTypeString:string,
	airportCode: string;
	categoryName: string;
	isActive: boolean;
	isActiveText:string;
}
