import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export function emptyValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const matches = control.value.replace(/\s/g, '');
		if (matches && matches.length > 0) {
			return null;
		} else {
			return {
				emptyName: {
					valid: false
				}
			};
		}
	};
}