import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReportLogService } from 'src/app/services/report-log.service';
import { TravelerService } from 'src/app/services/traveler.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-report-log-rollover-points',
  templateUrl: './report-log-rollover-points.component.html',
  styleUrls: ['./report-log-rollover-points.component.css'],
})
export class ReportLogRolloverPointsComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  years = new Date().getFullYear() - 1;
  searchText = '';

  public displayedColumns = [
    //'updatedOnDisplay',
    'formattedDate',
    'formattedTime',
    'updatedByName',
    'customerNumber',
    'companyName',
    'years',
    'rollOverPoints',
    'pullOverPoints',
    'totalRollOverPoints',
    'recordTypeDisplay',
  ];

  public dataSource = new MatTableDataSource<UpdatedTravelerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      2021,
      new Date().getFullYear() + 1
    );
    this.CustomerRolloverPointList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  CustomerRolloverPointList() {
    this.dataSource.data = [];
    this.reportLogService
      .getCustomerRolloverPointList(this.years)
      .subscribe((result) => {
        this.model = this.dataSource.data = result as UpdatedTravelerEntity[];
        this.dataSource.paginator = this.paginator;
      });
  }

  changeYear() {
    this.CustomerRolloverPointList();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
  exportCustomer() {
    this.reportLogService.getCustomerRolloverPointListExport(this.years);
  }
}

export interface UpdatedTravelerEntity {
  updatedOnDisplay: string;
  updatedByName: string;
  customerNumber: string;
  companyName: string;
  years: number;
  availablePoints: number;
  rollOverPoints: number;
  pullOverPoints: number;
  totalRollOverPoints: number;
  rolledbackPointType: number;
  rolledBackPointTypeDisplay: string;
  recordTypeDisplay: string;
  enrollmentId:number;
  formattedDate:string;
  formattedTime:string;
}
