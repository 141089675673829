import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
  MatDialogConfig,
} from '@angular/material';
import { Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import * as XLSX from 'xlsx';
import { UtilityService } from 'src/app/services/utility.service';
@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
})
export class CustomerListComponent implements OnInit {
  public displayedColumns = [
    'ppewhsCodeString',
    'customerNumber',
    'companyName',
    'sxeAddress',
    'ppeSalesmanCodeString',
  ];
  public dataSource = new MatTableDataSource<CustomerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  model: any = {};
  searchText = '';
  constructor(
    private customerService: CustomerService,
    private _router: Router,
    private matDialog: MatDialog,
    public _utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.customerList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  customerList() {
    this.customerService.getCustomerList().subscribe((result) => {
      this.dataSource.data = result as CustomerEntity[];
      this.dataSource.paginator = this.paginator;
      this.model = result;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
  exportCustomer() {
    // let timeSpan = new Date().toISOString();
    // let prefix = name || "Perm_Not_Eligible_Customers";
    // let fileName = `${prefix}-${timeSpan}`;
    // let targetTableElm = document.getElementById("tblnoncustomer");
    // let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{ sheet: prefix });
    // XLSX.writeFile(wb, `${fileName}.xlsx`);

    this.customerService.getCustomerListPremNotEligibleExport(this.searchText);
  }
}
export interface CustomerEntity {
  id: number;
  customerNumber: string;
  companyName: string;
  companyTypeString: string;
  ppewhsCodeString: string;
  ppeSalesmanCodeString: string;
  sxeAddress1: string;
  sxeAddress2: string;
  sxeAddress3: string;
  sxeCity: string;
  sxeState: string;
  sxeZip: string;
  isActive: boolean;
}
