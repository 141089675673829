import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { ReportLogService } from "src/app/services/report-log.service";
import { TravelerService } from "src/app/services/traveler.service";
import { UtilityService } from "src/app/services/utility.service";

@Component({
  selector: "app-report-log-updated-traveler",
  templateUrl: "./report-log-updated-traveler.component.html",
  styleUrls: ["./report-log-updated-traveler.component.css"],
})
export class ReportLogUpdatedTravelerComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = "";
  tripId = "";
  searchText = "";

  public displayedColumns = [
    //"deletedOnDisplay",
    "formattedDate",
    "formattedTime",
    "deletedByName",
    "tripCode",
    "ppeWhsCode",
    "salesmanCode",
    "customerNumber",
    "companyName",
    "groups",
    "groupsOld",
    "legalFirstName",
    "legalFirstNameOld",
    "legalMiddleName",
    "legalMiddleNameOld",
    "legalLastName",
    "legalLastNameOld",
    "legalSuffix",
    "legalSuffixOld",
    "nickName",
    "nickNameOld",
    "dateofBirthDisplay",
    "dateofBirthOldDisplay",
    "gender",
    "genderOld",
    "cellPhone",
    "cellPhoneOld",
    "emailAddress",
    "emailAddressOld",
    "preferredAirportCode",
    "preferredAirportCodeOld",

    "preferredAirline",
    "preferredAirlineOld",
    "prefAirlineFreqFlier",
    "prefAirlineFreqFlierOld",

    "outgoingFlightDateDisplay",
    "outgoingFlightDateOldDisplay",
    "returnFlightDateDisplay",
    "returnFlightDateOldDisplay",
    "isDepartureFlight",
    "isDepartureFlightOld",
    "isReturnFlight",
    "isReturnFlightOld",

    "passportName",
    "passportNameOld",
    "trustedTraveler",
    "trustedTravelerOld",

    "passport",
    "passportOld",
    "passportExpiration",
    "passportExpirationOld",
    "passportIssuingCountry",
    "passportIssuingCountryOld",
    "drinkChoiceDisplay",
    "drinkChoiceOldDisplay",
    "companyOwnerDisplay",
    "companyOwnerOldDisplay",
    "traverlerDesignationId",
    "oldTraverlerDesignationId",
    "isAuthorizedtoSpendPoints",
    "oldIsAuthorizedtoSpendPoints",
    "wheelChairRequest",
    "oldWheelChairRequest",
  ];

  public dataSource = new MatTableDataSource<UpdatedTravelerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService
  ) { }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updatedTravelerList() {
    this.dataSource.data = [];
    if (+this.tripId > 0 && +this.years > 0) {
      this.reportLogService
        .getUpdatedTravelerList(this.years, this.tripId)
        .subscribe((result) => {
          this.model = this.dataSource.data = result as UpdatedTravelerEntity[];
          this.dataSource.paginator = this.paginator;
        });
    }
  }

  changeYear() {
    this.tripId = "";
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
  }

  changeTrip() {
    this.updatedTravelerList();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
}

export interface UpdatedTravelerEntity {
  id: number;
  deletedOnDisplay: string;
  deletedByName: string;
  tripCode: string;
  ppeWhsCode: string;
  salesmanCode: string;
  customerNumber: string;
  companyName: string;
  groups: number;
  groupsOld: number;
  legalFirstName: string;
  legalFirstNameOld: string;
  legalMiddleName: string;
  legalMiddleNameOld: string;
  legalLastName: string;
  legalLastNameOld: string;
  legalSuffix: string;
  legalSuffixOld: string;
  nickName: string;
  nickNameOld: string;
  dateofBirthDisplay: string;
  dateofBirthOldDisplay: string;
  gender: number;
  genderOld: number;
  cellPhone: string;
  cellPhoneOld: string;
  emailAddress: string;
  emailAddressOld: string;
  preferredAirportCode: string;
  preferredAirportCodeOld: string;
  outgoingFlightDateDisplay: string;
  outgoingFlightDateOldDisplay: string;
  returnFlightDateDisplay: string;
  returnFlightDateOldDisplay: string;
  isDepartureFlight: boolean;
  isDepartureFlightOld: boolean;
  isReturnFlight: boolean;
  isReturnFlightOld: boolean;
  passport: string;
  passportOld: string;
  passportExpiration: string;
  passportExpirationOld: string;
  passportIssuingCountry: string;
  passportIssuingCountryOld: string;
  drinkChoiceDisplay: string;
  drinkChoiceOldDisplay: string;
  oldTraverlerDesignationId: number;
  traverlerDesignationId: number;
  oldIsAuthorizedtoSpendPoints: boolean;
  isAuthorizedtoSpendPoints: boolean;
  oldWheelChairRequest: boolean;
  wheelChairRequest: boolean;
  traverlerDesignationDisplay: string;
  oldTraverlerDesignationDisplay: string;
  passportName: string;
  passportNameOld: string;
  preferredAirline: string;
  preferredAirlineOld: string;
  prefAirlineFreqFlier: string;
  prefAirlineFreqFlierOld: string;
  trustedTraveler: string;
  trustedTravelerOld: string;
  companyOwnerDisplay: string;
  companyOwnerOldDisplay: string;
  formattedDate: string;
  formattedTime: string;
}
