import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReportLogService } from 'src/app/services/report-log.service';
import { TravelerService } from 'src/app/services/traveler.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-report-log-name-badge',
  templateUrl: './report-log-name-badge.component.html',
  styleUrls: ['./report-log-name-badge.component.css']
})
export class ReportLogNameBadgeComponent implements OnInit {

  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = '';
  tripId = '';
  searchText = '';
  

  public displayedColumns = [
    'ppeWhsCode',
    'masterUniqueIdentifier',
    'customerNumber',
    'companyName',
    'groups',
    'legalFirstName',
    'legalLastName',
    'nickName',
    'nameBadgeLegalFirstName',
    'nameBadgeLegalLastName',
    'companyOwner',
    'traverlerDesignationText',
  ];

  public dataSource = new MatTableDataSource<NameBadgeReportEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService,
    private travelerService: TravelerService,
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    //this.NameBadgeList();
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
 
  NameBadgeList() {
    //console.log(this.dataSource.data);
    this.dataSource.data = [];
    if (+this.tripId > 0 && +this.years > 0) {
    this.reportLogService
      .getNameBadgeList(this.years,this.tripId)
      .subscribe((result) => {
        this.model = this.dataSource.data = result as NameBadgeReportEntity[];
        this.dataSource.paginator = this.paginator;
      });
    }
  }
  
  changeYear() {
    this.tripId = '';
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
  }
  
  changeTrip() {
    this.NameBadgeList();
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
}

export interface NameBadgeReportEntity {
  ppeWhsCode: string;
  masterUniqueIdentifier:number;
  customerNumber: string;
  companyName: string;
  CustomerId: number;
  legalFirstName: string;
  legalLastName: string;
  nickName: string;
  nameBadgeLegalFirstName: string;
  nameBadgeLegalLastName: string;
  groups: string;
  traverlerDesignationText:string;
  companyOwner: boolean;
}
