import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { TravelerService } from '../../services/traveler.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-report-layover-hotels',
  templateUrl: './report-layover-hotels.component.html',
  styleUrls: ['./report-layover-hotels.component.css'],
})
export class ReportLayoverHotelsComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  groupList = [];
  years = '';
  tripId = '';
  groups = '';
  searchText = '';

  public displayedColumns = [
    'ppeWhsCode',
    'customerNumber',
    'companyName',
    'groups',
    'legalFirstName',
    'legalLastName',
    'layoverCity',
    'checkInDate',
    'checkOutDate',
    'preferredBedType',
    'hotelName',
    'hotelConfirmation',
    'layoverHotelAddress',
    'layoverHotelPhone',
    'cost',
    'salesmanName',
    'trip',
  ];

  public dataSource = new MatTableDataSource<TravelerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
    //this._utilityService.getCurrentDateTime();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  travelerList() {
    if (+this.tripId > 0) {
      let maxgroups = '';
      this.travelerService
        .reportLayoverHotels(+this.tripId, +this.years, null)
        .subscribe((result) => {
          this.model = this.dataSource.data = result as TravelerEntity[]; //.fltier(e=>e.isTripAdded)
          maxgroups = this.model[0].maxGroups;
          this.groupList = this._utilityService.fillGroup(+maxgroups);
          this.dataSource.paginator = this.paginator;
        });
    } else this.dataSource.data = [];
  }

  changeYear() {
    this.tripId = '';
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.travelerList();
  }

  changeTrip() {
    this.groups = '';
    this.travelerList();
  }
  // changeGroup()
  // {
  //      this.travelerList();
  // }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }
}

export interface TravelerEntity {
  trip: string;
  ppeWhsCode: string;
  customerNumber: string;
  companyName: string;
  salesmanName: string;
  groups: number;
  nickName: string;
  traveler: string;
  legalSuffix: string;
  layoverCity: string;
  layoverDate: string;
  preferredBedType: string;
  hotelName: string;
  cost: string;
  hotelConfirmation: string;
  legalFirstName: string;
  legalLastName: string;
  checkInDate: string;
  checkOutDate: string;
  layoverHotelAddress: string;
  layoverHotelPhone: string;
}
