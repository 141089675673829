import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { PointsAndRewardsService } from 'src/app/services/points-and-rewards.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
	selector: 'app-import-points-list',
	templateUrl: './import-points-list.component.html',
	styleUrls: [ './import-points-list.component.css' ]
})
export class ImportPointsListComponent implements OnInit {
	public displayedColumns = [
		'dateUpdatedDisplay',
		'customerNumber',
		'companyName',
		'pointMonthText',
		'pointYear',
		'points'
	];
	public dataSource = new MatTableDataSource<PointsAndRewardsEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	currentMonth;
	currentYear;
	submitted: boolean = false;
	public importForm: FormGroup;
	yearList: any = [];
	monthList: any = [];
	constructor(
		private pointsAndRewardService: PointsAndRewardsService,
		private _router: Router,
		private fb: FormBuilder,
		private _utilityService: UtilityService
	) {
		this.currentYear = new Date().getFullYear();
		this.currentMonth = new Date().getMonth() + 1;

		this.importForm = this.fb.group({
			PointYear: [ this.currentYear, Validators.required ],
			PointMonth: [ this.currentMonth, Validators.required ]
		});
	}

	ngOnInit() {
		if (localStorage.getItem('PointsAndRewardImport') != null) {
			this.response = JSON.parse(localStorage.getItem('PointsAndRewardImport'));
			localStorage.removeItem('PointsAndRewardImport');
			this.f.PointYear.setValue(this.response.PointYear);
			this.f.PointMonth.setValue(this.response.PointMonth);
		}

		this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear() + 1);
		this.monthList = this._utilityService.getMonthList();

		this.importedPointsAndRewardsList();
	}
	get f() {
		return this.importForm.controls;
	}
	importSearch() {
		this.submitted = true;
		if (this.importForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		this.importedPointsAndRewardsList();
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	importedPointsAndRewardsList() {
		this.pointsAndRewardService
			.getPointsAndRewardsList(this.f.PointYear.value, this.f.PointMonth.value)
			.subscribe((result) => {
				// console.log(result);
				this.dataSource.data = result as PointsAndRewardsEntity[];
				this.dataSource.paginator = this.paginator;
			});
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}
export interface PointsAndRewardsEntity {
	pointYear: number;
	pointMonthText: string;
	points: string;
	dateUpdatedDisplay: string;
	customerNumber: string;
	companyName: string;
}
