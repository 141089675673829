import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UpdateNameService } from '../services/update-name.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userData :any={}
  title
  constructor(private router: Router) { 
    //this.subscription = this.messageService.getUserData().subscribe(data => this.messageFromSibling = data)
    //  this.messageService.getUserData().subscribe(
    //   this.userData = JSON.parse(localStorage.getItem('user'))
    //  )
  }

  // constructor(private messageService: MessageService) {
  //   this.subscription = this.messageService.getMessage()
  //   .subscribe(mymessage => this.messageFromSibling = mymessage)
  // }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('user'));
  }
  logOut() 
  {
    localStorage.setItem('user', null);
    localStorage.setItem('role', null);
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.clear();
    this.router.navigate(['login']);
  }

}
