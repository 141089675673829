import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

let myAppUrl = '';
@Injectable({
  providedIn: 'root',
})
export class ReportLogService {
  
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }

  getDeletedTravelerList(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl + 'api/ReportLog/DeletedTravelerList/' + years + '/' + tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getUpdatedTravelerList(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl + 'api/ReportLog/UpdatedTravelerList/' + years + '/' + tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getGroupDetailUpdateList(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl + 'api/ReportLog/GroupDetailUpdateList/' + years + '/' + tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTravelerFlightDetailUpdateList(years:number, tripId:number, pageIndex : number, pageSize : number,searchText:string) : Observable<any> {
    return this._http
      .get<any>(
        myAppUrl +
        'api/ReportLog/TravelerFlightDetailUpdateList?tripId=' 
         + tripId +
        '&years=' + years + 
        '&pageNumber=' + pageIndex +
        '&recordCount=' + pageSize +
        '&searchText=' + searchText
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTravelerLayoverHotelDetailUpdateList(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/ReportLog/TravelerLayoverHotelDetailUpdateList/' +
        years +
        '/' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTravelerLayoverHotelDetailDeletedList(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/ReportLog/TravelerLayoverHotelDetailDeletedList/' +
        years +
        '/' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTravelerFlightDeletedList(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/ReportLog/TravelerFlightDeletedList/' +
        years +
        '/' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTravelerHotelUpdateList(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/ReportLog/TravelerHotelUpdateList/' +
        years +
        '/' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCustomerTripUpdatedList(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/ReportLog/CustomerTripUpdatedList/' +
        years +
        '/' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCustomerParentChildRelationUpdatedList() {
    return this._http
      .get<any>(
        myAppUrl + 'api/ReportLog/CustomerParentChildRelationUpdatedList/'
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCustomerParentChildRelationUpdatedListExport() {
    window.open(
      myAppUrl + 'api/ReportLog/CustomerParentChildRelationUpdatedListExport'
    );
  }
  getCustomerRolloverPointListExport(years: number) {
    window.open(
      myAppUrl + 'api/ReportLog/CustomerRolloverPointListExport/' + years
    );
  }
  getCustomerTripUpdatedListExport(years: number, tripId: number) {
    window.open(
      myAppUrl +
      'api/ReportLog/CustomerTripUpdatedListExport/' +
      years +
      '/' +
      tripId
    );
  }

  getCustomerRolloverPointList(years: number) {
    return this._http
      .get<any>(myAppUrl + 'api/ReportLog/CustomerRolloverPointList/' + years)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  ///Added by Chandan
  getOriginalTripSelectionColumns(years) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/ReportLog/ReportOriginalTripSelectionColumns/' +
        years
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getOriginalTripSelectionListExport(years: number) {
    window.open(
      myAppUrl +
      'api/ReportLog/OriginalTripSelectionListExport/' +
      years
    );
  }

  getOriginalGroupRegistrationColumns(years, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/ReportLog/ReportOriginalGroupRegistrationListColumns?years=' +
        years +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  //Added on 29/04/2024
  getNameBadgeList(years, tripId) {
    return this._http
      .get<any>(myAppUrl + 'api/ReportLog/NameBadgeReport/' +
        years +
        '/' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getHistoricalPoint() {
    return this._http
      .get<any[]>(myAppUrl + 'api/ReportLog/HistoricalPointReport')
      .pipe(
        map(data => {
          return data;
        }));
  }
  getHistoricalTraveler(categoryId) {
    return this._http
      .get<any[]>(myAppUrl + 'api/ReportLog/HistoricalTravelerColumns?categoryId=' + categoryId)
      .pipe(
        map(data => {
          return data;
        }));
  }
  getCategoryList() {
    return this._http.get<any>(myAppUrl + 'api/TripOption/TripCategoryList')
      .pipe(map(data => { return data; }))
  }
  exportHistoricalPoint() {
    window.open(myAppUrl + 'api/ReportLog/HistoricalPointReportExport/');
  }
  getNightlyCount(payload) {
    return this._http.get<any>(`${myAppUrl + 'api/ReportLog/NightlyRoomCountReport'}?tripId=${payload.tripId}&year=${payload.year}&minCheckinDate=${payload.minCheckinDate}&maxCheckoutDate=${payload.maxCheckout}`)
      .pipe(map(data => { return data; }))
  }

  nightlyRoomAvailability(payload: any): Observable<any> { 
    return this._http.get<any>(`${myAppUrl + 'api/ReportLog/NightlyRoomAvailability'}?tripId=${payload.tripId}&year=${payload.year}&startDate=${payload.startDate}&endDate=${payload.endDate}`)
      .pipe(map(data => { return data; }))
  }

  saveNightlyCount(payload) {
    return this._http.post<any>(myAppUrl + 'api/ReportLog/AddUpdateNightlyRoomCount',payload)
      .pipe(map(data => { return data; }))
  }

  groupCheckInCheckoutDateByTripId(tripId: number): Observable<any> { 
    return this._http.get<any>(`${myAppUrl + 'api/ReportLog/GroupCheckInCheckoutDateByTripId'}?tripId=${tripId}`)
      .pipe(map(data => { return data; }))
  }

  updateNightlyRoomAvailability(payload: any): Observable<any> {
    return this._http.put<any>(myAppUrl + 'api/ReportLog/updateNightlyRoomAvailability',payload)
      .pipe(map(data => { return data; }))
  }
}