import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { FormControl, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { EnrollmentService } from '../../services/enrollment.service';
import { TripSelectionComponent } from '../../Components/trip-selection/trip-selection.component';
import { TravelerComponent } from '../../Components/traveler/traveler.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-trip-enrollment-test',
  templateUrl: './trip-enrollment-test.component.html',
  styleUrls: ['./trip-enrollment-test.component.css']
})
export class TripEnrollmentTestComponent implements OnInit {
	model: any = {};
	customerList: any = [];
	yearList: any = [];
	selected = new FormControl(1);
	response: any = {};
	myControl = new FormControl();
	customerId: number = 0;
	parmId = 0;
	enrollmentId: number = 0;
	isProceed = false;
	isEditDAPAdd = false;
	isEditDAP = false;
	isTripAdded = 1;
	sub: any;
	role = +localStorage.getItem('role');
	isEditCompanyInfo = false;
	commonMethodList = [];
	pCustomerList= [];
	public mask = [ '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];
	dapAddressModel: { dapAddress1: ''; dapAddress2: ''; dapAddress3: ''; dapCity: ''; dapState: ''; dapZip: '' };
	years = new Date().getFullYear();
	constructor(
		private customerService: CustomerService,
		private _router: Router,
		private enrollmentService: EnrollmentService,
		private route: ActivatedRoute,
		private matDialog: MatDialog,
		private _utilityService: UtilityService,
		private fb: FormBuilder
	) {}

	ngOnInit() {

		this.commonMethodList = this._utilityService.getCommonMethod();
		this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear() + 1);
		this.sub = this.route.params.subscribe((params) => {
			this.enrollmentId = this.parmId = +params['id']; // (+) converts string 'id' to a number
			this.isTripAdded = +params['isTripAdded'];
		});
		if (this.enrollmentId > 0) {
			this.enrollmentService.getEnrollmentDetail(this.enrollmentId).subscribe((result) => {
				this.model = result;
				// console.log(this.model);
				this.yearList = this.yearList.filter(function(y) {
					return y == result.years;
				});
				this.years = this.yearList[0];
			});
		} else {
			this.enrollmentId = 0;
			this.enrollmentService.getCustomerNameList('').subscribe((result) => {
				this.customerList = result;
			});
		}
		if (this.isTripAdded == 0) {
			this.addTripSelection();
		}
		this.customerService.getCustomerNameList('', this.model.parentCompanyId).subscribe((result) => {
			this.pCustomerList = result;
		});
	}
	changeTab(index: number) {
		if (this.parmId) {
		} else {
			this.years = this.yearList[index];
			this.enrollmentId = 0;
			this.customerId = 0;
			this.selected.setValue(Event);
		}
	}

	private typed(value: string) {
		if (value.length >= 3) {
			var emptlist = [ { id: 0, customerName: 'No data found' } ];
			this.customerList = [];
			this.enrollmentService.getCustomerNameList(value).subscribe((result) => {
				if (result && result.length > 0) this.customerList = result;
				else this.customerList = emptlist;
			});
		}
	}

	private select(value: number) {
		if (value > 0) {
			this.customerId = value;
			this.isProceed = true;
		}
	}

	proceed() {
		this.enrollmentService.proceedEnrollment(this.customerId, this.years).subscribe((result) => {
			this.model = result.data;
			this.enrollmentId = this.model.id;
			this.isProceed = false;
		});
	}
	addDAPAddress() {
		this.dapAddressModel = this.model;
		this.model.dapAddress1 = '';
		this.model.dapAddress2 = '';
		this.model.dapAddress3 = '';
		this.model.dapCity = '';
		this.model.dapState = '';
		this.model.dapZip = '';
		this.isEditDAPAdd = true;
	}
	cancelDAPAddress() {
		// this.dapAddressModel =this.model;
		this.model.dapAddress1 = this.dapAddressModel.dapAddress1;
		this.model.dapAddress2 = this.dapAddressModel.dapAddress2;
		this.model.dapAddress3 = this.dapAddressModel.dapAddress3;
		this.model.dapCity = this.dapAddressModel.dapCity;
		this.model.dapState = this.dapAddressModel.dapState;
		this.model.dapZip = this.dapAddressModel.dapZip;
		this.isEditDAPAdd = false;
	}
	updateDAPAddress(form: NgForm) {
		if (form.valid) {
			this.enrollmentService.updateDAPAddress(this.model).subscribe((result) => {
				if (result) {
					this.response = result;
					if (result.messageCode == 1) {
						this.dapAddressModel = this.model;
						this.isEditDAPAdd = false;
					}
				}
			});
		} else {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
	}

	updatedCustomerDAP(form: NgForm) {
		if (form.valid) {
			this.customerService.updatedCustomerDAP(this.model).subscribe((result) => {
				if (result) {
					this.response = result;
					if (result.messageCode == 1) {
						this.isEditDAP = false;
					}
				}
			});
		} else {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
	}
	addTripSelection() {
		this.response = { isShow: false };
		this.enrollmentService.getTripSelection(this.enrollmentId).subscribe((result) => {
			//this.model = result;
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '650px';
			dialogConfig.data = result;
			const modalDialog = this.matDialog.open(TripSelectionComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					if (result.messageCode == '1' && this.parmId) {
						localStorage.setItem('tripOptionUpdate', JSON.stringify(result));
						this._router.navigate([ '/trip-enrollment' ]);
					}
				}
			});
		});
	}

	addTraveler() {
		this.response = { isShow: false };
		this.enrollmentService.getTripList(this.enrollmentId).subscribe((result) => {
			//this.model = result;
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '1250px';
			dialogConfig.data = result;
			const modalDialog = this.matDialog.open(TravelerComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					if (result.messageCode == '1' && this.parmId) {
						localStorage.setItem('tripOptionUpdate', JSON.stringify(result));
						this._router.navigate([ '/trip-enrollment' ]);
					}
				}
			});
		});
	}

	deleteEnrollment() {
		this.response = { isShow: false };
		if (confirm('Are you sure you want to delete this trip enrollment?')) {
			this.enrollmentService.deleteEnrollment(this.enrollmentId, this.customerId).subscribe((result) => {
				this.response = result;
				if (this.response.messageCode == 1) {
					// debugger;
					localStorage.setItem('deleteEnroll', '1');
					this._router.navigate([ '/trip-enrollment' ]);
				}
			});
		}
	}

	updatedCompanyInfo(form: NgForm) {
		if (form.valid) {
			if (!this.model.lastYearSales) this.model.lastYearSales = 0;
			// console.log(this.model);
      this.model.LogUserId= this._utilityService.getCurrentUserId();
			this.customerService.updatedCompanyInfo(this.model).subscribe((result) => {
				if (result) {
					this.response = result;
					if (result.messageCode == 1) {
						this.isEditCompanyInfo = false;
						this.enrollmentService.getEnrollmentDetail(this.enrollmentId).subscribe((result) => {
							this.model = result;
						});
					}
				}
			});
		} else {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
	}

	private typedP(value: string) {
		if (value.length >= 3) {
			var emptlist = [ { id: 0, customerName: 'No data found' } ];
			this.pCustomerList = [];
			this.customerService.getCustomerNameList(value, this.model.parentCompanyId).subscribe((result) => {
				if (result && result.length > 0) this.customerList = result;
				else this.pCustomerList = emptlist;
			});
		}
	}
	ngOnDestroy() {
		this.sub.unsubscribe();
	}
}




