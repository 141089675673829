import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, PageEvent, MatTableDataSource } from "@angular/material";
import { TravelerService } from "src/app/services/traveler.service";
import { UtilityService } from "src/app/services/utility.service";
import * as XLSX from 'xlsx';

@Component({
  selector: "app-report-traveler-common",
  templateUrl: "./report-traveler-common.component.html",
  styleUrls: ["./report-traveler-common.component.css"],
})
export class ReportTravelerCommonComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years;
  tripId = '';
  searchText = '';
  pageTitle = "Traveler Report ";

  @Input() xReportType: number;
  public displayedColumns = [
    "ppeWhsCode",
    "customerNumber",
    "companyName",
    "groups",
    "firstName",
    //"middleName",
    "lastName",
    "nickName",
    "gender",
    "dateOfBirth",
    "cellPhone",
    "emailAddress",
    "isDepartureFlight",
    "isReturnFlight",
    "preferredAirportCode",
    "hotelName",
    "dhCheckInDate",
    "dhCheckOutDate",
    "weekDesignationDisplay",
    "miscGroupRequest",
    "notes",
    "wheelChairRequest",
    "companyOwnerText",
    "traverlerDesignationText",
    "salesmanName",
    "trip",
  ];

  public dataSource = new MatTableDataSource<TravelerEntity>();
  @ViewChild(MatPaginator, { static: true })
  resultsLength = 0;
  pageSize = 25;
  pageEvent: PageEvent;

  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  response: any = {};
  exporter: any;
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );

    var user = JSON.parse(localStorage.getItem("user"));

    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
      //debugger;
      // Bind Traveler By User
      if (this.xReportType == 2) {
        this.years = new Date().getFullYear();
        this.pageTitle = "Travelers";
        this.changeYear();
        if (this.tripOptionList.length > 0) {
          var userTripCount = this.tripOptionList.filter(
            (t) => t.tripId == user.tripId
          ).length;
          this.tripId = userTripCount > 0 ? user.tripId : "";
        }

        // find tripId from tripOptionList

        this.travelerList(this.searchText);
      } else {
        this.years = "";
        this.tripId = "";
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  travelerList(searchText) {
    this.dataSource.data = [];
    let pageIndex = this.pageEvent ? this.pageEvent.pageIndex + 1 : 1;
    const pageSize = this.pageEvent ? this.pageEvent.pageSize : this.pageSize;

    if (searchText) {
      pageIndex = 1;
    }
    //if (+this.tripId > 0 && +this.years > 0) {
      this.travelerService
        .reportTravelerWithGroupList(+this.tripId, +this.years, pageIndex, pageSize, searchText)
        .subscribe((result) => {
          this.dataSource.data = result.data as TravelerEntity[];
          this.resultsLength = result.totalCount;
          if (this.paginator) {
            this.paginator.pageSize = pageSize;
            this.paginator.length = this.resultsLength;
          }
        });
    //}
  }

  loadPage() {
    this.travelerList(this.searchText);
  }
  changeYear() {
    this.tripId = "";
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
    //console.log(this.tripId);
  }

  changeTrip() {
    this.travelerList(this.searchText);
  }
  applyFilter(filterValue: string) {
    this.searchText = filterValue.trim().toLowerCase();
    this.travelerList(this.searchText);
  }
  exportToExcel(): void {
    const pageIndex = 1;
    const pageSize = this.resultsLength;

    if (+this.tripId > 0 && +this.years > 0) {
      this.travelerService
        .reportTravelerWithGroupList(+this.tripId, +this.years, pageIndex, pageSize, this.searchText)
        .subscribe((result) => {
          const data = result.data.map(item => {
            return {
              'Whs': item.ppeWhsCode,
              'Company#': item.customerNumber,
              'Company Name': item.companyName.replace(/&#39;/g, "'"),
              'Groups': item.groups,
              'First Name': item.firstName,
              //'middleName': item.middleName,
              'Last Name': item.lastName,
              'Nick Name': item.nickName,
              'Gender': item.gender == 1 ? 'M' : 'F',
              'Date Of Birth': item.dateOfBirth,
              'Cell Phone': item.cellPhone,
              'Email': item.emailAddress,
              'Departure Flight': item.isDepartureFlight ? 'Y' : 'N',
              'Return Flight': item.isReturnFlight ? 'Y' : 'N',
              'Airport Requested': item.preferredAirportCode,
              'Hotel Designation': item.hotelName,
              'Hotel Check-In': item.dhCheckInDate,
              'Hotel Check-Out': item.dhCheckOutDate,
              'Week Designation': item.weekDesignationDisplay,
              'Misc. Group Request': item.miscGroupRequest,
              'Notes': item.notes,
              'Wheel Chair': item.wheelChairRequest ? 'Y' : 'N',
              'Company Owner': item.companyOwnerText,
              'Employee': item.traverlerDesignationText,
              'Salesman': item.salesmanName,
              'Trip#': item.trip
            };
          });
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Traveler Report');
          XLSX.writeFile(wb, `Traveler_Report_${this._utilityService.getCurrentDateTime()}.xlsx`);
        });
    }
  }
}
export interface TravelerEntity {
  trip: string;
  ppeWhsCode: string;
  customerNumber: string;
  companyName: string;
  salesmanName: string;
  groups: string;
  firstName: string;
  middleName: string;
  lastName: string;
  nickName: string;
  traveler: string;
  gender: string;
  legalSuffix: string;
  dateOfBirth: string;
  cellPhone: string;
  emailAddress: string;
  isDepartureFlight: boolean;
  isReturnFlight: boolean;
  weekDesignationDisplay: string;
  traverlerDesignationText: string;
  rentACar: string;
  wheelChairRequest: string;
  miscGroupRequest: string;
  notes: string;
  companyOwnerText: string;
  preferredAirportCode: string;
  dhcheckInDate: string;
  dhcheckOutDate: string;
  enrollmentId: number;
  hotelName: string;
}
