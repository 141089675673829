import { Component } from '@angular/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent {
  isExpanded = false;
  showmenu: boolean = false;
  showmenuReport: boolean = false;
  showPointRewards: boolean = false;
  userData: any = {};
  displayMenu = true;
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('user'));

    // Check User Role, if user role is Report Only then only dashboard & report menu will be displayed.
    this.displayMenu = this.userData.roles != 4;
  }

  collapse() {
    this.isExpanded = false;
  }

  closeMenu() {
    this.showmenu = false;
  }
  openMenu() {
    this.showmenu = true;
  }
  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  showReport() {
    this.isExpanded = true;
  }
}
