import { Component, OnInit, Inject } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';
import { HttpClient } from '@angular/common/http';
import { TravelerService} from '../../services/traveler.service';

@Component({
  selector: 'app-report-shipping',
  templateUrl: './report-shipping.component.html',
  styleUrls: ['./report-shipping.component.css']
})
export class ReportShippingComponent implements OnInit {
  gridColumn=[];
  model: any = {};
  settings: any;
  mySettings:any;
	data: any={};
  response: any = {};
  yearList: any = [];
  tripOptionList=[]
  alltripList=[]
  tripId='';
  from :number=1; to:number=25; totalRecord:number;
  years = new Date().getFullYear();
  constructor(private enrollmentService: EnrollmentService,
		private _router: Router,
    private travelerService: TravelerService,
		private _utilityService: UtilityService,
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string) {
     
      this.mySettings = {
        columns: {},
        actions: {
          edit: false,
          add: false,
          delete: false,
          position: 'right'
        },
        noDataMessage: "No record found.",
        pager: {
          display: true,
          perPage: 25
        },
        attr: {
          class: 'table table color-table dark-table table-striped'
        }
      };
      this.settings = Object.assign({}, this.mySettings);
      this.travelerHeader(this.years);
     // this.travelerList(this.years);
     }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 3, new Date().getFullYear() + 1);
    this.model.Year=this.years;
    this.travelerService.getTripCodeList().subscribe((result) => {	
      this.alltripList=result;	
      if(this.alltripList && this.alltripList.length>0)
      this.tripOptionList=this.alltripList.filter(t=> t.years==this.years);
  });
    //this.customerStatementList(this.years);
    this.data.onChanged().subscribe((change) => {	
			this.from=((change.paging.page-1)*change.paging.perPage)+1;
			this.to=this.data.lastRequestCount <=change.paging.page*change.paging.perPage?this.data.lastRequestCount:change.paging.page*change.paging.perPage;
		});
  }
  travelerList(year)
  {
    if(+this.tripId>0){
    this.data = new ServerDataSource(this.http, {
			endPoint: this.baseUrl + 'api/Traveler/ReportShipping?year='+year+'&tripId='+this.tripId,
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});
  }
  else this.data =[];
  }
  bindTravelerList()
  {
	  	  this.tripId=''
        this.tripOptionList=[];
				if(this.alltripList && this.alltripList.length>0)
				this.tripOptionList=this.alltripList.filter(t=> t.years==this.model.Year);	  			
        this.travelerHeader(this.model.Year);
  }

  travelerHeader(year) { 
		this.travelerService.getShippingHeader(year,+this.tripId).subscribe((result) => {
      //console.log(result);
		 this.gridColumn= result;
      //console.log(this.gridColumn);
      this.mySettings.columns={};
      for(let i=0; i<this.gridColumn.length;i++)
      {
        this.mySettings.columns[this.gridColumn[i].columnName] = { title:this.gridColumn[i].title,
            sort:false,
          filter:this.gridColumn[i].filter };     
      }
      this.mySettings.pager= {
        display: true,
        perPage: 25
      };
      this.mySettings.attr= {
        class: 'table table color-table dark-table table-striped'
      };
      this.mySettings.attr= {
        class: 'table table color-table dark-table table-striped'
      };
     
      this.mySettings.actions= {
        edit: false,
        add: false,
        delete: false,
        position: 'right'
      };
      this.settings = Object.assign({}, this.mySettings);
      this.travelerList(this.model.Year);
		});
   
  }
  
  exportTraveler() {
    let filterData = this.data.getFilter();let extraSearch='';
		if(filterData.filters.length>0)
		{
			filterData.filters.forEach(element => {
				extraSearch+= '&'+element.field+'='+element.search;				
			});
		}		
		window.open(this.baseUrl + 'api/Traveler/ReportShippingExport?year='+this.model.Year+'&tripId='+this.tripId +extraSearch);
	}

	changeTrip()
	{
    this.travelerHeader(this.model.Year);	
	}
}
