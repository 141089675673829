import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReportLogService } from 'src/app/services/report-log.service';
import { TravelerService } from 'src/app/services/traveler.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-report-log-customer-parent-child-relation',
  templateUrl: './report-log-customer-parent-child-relation.component.html',
  styleUrls: ['./report-log-customer-parent-child-relation.component.css'],
})
export class ReportLogCustomerParentChildRelationComponent implements OnInit {
  model: any = {};
  searchText = '';

  public displayedColumns = [
//'logCreateDateDisplay',
    'formattedDate',
    'formattedTime',
    'updatedByName',
    'customerNumber',
    'companyName',
    'parentCustomerNumber',
    'parentCompanyName',
    'oldParentCustomerNumber',
    'oldParentCompanyName',
    'currentRelationStatusText',
  ];

  public dataSource = new MatTableDataSource<CustomerParentChildUpdateEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService,
    private reportLogService: ReportLogService
  ) {}

  ngOnInit() {
    this.CustomerParentChildRelationUpdatedList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  CustomerParentChildRelationUpdatedList() {
    this.dataSource.data = [];
    this.reportLogService
      .getCustomerParentChildRelationUpdatedList()
      .subscribe((result) => {
        this.model = this.dataSource.data =
          result as CustomerParentChildUpdateEntity[];
        this.dataSource.paginator = this.paginator;
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.searchText = filterValue;
    this.dataSource.filter = filterValue;
  }

  exportCustomer() {
    this.reportLogService.getCustomerParentChildRelationUpdatedListExport();
  }
}

export interface CustomerParentChildUpdateEntity {
  logCreateDateDisplay: string;
  updatedByName: string;
  customerNumber: string;
  companyName: string;
  parentCustomerNumber: string;
  parentCompanyName: string;
  oldParentCustomerNumber: string;
  oldParentCompanyName: string;
  currentRelationStatusText: string;
  enrollmentId:string;
  formattedDate:string;
  formattedTime:string
}
