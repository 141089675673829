import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AllocatePointsService } from "src/app/services/allocate-points.service";

@Component({
  selector: 'app-purchased-catalog-points',
  templateUrl: './purchased-catalog-points.component.html',
  styleUrls: ['./purchased-catalog-points.component.css']
})
export class PurchasedCatalogPointsComponent implements OnInit {

  purchasedCustomerCatalogPointList: any = [];
  constructor(
    public dialogRef: MatDialogRef<PurchasedCatalogPointsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private allocatePointsService: AllocatePointsService
  ) {}
  ngOnInit() {
    //this.model = this.data;

    this.getPointDetail(this.data.years, this.data.customerId);
  }

  getPointDetail(years, customerId) {
    // display last year points
    // debugger;
    this.allocatePointsService
      .getCatalogBuyPointByCustomer(years, customerId)
      .subscribe((result) => {
        this.purchasedCustomerCatalogPointList = result;
      });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
