import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { TripoptionsService } from "../services/tripoptions.service";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTabChangeEvent,
} from "@angular/material";
import { CustomValidators } from "../Directives/custom-validators";
import { UtilityService } from "../services/utility.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-trip-option-add-edit-popup",
  templateUrl: "./trip-option-add-edit-popup.component.html",
  styleUrls: ["./trip-option-add-edit-popup.component.css"],
})
export class TripOptionAddEditPopupComponent implements OnInit {
  [x: string]: any;
  public whsForm: FormGroup;
  categoryList: any = [];
  yearList: any = [];
  nameTypeList: any = [];
  airportList: any = [];
  response: any = {};
  submitted = false;
  title = "";
  showDeleteButton = false;
  submitButtonText = "Save & Next";
  submitButton = "Save";
  role = +localStorage.getItem("role");
  model = {};
  isOptionalCat: boolean = false;
  tripDestinationList: any = [];
  tripOptionDestinationHotels: any = [];
  tripOptionDestinationHotelsByTrip: any = [];
  tripOptionsID = 0;
  selectedTabIndex = 0;
  isAddedOnGroup: false;
  displayHotelTab = true;
  isSelected = false;
  disableDropDown = false;
  constructor(
    private _tripOptionsService: TripoptionsService,
    private _utilityService: UtilityService,
    private fb: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<TripOptionAddEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.generateForm();
  }

  get f() {
    return this.whsForm.controls;
  }

  generateForm() {
    this.tripOptionsID = this.data.id;
    if (this.data.id > 0) {
      this.title = "Edit Trip Identification";
      this.showDeleteButton = this.role <= 2;
      this.submitButtonText = "Save & Next";
    } else {
      this.title = "Add Trip Identification";
      this.showDeleteButton = false;
      this.data.isActive = true;
      this.data.tripDestinationID = "";
    }

    this.categoryList = this.data.categoryList;
    this.yearList = this.data.yearList;
    this.airportList = this.data.airportList;
    this.tripOptionDestinationHotels = this.data.tripOptionDestinationHotels;

    if (this.tripOptionsID && this.data.categoryId != 2) {
      //debugger;
      this.bindTripHotelByTripOptionId(this.data.tripDestinationID);
    }

    if (this.data.categoryId == 2) {
      this.displayHotelTab = false;
    }
    //this.tripDestinationList = this.data.tripDestinationList;
    this.nameTypeList = this._utilityService.getNameType();
    this.ChangeCat(this.data.categoryId);
    this.EnableAndDisableDropdown();
    //getNameType
    //debugger
    this.whsForm = this.fb.group({
      Id: [this.data.id, Validators.required],
      CategoryId: new FormControl(
        { value: this.data.categoryId, disabled: this.disableDropDown },
        Validators.required
      ),
      Years: new FormControl(
        { value: this.data.years, disabled: this.disableDropDown },
        Validators.required
      ),
      TripDestinationID: new FormControl(
        {
          value: this.data.tripDestinationID
            ? this.data.tripDestinationID
            : undefined,
          disabled: this.disableDropDown,
        },
        Validators.required
      ),
      //Destination: [ "",Validators.required],
      Destination: [
        this.data.destination ? this.data.destination : "",
        Validators.required,
      ],
      //this.data.destination ? this.data.destination :
      TripCode: [
        this.data.tripCode,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      DefaultNameType: [
        this.data.defaultNameType ? this.data.defaultNameType : "",
        Validators.required,
      ],
      AirportId: [
        this.data.airportId ? this.data.airportId : "",
        Validators.required,
      ],
      IsActive: [this.data.isActive],
    });
  }
  EnableAndDisableDropdown() {
    let isHotelSelected =
      this.tripOptionDestinationHotelsByTrip.filter((x) => x.isSelected)
        .length > 0;
    if (isHotelSelected && this.data.id > 0) {
      this.disableDropDown = true;
    } else {
      this.disableDropDown = false;
    }
  }
  bindTripHotelByTripOptionId(tripDestinationID: number) {
    this.tripOptionDestinationHotelsByTrip =
      this.tripOptionDestinationHotels.filter(
        (x) => x.tripDestinationId === tripDestinationID
      );
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  saveWHS() {
    //alert(this.f.TripDestinationID.value);
    this.submitted = true;

    if (this.whsForm.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName("ng-invalid")
      );

      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }

    let dataSave = this.whsForm.value;
    var id = dataSave.Id;
    if (!dataSave.Id) id = 0;

    var categoryId = 0;
    var years = 0;
    var tripDestinationID = 0;

    // If dropdown is disabled then use data from data variable
    if (this.disableDropDown) {
      categoryId = this.data.categoryId;
      years = this.data.years;
      tripDestinationID = this.data.tripDestinationID;
    } else {
      categoryId = dataSave.CategoryId;
      years = dataSave.Years;
      tripDestinationID = Number(this.f.TripDestinationID.value);
    }

    var destinationData = this.tripDestinationList.filter(
      (x) => x.tripDestinationID === tripDestinationID
    );
    var destination = "";
    if (destinationData.length > 0) {
      destination = destinationData[0].tripDestinationName;
    }

    this.model = {
      Id: id,
      CategoryId: categoryId,
      Years: years,
      TripDestinationID: tripDestinationID,
      Destination: destination,
      TripCode: dataSave.TripCode,
      DefaultNameType: dataSave.DefaultNameType ? dataSave.DefaultNameType : 0,
      AirportId: +dataSave.AirportId,
      IsActive: dataSave.IsActive,
    };

    console.log(this.model);
    this._tripOptionsService.saveTripOption(this.model).subscribe((result) => {
      if (result.messageCode == "1" && result.data != null) {
        this.tripOptionsID = this.data.id = result.data.id;
        this.whsForm.controls["Id"].setValue(this.tripOptionsID);

        this.selectedTabIndex = 1;
        console.log(this.displayHotelTab);
        if (this.displayHotelTab) {
          this.bindTripHotelByTripOptionId(tripDestinationID);
        }
        if (
          this.tripOptionDestinationHotelsByTrip.length == 0 ||
          !this.displayHotelTab
        ) {
          this.dialogRef.close(result);
        }
      } else {
        alert(result.message);
      }
    });
  }
  saveTripOptionDestinationHotels() {
    let dataTripHotels = {
      isAddedOnGroup: this.isAddedOnGroup,
      TripOptionsID: this.tripOptionsID,
      TripOptionDestinationHotels: this.tripOptionDestinationHotels.filter(
        (x) => x.isSelected
      ),
    };

    this._tripOptionsService
      .saveTripOptionDestinationHotels(dataTripHotels)
      .subscribe((result) => {
        this.response = result;
        this.dialogRef.close(result);
        // if (result) {
        //   if (result.messageCode == "1") {

        //   } else {
        //     this.response = result;
        //   }
        // }
      });
  }

  ChangeCat(categoryId, isCallfromPageLoad = true) {
    if (categoryId > 0) {
      if (!isCallfromPageLoad) {
        this.f.TripDestinationID.setValue("");
      }

      this.isOptionalCat = this.categoryList.filter(
        (c) => c.categoryId == categoryId
      )[0].isOptional;

      this.tripDestinationList = this.data.tripDestinationList.filter(
        (d) => d.tripCategoryID == categoryId
      );
    }
    if (categoryId == 2) {
      this.submitButtonText = "Save";
      this.displayHotelTab = false;
    } else {
      this.submitButtonText = "Save & Next";
      this.displayHotelTab = true;
    }
  }

  deleteTripOption() {
    var id = this.data.id;
    if (confirm("Are you sure to delete the current record?")) {
      this._tripOptionsService.deleteTripOption(id).subscribe((result) => {
        this.response = result;
        if (result.messageCode == "1") {
          this.dialogRef.close(result);
        } else {
          this.response = result;
        }
      });
    }
  }
  generateTripCode() {
    //var categoryId = this.f.CategoryId.value;
    var year = this.f.Years.value;
    var tripDestinationID = Number(this.f.TripDestinationID.value);
    var destinationData = this.tripDestinationList.filter(
      (x) => x.tripDestinationID === tripDestinationID
    );
    var destination = "";
    if (destinationData.length > 0) {
      destination = destinationData[0].tripDestinationName;
    }
    if (destination && year) {
      var auto_TripCode = year + " - " + destination;
      this.f.TripCode.setValue(auto_TripCode);
    } else {
      this.f.TripCode.setValue("");
    }
  }
}
