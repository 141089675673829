import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

let myAppUrl = '';
@Injectable({
  providedIn: 'root',
})
export class AllocatePointsService {
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }

  sampleFileDownload(model) {
    window.open(
      myAppUrl + 'api/AllocatePoints/SampleFileDownload?filename=' + model,
      '_blank'
    );
  }

  Import(data) {
    return this._http
      .post<any>(myAppUrl + 'api/AllocatePoints/Import', data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAllocatePointsList(isAllocated) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/AllocatePointsList?isAllocated=' +
          isAllocated
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getPointAllocation(year: number, status: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/GetPointAllocation?year=' +
          year +
          '&status=' +
          status +
          '&isExport=true'
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateAllocationStatus(data) {
    return this._http
      .post<any>(myAppUrl + 'api/AllocatePoints/UpdateAllocationStatus', data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCustomerWisePointsAllocation(enrollmentId: number, year: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/GetCustomerWisePointsAllocation?enrollmentId=' +
          enrollmentId +
          '&year=' +
          year
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateCustomerWiseAllocationStatus(data) {
    return this._http
      .post<any>(
        myAppUrl + 'api/AllocatePoints/UpdateCustomerWiseAllocationStatus',
        data
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  savePointsAllocation() {
    return this._http
      .get<any>(myAppUrl + 'api/AllocatePoints/SavePointsAllocation')
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  savePostTripPoints(data: any) {
    return this._http
      .post<any>(myAppUrl + 'api/AllocatePoints/SavePostTripPoints', data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  saveCatalogPoints(data: any) {
    return this._http
      .post<any>(myAppUrl + 'api/AllocatePoints/SaveCatalogPoints', data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  saveRollOverPoints(data: any) {
    return this._http
      .post<any>(myAppUrl + 'api/AllocatePoints/SaveRollOverPoints', data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getPointsOptionBreakUp(tripId: number, customerId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/GetPointsOptionBreakUp?tripId=' +
          tripId +
          '&customerId=' +
          customerId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCatalogPoint(years: number) {
    return this._http
      .get<any>(myAppUrl + 'api/AllocatePoints/GetCatalogPoint?years=' + years)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  //Catalog points listing of customer
  getCatalogPointByCustomer(years: number, customerId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/GetCatalogPointByCustomer/' +
          years +
          '/' +
          customerId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  deleteCatalogPoint(id: number) {
    return this._http
      .get<any>(myAppUrl + 'api/AllocatePoints/DeleteCatalogPoint?id=' + id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  importCatalogPoints(data) {
    return this._http
      .post<any>(myAppUrl + 'api/AllocatePoints/ImportCatalogPoints', data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  saveCatalogBuyPoints(data: any) {
    return this._http
      .post<any>(myAppUrl + 'api/AllocatePoints/SaveCatalogBuyPoints', data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteCatalogBuyPoints(id: number) {
    return this._http
      .post<any>(
        myAppUrl + 'api/AllocatePoints/DeleteCatalogBuyPoints/' + id,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteRollOverPoint(id: number, deletedBy: number) {
    return this._http
      .post<any>(
        myAppUrl +
          'api/AllocatePoints/DeleteRollOverPoint/' +
          id +
          '/' +
          deletedBy,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  //Added By Chandan
  //Catalog-purchased-points listing of customer
  getCatalogBuyPointByCustomer(years: number, customerId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/GetCatalogBuyPointByCustomer/' +
          years +
          '/' +
          customerId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getPostTripPointByCustomer(years: number, customerId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/GetPostTripPointByCustomer/' +
          years +
          '/' +
          customerId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getReAllocateEnabled(customerId: number) {
    return this._http
      .get<any>(
        myAppUrl + 'api/AllocatePoints/GetReAllocateEnabled/' + customerId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  reAllocateCustomerPoints(years: number, customerId: number) {
    return this._http
      .post<any>(
        myAppUrl +
          'api/AllocatePoints/ReAllocateCustomerPoints/' +
          years +
          '/' +
          customerId,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTripWhsDefaultDropdowns(years: number, ppeWhsCode) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/GetTripWhsDefaultDropdowns/' +
          years +
          '/' +
          ppeWhsCode
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getARStatusByCustomerIdAndYear(years: number, customerId: number) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/AllocatePoints/GetARStatusByCustomerIdAndYear/' +
          years +
          '/' +
          customerId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getPendingArReleasedCount() {
    return this._http
      .get<any>(myAppUrl + 'api/TripEnrollment/PendingArReleasedCount/')
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getotherCatalog() {
    return this._http
      .get<any>(myAppUrl + 'api/AllocatePoints/GetOtherCatalogs')
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getReportCatalogPoint(years) {
    return this._http
      .get<any>(myAppUrl + 'api/AllocatePoints/GetReportCatalogPoints?years='
        + years
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
