import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

let myAppUrl = '';
@Injectable({
	providedIn: 'root'
})
export class SalesmanService {
	constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		myAppUrl = baseUrl;
	}
	saveSalesman(model) {
		return this._http.post<any>(myAppUrl + 'api/Salesman/AddUpdateSalesman', model).pipe(
			map((data) => {
				return data;
			})
		);
	}
	getSalesmanDetail(id: number) {
		return this._http.get<any>(myAppUrl + 'api/Salesman/GetSalesmanDetail?id=' + id).pipe(
			map((data) => {
				return data;
			})
		);
	}
	getSalesmanList() {
		return this._http.get<any>(myAppUrl + 'api/Salesman/SalesmanList').pipe(
			map((data) => {
				return data;
			})
		);
	}
	getSalesmanListActive() {
		return this._http.get<any>(myAppUrl + 'api/Salesman/SalesmanListActive').pipe(
			map((data) => {
				return data;
			})
		);
	}
	deleteSalesman(id: number) {
		return this._http.post<any>(myAppUrl + 'api/Salesman/SalesmanDelete?id=' + id, null).pipe(
			map((data) => {
				return data;
			})
		);
	}
	Import(fileName) {
		return this._http.post<any>(myAppUrl + 'api/Salesman/Import?fileName=' + fileName, null).pipe(
			map((data) => {
				return data;
			})
		);
	}
	importSave(model) {
		return this._http.post<any>(myAppUrl + 'api/Salesman/ImportSave', model).pipe(
			map((data) => {
				return data;
			})
		);
	}
	// sampleFileDownload(model) {
	// 	return this._http.get<any>(myAppUrl + 'api/Salesman/SampleFileDownload?filename=' + model).pipe(
	// 		map((data) => {
	// 			return data;
	// 		})
	// 	);
	// }
	sampleFileDownload(model) {
		window.open(myAppUrl + 'api/Salesman/SampleFileDownload?filename=' + model, '_blank');
	}
}
