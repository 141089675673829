import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(private router: Router) {}
  /*User Role: 4 - Report Only */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(state.url);
    var redirectToNext = this.checkPageBasedOnUserRole(state.url);

    // Check User Logged In & They have permission on the click url.
    if (localStorage.getItem('isLoggedIn') && redirectToNext) {
      return true;
    }
    // Check User Logged In & they have not permission on the click url.
    else if (localStorage.getItem('isLoggedIn') && !redirectToNext) {
      this.router.navigate(['/']);
      return false;
    }
    // Not Logged In
    else {
      this.router.navigate(['login']);
      return false;
    }
  }

  // Validate Page based on User Role
  checkPageBasedOnUserRole(currentUrl: string): boolean {
    let url = currentUrl.split('/')[1];
    let role = localStorage.getItem('role');
    if (role === '4') {
      switch (url) {
        case 'company-import':
          return false;
        case 'trip-enrollment':
          return false;
        case 'trip-enrollment-old':
          return false;
        case 'trip-enrollment-detail':
          return false;
        case 'trip-enrollment-test-detail':
          return false;
        case 'companies':
          return false;
        case 'room-types':
          return false;
        case 'back-office-user':
          return false;
        case 'airport-list':
          return false;
        case 'airline':
          return false;
        case 'whs-list':
          return false;
        case 'salesman-import':
          return false;
        case 'salesman-list':
          return false;
        case 'trip-options':
          return false;
        case 'point-options':
          return false;
        case 'trip-whs-details':
          return false;
        case 'points-and-rewards':
          return false;
        case 'points-and-rewards-import':
          return false;
        case 'imported-points-and-rewards':
          return false;
        case 'allocate-points':
          return false;
        case 'allocated-points':
          return false;
        case 'import-ar-release':
          return false;
        case 'issue-certificates':
          return false;
        case 'export-certificates':
          return false;
        case 'customer-ar-release':
          return false;
        case 'exported-certificates':
          return false;
        case 'catalog-points':
          return false;
        case 'import-catalog-points':
          return false;
        case 'customer-points':
          return false;

        default:
          return true;
      }
    } else {
      return true;
    }
  }
}
