import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

let myAppUrl = "";
@Injectable({
  providedIn: 'root'
})
export class HotelDesignationService {
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }

  saveHotelDesignation(model) {
    return this._http.post<any>(myAppUrl + 'api/HotelDesignation/AddUpdateHotelDesignation', model)
      .pipe(map(data => { return data; }))
  }

  getHotelDesignationDetail(id:number){
    return this._http.get<any>(myAppUrl + 'api/HotelDesignation/GetHotelDesignationDetails?id=' + id)
    .pipe(map(
      data => {
        return data;
      }
    ))
  }
  getHotelDesignationList(){
    return this._http.get<any>(myAppUrl + 'api/HotelDesignation/GetHotelDesignationList')
    .pipe(map(
         data => {
          return data;
         }))
  }
  getHotelDesignationListActive() {
    return this._http.get<any>(myAppUrl + 'api/HotelDesignation/GetHotelDesignationListActive')
      .pipe(map(data => { return data; }))
  }
  deleteHotelDesignation(id: number) {
    return this._http.post<any>(myAppUrl + 'api/HotelDesignation/HotelDesignationDelete?id=' + id, null)
      .pipe(map(data => { return data; }))
  }
}
