import { Component, OnInit, Inject } from "@angular/core";
import { ServerDataSource } from "ng2-smart-table";
import { EnrollmentService } from "src/app/services/enrollment.service";
import { Router } from "@angular/router";
import { UtilityService } from "src/app/services/utility.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-customer-sxe-report",
  templateUrl: "./customer-sxe-report.component.html",
  styleUrls: ["./customer-sxe-report.component.css"],
})
export class CustomerSxeReportComponent implements OnInit {
  gridColumn = [];
  model: any = {};
  settings: any;
  mySettings: any;
  data: any = {};
  response: any = {};
  yearList: any = [];
  from: number = 1;
  to: number = 25;
  totalRecord: number;
  years = new Date().getFullYear();
  CurrentYears = new Date().getFullYear();
  DisplayYTDCustomerStatementReport = false;
  constructor(
    private enrollmentService: EnrollmentService,
    private _router: Router,
    private _utilityService: UtilityService,
    private http: HttpClient,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.mySettings = {
      columns: {},

      actions: {
        edit: false,
        add: false,
        delete: false,
        position: "right",
      },
      noDataMessage: "No record found.",
      pager: {
        display: true,
        perPage: 25,
      },
      attr: {
        class: "table table color-table dark-table table-striped",
      },
    };
    this.settings = Object.assign({}, this.mySettings);
    this.customerHeader(this.years);
    //this.customerStatementList(this.years);
  }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      2021,
      new Date().getFullYear() + 1
    );
    this.model.Year = this.years;
    this.DisplayYTDCustomerStatementReport = this.CurrentYears === this.years;
    this.data.onChanged().subscribe((change) => {
      this.from = (change.paging.page - 1) * change.paging.perPage + 1;
      this.to =
        this.data.lastRequestCount <= change.paging.page * change.paging.perPage
          ? this.data.lastRequestCount
          : change.paging.page * change.paging.perPage;
    });
  }
  customersxeStatementList(year) {
    this.data = new ServerDataSource(this.http, {
      endPoint:
        this.baseUrl +
        "api/TripEnrollment/CustomerSxeStatementListPagingNew?year=" +
        year,
      dataKey: "data.data",
      totalKey: "totalCount",
      pagerLimitKey: "recordCount",
      pagerPageKey: "pageNumber",
      filterFieldKey: "#field#",
      sortFieldKey: "sortColumn",
      sortDirKey: "sortOrder",
    });
  }
  bindCustomerList() {
    this.customerHeader(this.model.Year);
  }

  customerHeader(year) {
    this.enrollmentService.getCustomerSxeHeader(year).subscribe((result) => {
      //console.log(result);
      this.gridColumn = result;
      //console.log(this.gridColumn);
      this.mySettings.columns = {};
      for (let i = 0; i < this.gridColumn.length; i++) {
        this.mySettings.columns[this.gridColumn[i].columnName] = {
          title: this.gridColumn[i].title,
          sort: false,
          filter: this.gridColumn[i].filter,
        };
      }
      this.mySettings.pager = {
        display: true,
        perPage: 25,
      };
      this.mySettings.attr = {
        class: "table table color-table dark-table table-striped",
      };
      this.mySettings.attr = {
        class: "table table color-table dark-table table-striped",
      };

      this.mySettings.actions = {
        edit: false,
        add: false,
        delete: false,
        position: "right",
      };
      this.settings = Object.assign({}, this.mySettings);
      this.data = [];
      this.customersxeStatementList(this.model.Year);
    });
  }

  exportCustomer() {
    let filterData = this.data.getFilter();
    let extraSearch = "";
    if (filterData.filters.length > 0) {
      filterData.filters.forEach((element) => {
        extraSearch += "&" + element.field + "=" + element.search;
      });
    }
    window.open(
      this.baseUrl +
        "api/TripEnrollment/CustomerSxeStatementListExport?year=" +
        this.model.Year +
        extraSearch
    );
  }
}
