import { FormControl, Validators } from '@angular/forms';

// setup simple regex for white listed characters
//const validCharacters = /[^\s\w,.:&\/()+%'`@-]/;

// create your class that extends the angular validator class
export class CustomValidators extends Validators {
	static checkEmpty(control: FormControl) {
		if (control.value && control.value.length > 0) {
			const matches = control.value.replace(/\s/g, '');
			return matches && matches.length > 0 ? null : { required: true };
		} else {
			return null;
		}
	}
}
