import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { EnrollmentService } from '../../services/enrollment.service';
import { UtilityService } from '../../services/utility.service';
import { FormControl } from '@angular/forms';
import { ServerDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { ViewlinkComponent } from '../viewlink/viewlink.component';

@Component({
  selector: 'app-new-trip-enrollment-list',
  templateUrl: './new-trip-enrollment-list.component.html',
  styleUrls: ['./new-trip-enrollment-list.component.css']
})
export class NewTripEnrollmentListComponent implements OnInit {
  yearList: any = [];
	selected = new FormControl(1);
	years = new Date().getFullYear();
	data: any={}
	response: any = {};
	model = {};
  sub: any;
  settings: any;
  from :number=1; to:number=25; totalRecord:number;
  defaultSelectedTripYear = JSON.parse(localStorage.getItem('user')).defaultSelectedTripYear;
  constructor(private enrollmentService: EnrollmentService,
		private _router: Router,
		private matDialog: MatDialog,
		private _utilityService: UtilityService,private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string) {

      this.settings = {
        columns: {
          customerNumber: {
            title: 'Company No.',
            sort: false,
            type: 'custom',
            renderComponent: ViewlinkComponent
          },
          companyName: {
            title: 'Company Name',

            sort: false
          },
          dapContact: {
            title: 'DAP Contact',
            sort: false
          },
          dapPhone: {
            title: 'DAP Phone',
            sort: false
          },
          ppewhsCodeString: {
            title: 'WHS',
            sort: false
          },
          groupManager: {
            title: 'Group Mgr.',
            sort: false
          },
          tripEnrolled: {
            title: 'Trip Enrolled',
            sort: false,
            filter: false,
          },

        },
        noDataMessage: "No record found.",
        actions: {
          edit: false,
          add: false,
          delete: false,
          custom: [
            {
              name: 'Edit',
              title: `<span  class="rounded-circle px-2 btn-gray py-1 border mr-2 text-secondary"><i aria-hidden="true" class="mb-0 fa fa-pencil"></i> </span>`
            }
          ],
          position: 'right'
        },
        pager: {
          display: true,
          perPage: 25
        },
        attr: {
          class: 'table table color-table dark-table table-striped'
        }
      };

    }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(new Date().getFullYear() - 1, new Date().getFullYear() + 1);
    if(this.defaultSelectedTripYear != null)
    {
      let index = this.yearList.indexOf(this.defaultSelectedTripYear);
      if(index>=0)
      {
        this.years = this.defaultSelectedTripYear;
        this.selected.setValue(index);
      }
    }

		if (localStorage.getItem('deleteEnroll') != null && localStorage.getItem('deleteEnroll') == '1') {
			this.response.message = 'Record has been deleted successfully.';
			this.response.isShow = true;
			this.response.messageCode = 1;
			localStorage.removeItem('deleteEnroll');
    }
    this.enrollmentList(this.years);
    this.data.onChanged().subscribe((change) => {
			this.from=((change.paging.page-1)*change.paging.perPage)+1;
			this.to=this.data.lastRequestCount <=change.paging.page*change.paging.perPage?this.data.lastRequestCount:change.paging.page*change.paging.perPage;
		});
  }
  ngAfterViewInit() {

		if (localStorage.getItem('tripOptionUpdate') != null) {
			setTimeout(() => {
				//this.renderer.invokeElementMethod(this._el.nativeElement, 'focus');
				//document.getElementById("txSearch").focus();
			}, 100);
			this.response = JSON.parse(localStorage.getItem('tripOptionUpdate'));
			localStorage.removeItem('tripOptionUpdate');
		}
	}



	changeTab(index: number) {
		this.years = this.yearList[index];
		this.enrollmentList(this.years);
		//this.selected.setValue(Event);
  }
  onCustom(event: any) {
		if (event.action == 'Edit') {
      this._router.navigate([ '/trip-enrollment-detail/'+ event.data.id]);

      //this._router.navigate(['/trip-enrollment-detail'], { queryParams: {recordId: this.recordid , form: entityType , email: response.email, price: parseFloat(this.model.xTotalAmount).toFixed(2),transID:response.transactionId }});
		}
	}
  enrollmentList(year)
  {
    this.data = new ServerDataSource(this.http, {
			endPoint: this.baseUrl + 'api/TripEnrollment/EnrollmentListPaging?year='+year,
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});
  }
}
