import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { WhsService } from '../services/whs.service';
import { CustomValidators } from '../Directives/custom-validators';

@Component({
	selector: 'app-whs-add-edit-popup',
	templateUrl: './whs-add-edit-popup.component.html',
	styleUrls: [ './whs-add-edit-popup.component.css' ]
})
export class WhsAddEditPopupComponent implements OnInit {
	public whsForm: FormGroup;
	airportList: any = [];
	response: any = {};
	submitted = false;
	showDeleteButton=false;
	role=+localStorage.getItem('role');
	title="";
	submitButtonText="Save";
	model = {};
	constructor(
		private _whsService: WhsService,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<WhsAddEditPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		//this.airportList=this.data.airportList;
		this.generateForm();
	}

	get f() {
		return this.whsForm.controls;
	}

	generateForm() {
		//console.log(this.data);

		if (this.data.id > 0) { this.title = 'Edit WHS'; this.showDeleteButton=this.role<=2;this.submitButtonText="Update"}
		else { this.title = 'Add WHS'; this.showDeleteButton=false;this.data.isActive=true;}
		this.airportList = this.data.airportList;
		this.whsForm = this.fb.group({
			Id: [ this.data.id, Validators.required ],
			PPEWhsCode: [ this.data.ppeWhsCode, [ Validators.required, CustomValidators.checkEmpty ] ],
			WHSDescription: [ this.data.whsDescription, [ Validators.required, CustomValidators.checkEmpty ] ],
			BranchManager: [ this.data.branchManager, [ Validators.required, CustomValidators.checkEmpty ] ],
			BranchManageEmail: [ this.data.branchManageEmail, [Validators.required, Validators.email]],
			GroupManager: [ this.data.groupManager, [ Validators.required, CustomValidators.checkEmpty ] ],
			GroupManagerEmail: [ this.data.groupManagerEmail, [Validators.required, Validators.email]],
			AirportId: [ this.data.airportId, Validators.required ],
			IsActive:[this.data.isActive]
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
	saveWHS() {
		this.submitted = true;
		if (this.whsForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		let dataSave = this.whsForm.value;
		var id = dataSave.Id;
		if (!dataSave.Id) id = 0;

		this.model = {
			Id: id,
			AirportId: dataSave.AirportId,
			PPEWhsCode: dataSave.PPEWhsCode,
			WHSDescription: dataSave.WHSDescription,
			BranchManager: dataSave.BranchManager,
			BranchManageEmail: dataSave.BranchManageEmail,
			GroupManager: dataSave.GroupManager,
			GroupManagerEmail: dataSave.GroupManagerEmail,
			IsActive: dataSave.IsActive
		};

		//console.log(this.model);

		this._whsService.saveWHS(this.model).subscribe((result) => {
			if (result) {
				if (result.messageCode == '1') {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			}
		});

		
	}

	deleteWHS() {
		var id=this.data.id;
		if (confirm('Are you sure to delete the current record?')) {
			this._whsService.deleteWHS(id).subscribe((result) => {
				if (result.messageCode == '1') {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			});
		}
	}

	// private typed(value: string) {
	// 	//if (value.length >= 3) {
	// 		var emptlist = [ { id: 0, customerName: 'No data found' } ];
	// 		this.airportList = [];
	// 		this.customerService.getCustomerNameList(value, this.model.parentCompanyId).subscribe((result) => {
	// 			if (result && result.length > 0) this.customerList = result;
	// 			else this.airportList = emptlist;
	// 		});
	// 	//}
	// }

}
