import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
  MatDialogConfig,
} from '@angular/material';
import { TravelerService } from '../../services/traveler.service';
import { ItineraryService } from '../../services/itinerary.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormGroupDirective,
  FormArray,
} from '@angular/forms';
import { CustomValidators } from '../../Directives/custom-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { GroupOptiosComponent } from '../../Components/group-optios/group-optios.component';
import { TravelerComponent } from '../../Components/traveler/traveler.component';
import { EnrollmentService } from '../../services/enrollment.service';
import { CopyItineraryComponent } from '../copy-itinerary/copy-itinerary.component';
import { AirportLayoverHotelService } from 'src/app/services/airport-layover-hotel.service';
@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.css'],
})
export class ItineraryComponent implements OnInit {
  enrollmentId: number = 0;
  selected = new FormControl(0);
  selectedHotel = new FormControl(0);
  tripId: number;
  tripOptionId: number = JSON.parse(localStorage.getItem('user')).tripId;
  customerId: number;
  response: any = {};
  public itineraryForm: FormGroup;
  model = {};
  optionsData: any = {};
  sub: any;
  submitted = false;
  showDeleteButton = false;
  role = +localStorage.getItem('role');
  title = '';
  submitButtonText = 'Save';
  itineraryList = [];
  travelerList = [];
  airportList = [];
  airlineList = [];
  timeZoneList = [];
  data: any = {};
  endDOBDate = new Date();
  dateMask = MaskedDate;
  DefaultNameType = 0;
  defaultNameGen;
  isYesNos = [];
  bedTypes = [];
  drinkChoices = [];
  mailingAddresses = [];
  genders = [];
  groupList = [];
  isYesNosBool = [];
  bedTypeList = [];
  public mask = [
    '(',
    /[0-9]/,
    /\d/,
    /\d/,
    ')',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public maskTime = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
  timeValidateFormat = '^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100)); // new Date(1900, 0, 1);
  maxDateDOB = new Date();
  isSelect: boolean = false;
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100));
  pointsOptionList1 = [];
  pointsOptionList2 = [];
  pointsOptionList3 = [];
  pointsOptionList4 = [];
  pointsOptionList5 = [];
  pointsOptionList6 = [];
  isGroupAdded: boolean = false;
  airportLayoverHotelList: any = [];

  displayLinkButton = false;
  LastYear = new Date().getFullYear() - 1;
  displaySaveButton =true;
  years = new Date().getFullYear();
  constructor(
    private _travelerService: TravelerService,
    private _itineraryService: ItineraryService,
    private _utilityService: UtilityService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<ItineraryComponent>,
    private enrollmentService: EnrollmentService,
    private airportLayoverHotelService: AirportLayoverHotelService,
    @Inject(MAT_DIALOG_DATA) public trips
  ) {
    let index = this.trips
      .map(function (e) {
        return e._TripOptionsId;
      })
      .indexOf(this.tripOptionId);
    if (index >= 0) this.selected.setValue(index);
    else {
      this.tripOptionId = this.trips[0]._TripOptionsId;
    }
  }

  public displayedColumns = [
    'traveler',
    'dateofBirth',
    'recordLocator',
    'groups',
    'action',
  ];
  public displayedColumnsT = ['traveler', 'dateofBirth', 'groups', 'action'];
  public dataSource = new MatTableDataSource<TravellerEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  public dataSourceT = new MatTableDataSource<TravellerEntity>();

  ngOnInit() {
    this.pageLoad();

    if(!this.trips.displayLinkButton && this.LastYear==this.trips.years)
    {
      this.displaySaveButton=false;
    }

  }

  onCloseClick(): void {
    this.dialogRef.close({});
    if (+localStorage.getItem('isPoints'))
      this.router.navigate(['/trip-enrollment-detail/' + this.enrollmentId]);
    else this.router.navigate(['/trip-enrollment']);
  }

  get f() {
    return this.itineraryForm.controls;
  }
  nexttoHotel() {
    this.submitted = true;
    if (
      (this.a_flights.invalid && this.data.isDepartureFlight) ||
      (this.a_returnFlights.invalid && this.data.isReturnFlight)
    ) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
    this.selectedHotel.setValue(1);
    this.submitted = false;
    this.getAirportCodeListForLayoverHotel();
  }
  changeHotelTab(index: number) {
    this.selectedHotel.setValue(index);

    if (index == 1) {
      // Bind Layover Hotel
      this.getAirportCodeListForLayoverHotel();
    }
  }

  // Get Airport Code List for Layover Hotel
  airportIdForLayoverHotel: any = [];
  getAirportCodeListForLayoverHotel() {
    this.airportIdForLayoverHotel = [];

    // Get Index on filter Array

    var departureFlightIndex = 0;
    this.a_flights.controls.filter((obj: any) => {
      if (obj.controls.OvernightHotelNeeded.value) {
        var airportData = {
          AirportId: +obj.controls.ArrivalAirportCodeId.value,
          JourneyType: 1,
          LayoverCheckInDate: obj.controls.ArrivalDate.value,
          LayoverCheckOutDate: '',
        };

        var flightControl = this.a_flights.controls[
          departureFlightIndex + 1
        ] as any;
        if (flightControl) {
          airportData.LayoverCheckOutDate =
            flightControl.controls.DepartureDate.value;
        }

        this.airportIdForLayoverHotel.push(airportData);
      }
      departureFlightIndex++;
    });

    var returnFlightIndex = 0;
    this.a_returnFlights.controls.filter((obj: any) => {
      if (obj.controls.OvernightHotelNeeded.value) {
        var airportData = {
          AirportId: +obj.controls.ArrivalAirportCodeId.value,
          JourneyType: 2,
          LayoverCheckInDate: obj.controls.ArrivalDate.value,
          LayoverCheckOutDate: '',
        };

        var flightControl = this.a_returnFlights.controls[
          returnFlightIndex + 1
        ] as any;
        if (flightControl) {
          airportData.LayoverCheckOutDate =
            flightControl.controls.ReturnDate.value;
        }
        this.airportIdForLayoverHotel.push(airportData);
      }
      returnFlightIndex++;
    });
    //console.log(this.airportIdForLayoverHotel);

    if (this.airportIdForLayoverHotel.length > 0) {
      this.getAirportLayoverHotelList(this.airportIdForLayoverHotel);
    } else {
      this.airportLayoverHotelList = [];
    }
  }

  getAirportLayoverHotelList(airportIds: any) {
    var airportIdList = {
      AirportIds: airportIds,
    };
    this.airportLayoverHotelService
      .getAirportLayoverHotelForDropdownList(airportIdList)
      .subscribe((result) => {
        this.airportLayoverHotelList = result;
        // debugger;
        // Bind Layover Hotel
        if (
          this.data.layoverHotels.length > 0 &&
          this.a_layoverHotels.controls.length == this.data.layoverHotels.length
        ) {
          var rowIndex = 0;
          this.data.layoverHotels.forEach((element) => {
            var filterHotelList = this.airportLayoverHotelList.filter(
              (x) => x.journeyType == +element.layoverJourneyType
            );

            if (rowIndex < this.a_layoverHotels.controls.length) {
              const control = this.a_layoverHotels.controls[rowIndex].get(
                'AirportLayoverHotelList'
              ) as FormArray;
              control.clear();

              filterHotelList.forEach((element) => {
                control.push(
                  this.fb.group({
                    id: element.id,
                    layoverHotelName: element.layoverHotelName,
                  })
                );
              });
            }
            rowIndex++;
          });
        } else {
          var jIndex = 0;
          this.a_layoverHotels.controls.forEach((element: any) => {
            // debugger;
            if (element.controls.LayoverJourneyType.value) {
              this.changeLayoverJourneyType(
                element.controls.LayoverJourneyType.value,
                jIndex,
                element
              );
            }
            // debugger;
            if (
              element.controls.LayoverJourneyType.value &&
              element.controls.AirportLayoverHotelId.value
            ) {
              this.changeAirportLayoverHotel(
                element.controls.AirportLayoverHotelId.value,
                element
              );
            }

            jIndex++;
          });
        }
      });
  }

  changeLayoverJourneyType(eventValue: any, index: number, layoverHotels: any) {
    if (eventValue == '') {
      layoverHotels.controls.LayoverCity.setValue('');
      layoverHotels.controls.AirportLayoverHotelId.setValue(0);
      return;
    }

    const control = this.a_layoverHotels.controls[index].get(
      'AirportLayoverHotelList'
    ) as FormArray;

    // Clear the control value
    control.clear();

    var filterHotelList = this.airportLayoverHotelList.filter(
      (x) => x.journeyType == +eventValue
    );

    filterHotelList.forEach((element) => {
      control.push(
        this.fb.group({
          id: element.id,
          layoverHotelName: element.layoverHotelName,
        })
      );
    });

    if (filterHotelList.length == 1) {
      layoverHotels.controls.LayoverCity.setValue(
        filterHotelList[0].airportCode
      );
      layoverHotels.controls.AirportLayoverHotelId.setValue(
        filterHotelList[0].id
      );

      var checkInCheckOutDate = this.airportIdForLayoverHotel.filter(
        (x) =>
          x.AirportId == filterHotelList[0].airportId &&
          x.JourneyType == filterHotelList[0].journeyType
      );
      // Set Hotel CheckIn & Checkout Date
      if (checkInCheckOutDate.length > 0) {
        layoverHotels.controls.LHCheckInDate.setValue(
          checkInCheckOutDate[0].LayoverCheckInDate
        );
        layoverHotels.controls.LHCheckOutDate.setValue(
          checkInCheckOutDate[0].LayoverCheckOutDate
        );
      }
    } else {
      layoverHotels.controls.LayoverCity.setValue('');
      layoverHotels.controls.LHCheckInDate.setValue('');
      layoverHotels.controls.LHCheckOutDate.setValue('');
      if (
        layoverHotels.controls.AirportLayoverHotelId.value == 0 ||
        !layoverHotels.controls.AirportLayoverHotelId.value
      ) {
        layoverHotels.controls.AirportLayoverHotelId.setValue(0);
      } else {
        this.changeAirportLayoverHotel(
          layoverHotels.controls.AirportLayoverHotelId.value,
          layoverHotels
        );
      }

      if (!layoverHotels.controls.LHRoomType.value) {
        layoverHotels.controls.LHRoomType.setValue(this.data.roomType);
      }
    }

    if (!layoverHotels.controls.LHRoomType.value) {
      layoverHotels.controls.LHRoomType.setValue(this.data.roomType);
    }
  }

  changeAirportLayoverHotel(eventValue: any, layoverHotels: any) {
    // debugger;
    var filterHotelList = this.airportLayoverHotelList.filter(
      (x) => x.id == +eventValue
    );
    if (filterHotelList.length > 0) {
      layoverHotels.controls.LayoverCity.setValue(
        filterHotelList[0].airportCode
      );
      // debugger;

      var checkInCheckOutDate = this.airportIdForLayoverHotel.filter(
        (x) =>
          x.AirportId == filterHotelList[0].airportId &&
          x.JourneyType == +layoverHotels.controls.LayoverJourneyType.value
      );

      // console.log(checkInCheckOutDate);

      // Set Hotel CheckIn & Checkout Date
      if (checkInCheckOutDate.length > 0) {
        layoverHotels.controls.LHCheckInDate.setValue(
          checkInCheckOutDate[0].LayoverCheckInDate
        );
        layoverHotels.controls.LHCheckOutDate.setValue(
          checkInCheckOutDate[0].LayoverCheckOutDate
        );
      } else {
        layoverHotels.controls.LayoverCity.setValue('');
        layoverHotels.controls.LHCheckInDate.setValue('');
        layoverHotels.controls.LHCheckOutDate.setValue('');
      }
    } else {
      layoverHotels.controls.LayoverCity.setValue('');
      layoverHotels.controls.LHCheckInDate.setValue('');
      layoverHotels.controls.LHCheckOutDate.setValue('');
    }

    if (!layoverHotels.controls.LHRoomType.value) {
      layoverHotels.controls.LHRoomType.setValue(this.data.roomType);
    }
  }

  pageLoad() {
    this.timeZoneList = this._utilityService.getTimeZone();
    let tripdetail = this.trips[this.selected.value];
    if (tripdetail) {
      this.enrollmentId = tripdetail._EnrollmentId;
      this.tripId = tripdetail._Id;
      this.customerId = tripdetail._CustomerId;
      this.getTravelerDetailList(this.tripId);
      this.generateForm(false);
    }
  }

  generateForm(isSave: boolean, isEdit: boolean = false) {
    if (!isSave) this.response = {};
    let option1 = '';
    let option2 = '';
    let option3 = '';
    let option4 = '';
    let option5 = '';
    let option6 = '';

    if (
      this.data.groupPointOptionsList &&
      this.data.groupPointOptionsList.length > 0
    ) {
      if (this.data.groupPointOptionsList.length > 0) {
        option1 = this.data.groupPointOptionsList[0]._OptionId;
        this.pointsOptionList1 = this.pointsOptionList1.filter(
          (b) => b._IsActive || b._OptionId === option1
        );
      }
      if (this.data.groupPointOptionsList.length > 1) {
        option2 = this.data.groupPointOptionsList[1]._OptionId;
        this.pointsOptionList2 = this.pointsOptionList2.filter(
          (b) => b._IsActive || b._OptionId === option2
        );
      }
      if (this.data.groupPointOptionsList.length > 2) {
        option3 = this.data.groupPointOptionsList[2]._OptionId;
        this.pointsOptionList3 = this.pointsOptionList3.filter(
          (b) => b._IsActive || b._OptionId === option3
        );
      }
      if (this.data.groupPointOptionsList.length > 3) {
        option4 = this.data.groupPointOptionsList[3]._OptionId;
        this.pointsOptionList4 = this.pointsOptionList4.filter(
          (b) => b._IsActive || b._OptionId === option4
        );
      }
      if (this.data.groupPointOptionsList.length > 4) {
        option5 = this.data.groupPointOptionsList[4]._OptionId;
        this.pointsOptionList5 = this.pointsOptionList5.filter(
          (b) => b._IsActive || b._OptionId === option5
        );
      }
      if (this.data.groupPointOptionsList.length > 5) {
        option6 = this.data.groupPointOptionsList[5]._OptionId;
        this.pointsOptionList6 = this.pointsOptionList6.filter(
          (b) => b._IsActive || b._OptionId === option6
        );
      }
    }
    if (this.data.dhCheckInDate)
      this.data.dhCheckInDate = new Date(this.data.dhCheckInDate);
    else this.data.dhCheckInDate = '';
    if (this.data.dhCheckOutDate)
      this.data.dhCheckOutDate = new Date(this.data.dhCheckOutDate);
    else this.data.dhCheckOutDate = '';

    if (this.data.outgoingFlightDate)
      this.data.outgoingFlightDate = new Date(this.data.outgoingFlightDate);
    else this.data.outgoingFlightDate = '';
    if (this.data.returnFlightDate)
      this.data.returnFlightDate = new Date(this.data.returnFlightDate);
    else this.data.returnFlightDate = '';

    this.itineraryForm = this.fb.group({
      DHCheckInDate: [
        this.data.dhCheckInDate,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      DHCheckOutDate: [
        this.data.dhCheckOutDate,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      DHRoomType: [
        this.data.dhRoomType ? this.data.dhRoomType : '',
        [Validators.required, CustomValidators.checkEmpty],
      ],
      DHCost: [
        this.data.dhCost ? this.data.dhCost : '',
        [Validators.required, CustomValidators.checkEmpty],
      ],

      MiscGroupRequest: [
        this.data.miscGroupRequest,
        [Validators.maxLength(250)],
      ],
      MiscGroupPoint: [
        this.data.miscGroupPoint == '0' ? '' : this.data.miscGroupPoint,
      ],
      Notes: [this.data.notes, [Validators.maxLength(250)]],
      RentACar: [this.data.rentACar],
      WheelChairRequest: [this.data.wheelChairRequest],
      FirstClass: [this.data.firstClass],
      GroupCompanyOwner: [this.data.groupCompanyOwner],
      DrinkChoice: [this.data.drinkChoice, [Validators.required]],
      Groups: [
        this.data.groups ? this.data.groups : '',
        [Validators.required, CustomValidators.checkEmpty],
      ], //
      Filter: [''],
      Id: [this.data.id],
      TravelerId: [this.data.travelerId],
      TripId: [this.data.tripOptionsId],
      CustomerId: [this.data.customerId],
      flights: this.fb.array([]),
      returnFlights: this.fb.array([]),
      layoverHotels: this.fb.array([]),
      _Option1: [option1],
      _Option2: [option2],
      _Option3: [option3],
      _Option4: [option4],
      _Option5: [option5],
      _Option6: [option6],
    });
  }
  get a_flights() {
    return this.f.flights as FormArray;
  }
  get a_returnFlights() {
    return this.f.returnFlights as FormArray;
  }

  get a_layoverHotels() {
    return this.f.layoverHotels as FormArray;
  }

  createFlights(totalflight: number = 0): FormGroup {
    let airportCode =
      totalflight === 0
        ? this.data.preferredAirportCode
          ? this.data.preferredAirportCode.toString()
          : ''
        : '';
    let airline =
      totalflight === 0
        ? this.data.preferredAirline
          ? this.data.preferredAirline
          : ''
        : '';
    let outgoingFlightDate =
      totalflight === 0 ? this.data.outgoingFlightDate : '';

    let arrivalAirportCode =
      totalflight === 0
        ? this.data.returnAirportCode
          ? this.data.returnAirportCode.toString()
          : ''
        : '';

    if (airportCode === '' && this.a_flights.controls.length > 0) {
      var data = this.a_flights.controls[
        this.a_flights.controls.length - 1
      ] as any;
      airportCode = data.controls.ArrivalAirportCodeId.value;
    }

    const flight = this.fb.group({
      Id: [0],
      RecordLocator: [''],
      TicketNumber: [''],
      TicketCost: [''],
      AirportCodeId: [airportCode],
      Airline: [airline],
      FlightNumber: [''],
      DepartureDate: [outgoingFlightDate],
      DepartureTime: ['', [Validators.pattern(this.timeValidateFormat)]],
      DepartureTimeZone: [''],
      ArrivalAirportCodeId: [arrivalAirportCode],
      ArrivalDate: [outgoingFlightDate],
      ArrivalTime: ['', [Validators.pattern(this.timeValidateFormat)]],
      ArrivalTimeZone: [''],
      OvernightHotelNeeded: [false],
      CustomerPurchasedTheirOwn: [false],
    });
    if (this.data.isDepartureFlight) {
      // flight.controls["TicketCost"].setValidators(Validators.required);
      flight.controls['AirportCodeId'].setValidators(Validators.required);
      flight.controls['Airline'].setValidators(Validators.required);
      flight.controls['FlightNumber'].setValidators(Validators.required);
      flight.controls['DepartureDate'].setValidators(Validators.required);
      flight.controls['DepartureTime'].setValidators(Validators.required);
      flight.controls['ArrivalAirportCodeId'].setValidators(
        Validators.required
      );
      flight.controls['ArrivalDate'].setValidators(Validators.required);
      flight.controls['ArrivalTime'].setValidators(Validators.required);
    }
    return flight;
  }
  editFlights(flight): FormGroup {
    //debugger;
    if (flight.departureDate)
      flight.departureDate = new Date(flight.departureDate);
    else flight.departureDate = '';

    if (flight.arrivalDate) flight.arrivalDate = new Date(flight.arrivalDate);
    else flight.arrivalDate = '';

    var arrivalAirportCodeId = flight.arrivalAirportCodeId
      ? flight.arrivalAirportCodeId
      : this.data.returnAirportCode
      ? this.data.returnAirportCode.toString()
      : '';

    const flt = this.fb.group({
      Id: [flight.id],
      RecordLocator: [flight.recordLocator],
      TicketNumber: [flight.ticketNumber],
      TicketCost: [flight.ticketCost <= 0 ? '' : flight.ticketCost.toFixed(2)],
      AirportCodeId: [flight.airportCodeId],
      Airline: [flight.airline > 0 ? flight.airline : ''],
      FlightNumber: [flight.flightNumber],
      DepartureDate: [flight.departureDate],
      DepartureTime: [
        flight.departureTime,
        [Validators.pattern(this.timeValidateFormat)],
      ],
      DepartureTimeZone: [flight.departureTimeZone],
      ArrivalAirportCodeId: [arrivalAirportCodeId],
      ArrivalDate: [flight.arrivalDate],
      ArrivalTime: [
        flight.arrivalTime,
        [Validators.pattern(this.timeValidateFormat)],
      ],
      ArrivalTimeZone: [flight.arrivalTimeZone],
      OvernightHotelNeeded: [flight.overnightHotelNeeded],
      CustomerPurchasedTheirOwn: [flight.customerPurchasedTheirOwn],
    });

    if (this.data.isDepartureFlight) {
      // flt.controls["TicketCost"].setValidators(Validators.required);
      flt.controls['AirportCodeId'].setValidators(Validators.required);
      flt.controls['Airline'].setValidators(Validators.required);
      flt.controls['FlightNumber'].setValidators(Validators.required);
      flt.controls['DepartureDate'].setValidators(Validators.required);
      flt.controls['DepartureTime'].setValidators(Validators.required);
      flt.controls['ArrivalAirportCodeId'].setValidators(Validators.required);
      flt.controls['ArrivalDate'].setValidators(Validators.required);
      flt.controls['ArrivalTime'].setValidators(Validators.required);
    }
    return flt;
  }

  createRetutnFlights(totalflight: number = 0) {
    let returnFlightDate = totalflight === 0 ? this.data.returnFlightDate : '';
    let returnAirportCode =
      totalflight === 0
        ? this.data.returnAirportCode
          ? this.data.returnAirportCode.toString()
          : ''
        : '';

    let airline =
      totalflight === 0
        ? this.data.preferredAirline
          ? this.data.preferredAirline
          : ''
        : '';

    if (returnAirportCode === '' && this.a_returnFlights.controls.length > 0) {
      var data = this.a_returnFlights.controls[
        this.a_returnFlights.controls.length - 1
      ] as any;
      returnAirportCode = data.controls.ArrivalAirportCodeId.value;
    }

    const reFlight = this.fb.group({
      Id: [0],
      RecordLocator: [''],
      TicketNumber: [''],
      TicketCost: [''],
      AirportCodeId: [returnAirportCode],
      Airline: [airline],
      FlightNumber: [''],
      ReturnDate: [returnFlightDate],
      ReturnTime: ['', [Validators.pattern(this.timeValidateFormat)]],
      ReturnTimeZone: [''],
      ArrivalAirportCodeId: [''],
      ArrivalDate: [returnFlightDate],
      ArrivalTime: ['', [Validators.pattern(this.timeValidateFormat)]],
      ArrivalTimeZone: [''],
      OvernightHotelNeeded: [false],
      CustomerPurchasedTheirOwn: [false],
    });

    if (this.data.isReturnFlight) {
      // reFlight.controls["TicketCost"].setValidators(Validators.required);
      reFlight.controls['AirportCodeId'].setValidators(Validators.required);
      reFlight.controls['Airline'].setValidators(Validators.required);
      reFlight.controls['FlightNumber'].setValidators(Validators.required);
      reFlight.controls['ReturnDate'].setValidators(Validators.required);
      reFlight.controls['ReturnTime'].setValidators(Validators.required);
      reFlight.controls['ArrivalAirportCodeId'].setValidators(
        Validators.required
      );
      reFlight.controls['ArrivalDate'].setValidators(Validators.required);
      reFlight.controls['ArrivalTime'].setValidators(Validators.required);
    }
    return reFlight;
  }

  editRetutnFlights(returnFlight) {
    if (returnFlight.returnDate)
      returnFlight.returnDate = new Date(returnFlight.returnDate);
    else returnFlight.returnDate = '';

    if (returnFlight.arrivalDate)
      returnFlight.arrivalDate = new Date(returnFlight.arrivalDate);
    else returnFlight.arrivalDate = '';

    var airportCodeId = returnFlight.airportCodeId
      ? returnFlight.airportCodeId
      : this.data.returnAirportCode
      ? this.data.returnAirportCode.toString()
      : '';
    var airline = returnFlight.airline
      ? returnFlight.airline
      : this.data.preferredAirline
      ? this.data.preferredAirline
      : '';

    const reFlight = this.fb.group({
      Id: [returnFlight.id],
      RecordLocator: [returnFlight.recordLocator],
      TicketNumber: [returnFlight.ticketNumber],
      TicketCost: [
        returnFlight.ticketCost <= 0 ? '' : returnFlight.ticketCost.toFixed(2),
      ],
      AirportCodeId: [airportCodeId],
      Airline: [airline],
      FlightNumber: [returnFlight.flightNumber],

      ReturnDate: [returnFlight.returnDate],
      ReturnTime: [
        returnFlight.returnTime,
        [Validators.pattern(this.timeValidateFormat)],
      ],
      ReturnTimeZone: [returnFlight.returnTimeZone],
      ArrivalAirportCodeId: [returnFlight.arrivalAirportCodeId],
      ArrivalDate: [returnFlight.arrivalDate],
      ArrivalTime: [
        returnFlight.arrivalTime,
        [Validators.pattern(this.timeValidateFormat)],
      ],
      ArrivalTimeZone: [returnFlight.arrivalTimeZone],
      OvernightHotelNeeded: [returnFlight.overnightHotelNeeded],
      CustomerPurchasedTheirOwn: [returnFlight.customerPurchasedTheirOwn],
    });

    if (this.data.isReturnFlight) {
      // reFlight.controls["TicketCost"].setValidators(Validators.required);
      reFlight.controls['AirportCodeId'].setValidators(Validators.required);
      reFlight.controls['Airline'].setValidators(Validators.required);
      reFlight.controls['FlightNumber'].setValidators(Validators.required);
      reFlight.controls['ReturnDate'].setValidators(Validators.required);
      reFlight.controls['ReturnTime'].setValidators(Validators.required);
      reFlight.controls['ArrivalAirportCodeId'].setValidators(
        Validators.required
      );
      reFlight.controls['ArrivalDate'].setValidators(Validators.required);
      reFlight.controls['ArrivalTime'].setValidators(Validators.required);
    }
    return reFlight;
  }
  createLayoverHotels() {
    return this.fb.group({
      Id: [0],
      HotelName: [''],
      HotelConfirmation: [''],
      LHCheckInDate: [''],
      LHCheckOutDate: [''],
      LHRoomType: [''],
      LHCost: [''],
      LayoverCity: ['', Validators.required],
      LayoverJourneyType: ['', Validators.required],
      AirportLayoverHotelId: [0],
      AirportLayoverHotelList: this.fb.array([]),
    });
  }

  editLayoverHotels(layoverHotel) {
    if (layoverHotel.lhCheckInDate)
      layoverHotel.lhCheckInDate = new Date(layoverHotel.lhCheckInDate);
    else layoverHotel.lhCheckInDate = '';
    if (layoverHotel.lhCheckOutDate)
      layoverHotel.lhCheckOutDate = new Date(layoverHotel.lhCheckOutDate);
    else layoverHotel.lhCheckOutDate = '';
    let layoverJourneyType =
      layoverHotel.layoverJourneyType > 0
        ? layoverHotel.layoverJourneyType
        : '';
    return this.fb.group({
      Id: [layoverHotel.id],
      HotelName: [layoverHotel.hotelName],
      HotelConfirmation: [layoverHotel.hotelConfirmation],
      LHCheckInDate: [layoverHotel.lhCheckInDate],
      LHCheckOutDate: [layoverHotel.lhCheckOutDate],
      LHRoomType: [layoverHotel.lhRoomType],
      LHCost: [layoverHotel.lhCost.toFixed(2)],
      LayoverCity: [layoverHotel.layoverCity, Validators.required],
      LayoverJourneyType: [layoverJourneyType, Validators.required],
      AirportLayoverHotelId: [layoverHotel.airportLayoverHotelId],
      AirportLayoverHotelList: this.fb.array([]),
    });
  }

  getNotesChar() {
    let notes = '';
    if (this.f.value) {
      notes = this.f.Notes.value;
    }
    return notes;
  }

  addMoreFlight(flight, isEdit) {
    if (flight && isEdit) {
      this.a_flights.push(this.editFlights(flight));
    } else if (!isEdit) {
      this.a_flights.push(this.createFlights(this.a_flights.length));
    }
  }

  addMoreReturnFlight(returnflight, isEdit) {
    if (returnflight && isEdit) {
      this.a_returnFlights.push(this.editRetutnFlights(returnflight));
    } else if (!isEdit) {
      this.a_returnFlights.push(
        this.createRetutnFlights(this.a_returnFlights.length)
      );
    }
  }
  addMoreLayoverHotel(hotel, isEdit) {
    // debugger;
    if (hotel && isEdit) {
      this.a_layoverHotels.push(this.editLayoverHotels(hotel));
    } else if (!isEdit) {
      this.a_layoverHotels.push(this.createLayoverHotels());

      // SET Layover Hotel Check In & Check Out Date on Add More Layover Hotel
      var layoverHotelLastIndex = this.a_layoverHotels.controls.length - 1;

      var airportLayoverHotelControl = this.a_layoverHotels.controls[
        layoverHotelLastIndex
      ] as any;

      airportLayoverHotelControl.controls.LHRoomType.setValue(
        this.data.roomType
      );
    }
  }

  RemoveFlight(i: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this.a_flights.removeAt(i);
    }
  }

  RemoveReturnFlight(i: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this.a_returnFlights.removeAt(i);
    }
  }

  RemoveLayoverHotel(i: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this.a_layoverHotels.removeAt(i);
    }
  }

  getList() {
    this._itineraryService
      .getRegTravelerList(this.tripId)
      .subscribe((result) => {
        this.travelerList = result;
        this.dataSourceT.data = this.travelerList as TravellerEntity[];

        this._itineraryService
          .getRegisteredItineraryList(this.tripId)
          .subscribe((itinerary) => {
            this.itineraryList = itinerary;
            this.dataSource.data = this.itineraryList as TravellerEntity[];
          });
      });
  }
  saveItinerary() {
    this.response = {};
    this.submitted = true;
    let dataSave = this.itineraryForm.value;

    if (
      (this.a_flights.invalid && this.data.isDepartureFlight) ||
      (this.a_returnFlights.invalid && this.data.isReturnFlight)
    ) {
      this.selectedHotel.setValue(0);
      return;
    } else if (
      this.data.isDepartureFlight &&
      this.a_flights.length == 0 &&
      this.data.isReturnFlight &&
      this.a_returnFlights.length == 0
    ) {
      this.selectedHotel.setValue(0);
      this.response = {
        isShow: true,
        messageCode: 0,
        message:
          'Departure, Return flight is required to save itinerary detail.',
      };
      return;
    } else if (this.data.isDepartureFlight && this.a_flights.length == 0) {
      this.selectedHotel.setValue(0);
      this.response = {
        isShow: true,
        messageCode: 0,
        message: 'Departure flight is required to save itinerary detail.',
      };
      return;
    } else if (this.data.isReturnFlight && this.a_returnFlights.length == 0) {
      this.selectedHotel.setValue(0);
      this.response = {
        isShow: true,
        messageCode: 0,
        message: 'Return flight is required to save itinerary detail.',
      };
      return;
    }

    if (this.itineraryForm.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
    if (dataSave.DHCost === '') {
      this.selectedHotel.setValue(1);
    } else {
      var id = dataSave.Id;
      if (!dataSave.Id) id = 0;

      var outgoingFlightDate = '';
      var returnFlightDate = '';
      dataSave.DHCost = +dataSave.DHCost;
      dataSave.MiscGroupPoint = +dataSave.MiscGroupPoint;

      if (dataSave.DHCheckInDate)
        dataSave.DHCheckInDate = new Date(
          dataSave.DHCheckInDate
        ).toDateString();
      if (dataSave.DHCheckOutDate)
        dataSave.DHCheckOutDate = new Date(
          dataSave.DHCheckOutDate
        ).toDateString();

      if (dataSave.OutgoingFlightDate)
        outgoingFlightDate = new Date(
          dataSave.OutgoingFlightDate
        ).toDateString();
      if (dataSave.ReturnFlightDate)
        returnFlightDate = new Date(dataSave.ReturnFlightDate).toDateString();
      if (dataSave && dataSave.flights) {
        dataSave.flights.forEach((obj) => {
          obj.TicketCost = +obj.TicketCost;
          obj.Airline = +obj.Airline;
          if (obj.DepartureDate)
            obj.DepartureDate = new Date(obj.DepartureDate).toDateString();
          if (obj.ArrivalDate)
            obj.ArrivalDate = new Date(obj.ArrivalDate).toDateString();
        });
      }
      if (dataSave && dataSave.returnFlights) {
        dataSave.returnFlights.forEach((obj) => {
          obj.TicketCost = +obj.TicketCost;
          obj.Airline = +obj.Airline;
          if (obj.ReturnDate)
            obj.ReturnDate = new Date(obj.ReturnDate).toDateString();
          if (obj.ArrivalDate)
            obj.ArrivalDate = new Date(obj.ArrivalDate).toDateString();
        });
      }

      if (dataSave && dataSave.layoverHotels) {
        dataSave.layoverHotels.forEach((obj) => {
          obj.LHRoomType = obj.LHRoomType;
          obj.LHCost = +obj.LHCost;
          obj.AirportLayoverHotelId = +obj.AirportLayoverHotelId;
          if (obj.LHCheckOutDate)
            obj.LHCheckOutDate = new Date(obj.LHCheckOutDate).toDateString();
          if (obj.LHCheckInDate)
            obj.LHCheckInDate = new Date(obj.LHCheckInDate).toDateString();
        });
      }
      let groupPointOptionsList = [];
      if (dataSave._Option1)
        groupPointOptionsList.push({ _OptionId: dataSave._Option1 });
      if (dataSave._Option2)
        groupPointOptionsList.push({ _OptionId: dataSave._Option2 });
      if (dataSave._Option3)
        groupPointOptionsList.push({ _OptionId: dataSave._Option3 });
      if (dataSave._Option4)
        groupPointOptionsList.push({ _OptionId: dataSave._Option4 });
      if (dataSave._Option5)
        groupPointOptionsList.push({ _OptionId: dataSave._Option5 });
      if (dataSave._Option6)
        groupPointOptionsList.push({ _OptionId: dataSave._Option6 });

      dataSave.GroupPointOptionsList = groupPointOptionsList;
      dataSave.LogUserId = this._utilityService.getCurrentUserId();
      //console.log(dataSave);

      this._itineraryService.saveItinerary(dataSave).subscribe((result) => {
        if (result) {
          if (result.messageCode == 1) {
            this.response = result;
            this.isSelect = false;
            this.getList();
            this.data = {};
            let tripdetail = this.trips[this.selected.value];
            if (tripdetail) {
              this.data.outgoingFlightDate = tripdetail._OutgoingFlightDate;
              this.data.returnFlightDate = tripdetail._ReturnFlightDate;
              this.data.preferredAirportCode = tripdetail._AirportId;
            }
            this.generateForm(true);
            this.submitted = false;
          } else {
            this.response = result;
          }
        }
      });
    }
  }
  deleteItinerary(id: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this._itineraryService
        .deleteItinerary(id, this._utilityService.getCurrentUserId())
        .subscribe((result) => {
          this.response = result;
          if (result.messageCode == 1) {
            this.isSelect = false;
            this.getList();
          } else {
            this.response = result;
          }
        });
    }
  }

  getTravelerDetailList(tripId) {
    this._itineraryService
      .getItineraryDetailList(tripId)
      .subscribe((result) => {
        let travelerDetailList = result;

        this.travelerList = travelerDetailList.travelerList;
        this.airportList = travelerDetailList.airportList;
        this.airlineList = travelerDetailList.airlineList;
        this.itineraryList = travelerDetailList.itineraryList;
        //console.log(this.itineraryList);

        this.dataSource.data = this.itineraryList as TravellerEntity[];
        this.dataSourceT.data = this.travelerList as TravellerEntity[];
      });
  }

  changeTab(index: number) {
    this.f.Filter.setValue('');
    this.response = {};
    this.submitted = false;
    let tripdetail = this.trips[index];
    this.tripId = tripdetail._Id;
    this.tripOptionId = tripdetail._TripOptionsId;

    this._itineraryService
      .getRegTravelerList(this.tripId)
      .subscribe((result) => {
        this.travelerList = result;
        this.dataSourceT.data = this.travelerList as TravellerEntity[];

        this._itineraryService
          .getRegisteredItineraryList(this.tripId)
          .subscribe((itinerary) => {
            this.itineraryList = itinerary;
            this.dataSource.data = this.itineraryList as TravellerEntity[];

            this.applyFilter('');
            this.selectedHotel.setValue(0);
          });
        this.generateForm(false);
      });
    this.selected.setValue(index);
    this.isSelect = false;
  }

  selectTraveler(
    id: number,
    customerId: number,
    tripId: number,
    groups: number,
    isEdit: boolean,
    isItineraryAdded: boolean,
    fromTraveler: string
  ) {
    this.selectedHotel.setValue(0);
    this.response = {};
    this.submitted = false;
    this._itineraryService
      .getItineraryDetail(id, customerId, tripId, groups, false, 0)
      .subscribe((result) => {
        this.data = result;
        //console.log(this.data);
        this.enrollmentId = this.data.enrollmentId;
        this.isSelect = this.data.travelerId;
        this.pointsOptionList1 =
          this.pointsOptionList2 =
          this.pointsOptionList3 =
          this.pointsOptionList4 =
          this.pointsOptionList5 =
          this.pointsOptionList6 =
            this.data.pointsOptionList;
        this.generateForm(false, false);

        if (isEdit) {
          this.generateForm(false, isEdit);

          if (this.data.flights && this.data.flights.length > 0) {
            this.data.flights.forEach((flight) => {
              this.addMoreFlight(flight, true);
            });
          } else if (this.data.isDepartureFlight) this.addMoreFlight(null, 0);

          if (this.data.returnFlights && this.data.returnFlights.length > 0) {
            this.data.returnFlights.forEach((retflight) => {
              this.addMoreReturnFlight(retflight, true);
            });
          } else if (this.data.isReturnFlight)
            this.addMoreReturnFlight(null, 0);

          this.data.layoverHotels.forEach((hotel) => {
            this.addMoreLayoverHotel(hotel, true);
          });
        } else {
          this.generateForm(false);

          if (this.data.isDepartureFlight) this.addMoreFlight(null, 0);

          if (this.data.isReturnFlight) this.addMoreReturnFlight(null, 0);
        }
      });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSourceT.filter = filterValue;
  }

  addGroup(groups: number) {
    this.response = { isShow: false };
    let tripData = this.trips[this.selected.value];
    //console.log(tripData);
    this._travelerService
      .getGroupDetail(tripData._CustomerId, this.tripOptionId, groups)
      .subscribe((result) => {

        //this.data=result;
        let groupDetail = { ...tripData, ...result };
        groupDetail.tripOptionId = this.tripOptionId;
        groupDetail.displayLinkButton = this.trips.displayLinkButton; // GET AR Status
        groupDetail.years= this.trips.years;
        groupDetail.isGroupAdded =true;

        console.log(groupDetail);
        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        // dialogConfig.id = "modal-component";
        dialogConfig.width = '800px';
        dialogConfig.data = groupDetail;
        const modalDialog = this.matDialog.open(
          GroupOptiosComponent,
          dialogConfig
        );

        modalDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.response = result;
            this.isSelect = false;
          }
        });
      });
  }

  copyItinerary(
    id: number,
    customerId: number,
    tripId: number,
    groups: number
  ) {
    this.submitted = false;
    this.response = { isShow: false };
    let tripData = this.trips[this.selected.value];
    // console.log(tripData);
    this._itineraryService
      .getItineraryCopyList(this.tripId, tripData._CustomerId, groups, id)
      .subscribe((result) => {
        if (result.length > 0) {
          const dialogConfig = new MatDialogConfig();
          // The user can't close the dialog by clicking outside its body
          dialogConfig.disableClose = false;
          // dialogConfig.id = "modal-component";
          dialogConfig.width = '700px';
          dialogConfig.data = result;
          const modalDialog = this.matDialog.open(
            CopyItineraryComponent,
            dialogConfig
          );

          modalDialog.afterClosed().subscribe((copyData) => {
            if (copyData && copyData.isCopy) {
              this._itineraryService
                .getItineraryDetail(
                  id,
                  customerId,
                  copyData.tripId,
                  groups,
                  copyData.isCopy,
                  copyData.id
                )
                .subscribe((result) => {
                  this.data = result;
                  // console.log(this.data);
                  this.enrollmentId = this.data.enrollmentId;
                  this.isSelect = this.data.travelerId;
                  this.pointsOptionList1 =
                    this.pointsOptionList2 =
                    this.pointsOptionList3 =
                    this.pointsOptionList4 =
                    this.pointsOptionList5 =
                    this.pointsOptionList6 =
                      this.data.pointsOptionList;
                  this.generateForm(false, true);

                  if (
                    (this.data.isDepartureFlight || this.data.traverlerDesignationId == 2 ) &&
                    this.data.flights &&
                    this.data.flights.length > 0
                  ) {
                    this.data.flights.forEach((flight) => {
                      this.addMoreFlight(flight, true);
                    });
                  } else if (this.data.isDepartureFlight)
                    this.addMoreFlight(null, 0);

                  if (
                    (this.data.isReturnFlight || this.data.traverlerDesignationId == 2) &&
                    this.data.returnFlights &&
                    this.data.returnFlights.length > 0
                  ) {
                    this.data.returnFlights.forEach((retflight) => {
                      this.addMoreReturnFlight(retflight, true);
                    });
                  } else if (this.data.isReturnFlight)
                    this.addMoreReturnFlight(null, 0);

                  this.data.layoverHotels.forEach((hotel) => {
                    this.addMoreLayoverHotel(hotel, true);
                  });
                  console.log(this.data.traverlerDesignationId);
                  console.log(this.data.isDepartureFlight);
                });
            } else {
              this._itineraryService
                .getItineraryDetail(id, customerId, tripId, groups, false, 0)
                .subscribe((result) => {
                  this.data = result;
                  //console.log(this.data);
                  this.enrollmentId = this.data.enrollmentId;
                  this.isSelect = this.data.travelerId;
                  this.pointsOptionList1 =
                    this.pointsOptionList2 =
                    this.pointsOptionList3 =
                    this.pointsOptionList4 =
                    this.pointsOptionList5 =
                    this.pointsOptionList6 =
                      this.data.pointsOptionList;
                  this.generateForm(false, false);

                  if (this.data.isDepartureFlight) this.addMoreFlight(null, 0);

                  if (this.data.isReturnFlight)
                    this.addMoreReturnFlight(null, 0);
                });
            }
          });
        } else {
          this._itineraryService
            .getItineraryDetail(id, customerId, tripId, groups, false, 0)
            .subscribe((result) => {
              this.data = result;
              //console.log(this.data);
              this.enrollmentId = this.data.enrollmentId;
              this.isSelect = this.data.travelerId;
              this.pointsOptionList1 =
                this.pointsOptionList2 =
                this.pointsOptionList3 =
                this.pointsOptionList4 =
                this.pointsOptionList5 =
                this.pointsOptionList6 =
                  this.data.pointsOptionList;
              this.generateForm(false, false);

              if (this.data.isDepartureFlight) this.addMoreFlight(null, 0);

              if (this.data.isReturnFlight) this.addMoreReturnFlight(null, 0);
            });
        }
      });
  }

  addTraveler(eId: number, tId: number, toId: number) {
    this.response = { isShow: false };
    eId = eId ? eId : this.enrollmentId;
    tId = tId ? tId : this.data.travelerId;
    toId = toId ? toId : this.data.tripOptionsId;

    this.enrollmentService.getTripList(eId).subscribe((result) => {
      //this.model = result;
      result[0]._IsFromItenary = true;
      const dialogConfig = new MatDialogConfig();
      // The user can't close the dialog by clicking outside its body
      dialogConfig.disableClose = true;
      // dialogConfig.id = "modal-component";
      dialogConfig.width = '1250px';
      dialogConfig.data = result;
      var travelerDetail = {
        travelerId: tId,
        tripId: toId,
        isFromItenary: true,
      };
      localStorage.setItem(
        'travelerFromItenry',
        JSON.stringify(travelerDetail)
      );

      const modalDialog = this.matDialog.open(TravelerComponent, dialogConfig);
      modalDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.response = result;
          if (result.messageCode == 1) {
            this.response = result;
            this.isSelect = false;
            localStorage.removeItem('travelerFromItenry');
            this.getList();
          }
        }
      });
    });
  }

  numberOnly(event): boolean {
    var dotCount = 0;
    var checkNumberOnly = '';
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
      dotCount += 1;
      checkNumberOnly = event.target.value;
      var numericCheck = event.target.value.toString();
      if (numericCheck.includes('.')) {
        dotCount += 1;
      }
      if (dotCount > 1) {
        dotCount = 0;
        return false;
      }
    }
    if (charCode > 31 && (charCode < 45 || charCode > 57 || charCode == 47)) {
      return false;
    }
    checkNumberOnly = event.target.value;
    var checkString = '';
    if (checkNumberOnly != null) {
      var numeric = event.target.value.toString();
      if (numeric.includes('.')) {
        var checkNumeric = numeric.split('.');
        if (checkNumeric.length > 2) {
          return false;
        }
        checkString = checkNumeric[1].split('');
        if (checkString.length > 1) {
          return false;
        }
      }
    }
    dotCount = 0;
    return true;
  }

  numberOnlywithMinus(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 45) return true;
    else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getFlightTime(flights, isReturn) {
    if (flights) {
      let departureDate;
      if (isReturn) {
        if (flights.value.ReturnDate) {
          departureDate = new Date(flights.value.ReturnDate).toDateString();
          flights.controls.ArrivalDate.setValue(
            new Date(flights.value.ReturnDate)
          );
        }
      } else {
        if (flights.value.DepartureDate) {
          departureDate = new Date(flights.value.DepartureDate).toDateString();
          flights.controls.ArrivalDate.setValue(
            new Date(flights.value.DepartureDate)
          );
        }
      }

      let airportCodeId = +flights.value.AirportCodeId,
        airline = +flights.value.Airline,
        flightNumber = flights.value.FlightNumber;
      if (airportCodeId && airline && flightNumber && departureDate) {
        let flightTimeparam = {
          AirportCodeId: airportCodeId,
          Airline: airline,
          FlightNumber: flightNumber,
          DepartureDate: departureDate,
          IsReturn: isReturn,
        };
        this._itineraryService
          .getFlightTime(flightTimeparam)
          .subscribe((result) => {
            console.log(result);
            if (result) {
              if (isReturn)
                flights.controls.ReturnTime.setValue(result.returnTime);
              else
                flights.controls.DepartureTime.setValue(result.departureTime);

              flights.controls.ArrivalTime.setValue(result.arrivalTime);

              flights.controls.ArrivalAirportCodeId.setValue(
                result.arrivalAirportCodeId.toString()
              );
            } else {
              if (isReturn) flights.controls.ReturnTime.setValue('');
              else flights.controls.DepartureTime.setValue('');
              flights.controls.ArrivalTime.setValue('');
            }
          });
      }
    }
  }
  changeRoomType() {
    let roomCost = this.data.bedTypeList.filter(
      (b) => b.id == this.itineraryForm.controls.DHRoomType.value
    );
    this.itineraryForm.controls.DHCost.setValue(
      roomCost[0].roomCost.toFixed(2)
    );
  }

  UpdateCustomerPurchasedTheirOwnFlight(event, index) {
    if (event.target.checked && index == 0) {
      this.a_flights.controls.forEach((obj, i) => {
        this.a_flights.controls[i].patchValue({
          CustomerPurchasedTheirOwn: true,
        });
      });

      this.a_returnFlights.controls.forEach((element, i) => {
        this.a_returnFlights.controls[i].patchValue({
          CustomerPurchasedTheirOwn: true,
        });
      });
    }
  }

  UpdateCustomerPurchasedTheirOwnReturnFlight(event, index) {
    console.log(event.target.checked);
    if (event.target.checked && index == 0) {
      this.a_returnFlights.controls.forEach((element, i) => {
        this.a_returnFlights.controls[i].patchValue({
          CustomerPurchasedTheirOwn: true,
        });
      });
    }
  }
}

export interface TravellerEntity {
  id: number;
  legalFirstName: string;
  legalMiddleName: string;
  legalLastName: string;
  legalSuffix: string;
  dateofBirth: Date;
  recordLocator: string;
  cellPhone: string;
  groups: number;
  customerId: number;
  tripOptionsId: number;
  enrollmentId: number;
}

export function MaskedDate(event: any) {
  var v = event.target.value;
  if (v.match(/^\d{2}$/) !== null) {
    event.target.value = v + '/';
  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
    event.target.value = v + '/';
  }
}
