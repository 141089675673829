import { Component, OnInit, Inject } from '@angular/core';
import { AirlineService } from '../../services/airline.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../Directives/custom-validators';

@Component({
  selector: 'app-airline',
  templateUrl: './airline.component.html',
  styleUrls: ['./airline.component.css']
})
export class AirlineComponent implements OnInit {
	response: any = {};
	public airlineForm: FormGroup;
	model = {};
	submitted = false;
	showDeleteButton = false;
	role = +localStorage.getItem('role');
	title = '';
	submitButtonText = 'Save';
	constructor(
		private _airlineService: AirlineService,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AirlineComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		this.generateForm();
	}

	get f() {
		return this.airlineForm.controls;
	}

	generateForm() {
		if (this.data.id > 0) {
			this.title = 'Edit Airline';
			this.showDeleteButton = this.role <= 2;
			this.submitButtonText = 'Update';
		} else {
			this.title = 'Add Airline';
			this.showDeleteButton = false;
			this.data.isActive = true;
		}

		this.airlineForm = this.fb.group({
			Id: [ this.data.id, Validators.required ],
			AirlineCode: [ this.data.airlineCode, [ Validators.required, CustomValidators.checkEmpty ] ],
			AirlineName: [ this.data.airlineName, [ Validators.required, CustomValidators.checkEmpty ] ],
			IsActive: [ this.data.isActive ]
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
	saveAirline() {
		this.submitted = true;
		if (this.airlineForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		let dataSave = this.airlineForm.value;
		var id = dataSave.Id;
		if (!dataSave.Id) id = 0;

		this.model = {
			Id: id,
			AirlineCode: dataSave.AirlineCode,
			AirlineName: dataSave.AirlineName,
			IsActive: dataSave.IsActive
		};

		this._airlineService.saveAirline(this.model).subscribe((result) => {
			if (result) {
				if (result.messageCode == '1') {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			}
		});
	}

	deleteAirline() {
		var id = this.data.id;
		if (confirm('Are you sure to delete the current record?')) {
			this._airlineService.deleteAirline(id).subscribe((result) => {
				this.response = result;
				if (result.messageCode == '1') {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			});
		}
	}
}
