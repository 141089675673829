import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

let myAppUrl = '';
@Injectable({
  providedIn: 'root',
})
export class TravelerService {
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  saveTraveler(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Traveler/AddUpdateTraveler', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getTravelerDetail(id: number, isHistory: boolean) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/GetTravelerDetail?id=' +
        id +
        '&isHistory=' +
        isHistory
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // getTravelerHistoryDetail(id: number) {
  //   return this._http.get<any>(myAppUrl + 'api/Traveler/GetTravelerHistoryDetail?id=' + id)
  //     .pipe(map(data => { return data; }))
  // }

  getBedTypeList(tripId: number) {
    return this._http
      .get<any>(myAppUrl + 'api/Traveler/BedTypeList?tripId=' + tripId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getRegTravelerList(tripId: number) {
    return this._http
      .get<any>(myAppUrl + 'api/Traveler/RegTravelerList?tripId=' + tripId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTravelerDetailList(tripId: number) {
    return this._http
      .get<any>(myAppUrl + 'api/Traveler/TravelerDetailList?tripId=' + tripId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getTravelerHistoryList(tripId: number) {
    return this._http
      .get<any>(myAppUrl + 'api/Traveler/TravelerHistoryList?tripId=' + tripId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteTraveler(id: number, logUserId) {
    return this._http
      .post<any>(
        myAppUrl + 'api/Traveler/TravelerDelete/' + id + '/' + logUserId,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteTravelerHistory(id: number) {
    return this._http
      .post<any>(myAppUrl + 'api/Traveler/TravelerHistoryDelete?id=' + id, null)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getMailingAddress(id: number, mailingAddress: number) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/GetMailingAddress?id=' +
        id +
        '&mailingAddress=' +
        mailingAddress
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTripCodeList() {
    return this._http.get<any>(myAppUrl + 'api/Traveler/TripCodeList').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getTravelerByGroupList(model) {
    return this._http
      .get<any>(myAppUrl + 'api/Traveler/TravelerByGroupList', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  download(url): Observable<Blob> {
    return this._http.post<Blob>(url, { responseType: 'blob' as 'json' });
  }
  getGroupDetail(customerId: number, tripId: number, group: number) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/GetGroupDetail?customerId=' +
        customerId +
        '&tripId=' +
        tripId +
        '&groups=' +
        group
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // getAirlineList() {
  //   return this._http.get<any>(myAppUrl + 'api/Traveler/AirlineList')
  //     .pipe(map(data => { return data; }))
  // }
  checkDuplicateGroupOptions(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Traveler/CheckDuplicateGroupOptions', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  saveGroupOption(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Traveler/AddUpdateGroupOptions', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  checkGroupAdded(customerId: number, tripId: number, group: number) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/CheckGroupAdded?customerId=' +
        customerId +
        '&tripId=' +
        tripId +
        '&groups=' +
        group
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  reportTravelerList(tripId: number, years: number) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportTravelerList?tripId=' +
        tripId +
        '&years=' +
        years
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  reportTravelerWithGroupList(tripId: number, years: number, pageIndex: number, pageSize: number, searchText: string): Observable<any> {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportTravelerWithGroup?tripId=' + tripId +
        '&years=' + years +
        '&pageNumber=' + pageIndex +
        '&recordCount=' + pageSize +
        '&searchText=' + searchText
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  reportTravelerWithGroupListNew(tripId: number, years: number, pageIndex: number, pageSize: number, searchText: string): Observable<any> {
    return this._http.get<any>(
      myAppUrl
      + 'api/Traveler/ReportTravelerWithGroupNew?tripId='
      + tripId +
      '&years=' + years +
      '&pageNumber=' + pageIndex +
      '&recordCount=' + pageSize +
      '&searchText=' + searchText
    )
      .pipe(map((data) => {
        return data;
      })
      );
  }

  reportTravelerNoAirfareNeeded(tripId: number, years: number, pageIndex: number, pageSize: number, searchText: string): Observable<any> {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportTravelerNoAirfareNeeded?tripId=' + tripId +
        '&years=' + years +
        '&pageNumber=' + pageIndex +
        '&recordCount=' + pageSize +
        '&searchText=' + searchText
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  reportRoomRoster(tripId: number, years: number) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportRoomRoster?tripId=' +
        tripId +
        '&years=' +
        years
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  reportLayoverHotels(tripId: number, years: number, groups: number) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportLayoverHotels?tripId=' +
        tripId +
        '&years=' +
        years +
        '&groups=' +
        groups
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTravelerHeader(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportRoomRosterGroupWiseColumns?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getReportTravelFormInfoByRoomColumns(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportTravelFormInfoByRoomColumns?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getShippingHeader(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportShippingColumns?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAirfareItineraryHeader(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportAirfareItineraryColumns?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  //added on 05/09/2023
  getAirfareManifestHeader(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportAirfareManifestColumns?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getMasterTravelerManifestColumns(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportMasterTravelerManifestColumns?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getMobileAppReport(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/MobileAppReport?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getMultipleFlightItineraryHeader(year, tripId, isReturn) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportMultipleFlightItineraryColumns?year=' +
        year +
        '&tripId=' +
        tripId +
        '&isReturn=' +
        isReturn
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  reportTravelerPointsOptionList(tripId: number, years: number) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/TravelerPointsOptionList?tripId=' +
        tripId +
        '&years=' +
        years
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  CheckTravelerRegisterOnAnotherTrip(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Traveler/CheckTravelerRegisterOnAnotherTrip', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getHotelDetailList(tripOptionsID: number) {
    return this._http
      .get<any[]>(
        myAppUrl +
        'api/Traveler/GetGroupOptionsHotelDesignationDetails?tripOptionsID=' + tripOptionsID
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getRoomLetterHeader(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportRoomLetterColumns?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  //added on 05/08/2024
  getInitialFlightPurchasedColumns(year, tripId) {
    return this._http
      .get<any>(
        myAppUrl +
        'api/Traveler/ReportInitialFlightPurchasedColumns?year=' +
        year +
        '&tripId=' +
        tripId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}


