import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AirportLayoverHotelService } from 'src/app/services/airport-layover-hotel.service';
import { CustomValidators } from 'src/app/Directives/custom-validators';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-airport-layover-hotel-save',
  templateUrl: './airport-layover-hotel-save.component.html',
  styleUrls: ['./airport-layover-hotel-save.component.css'],
})
export class AirportLayoverHotelSaveComponent implements OnInit {
  response: any = {};
  public airportForm: FormGroup;
  model = {};
  submitted = false;
  showDeleteButton = false;
  role = +localStorage.getItem('role');
  title = '';
  submitButtonText = 'Save';
  yesNo = [];
  public mask = [
    '(',
    /[0-9]/,
    /\d/,
    /\d/,
    ')',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  //Added on 12/09/2023
  AirportCode: '';
  AirportName: '';
  constructor(
    private utilityService: UtilityService,
    private _airportLayoverHotelService: AirportLayoverHotelService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AirportLayoverHotelSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.yesNo = this.utilityService.yesNo;
    this.generateForm();
  }

  get f() {
    return this.airportForm.controls;
  }

  generateForm() {
    if (this.data.id > 0) {
      this.title = 'Edit Airport Layover Hotel';
      this.showDeleteButton = this.role <= 2;
      this.submitButtonText = 'Update';
    } else {
      this.title = 'Add Airport Layover Hotel';
      this.showDeleteButton = false;
      this.data.isActive = true;
    }
    //Added on 12/09/2023
    this.AirportCode = this.data.airportCode;
    this.AirportName = this.data.airportName;
    this.airportForm = this.fb.group({
      Id: [this.data.id, Validators.required],
      AirportId: [this.data.airportId, Validators.required],
      AirportCode: [
        this.data.airportCode,
        [Validators.required, CustomValidators.checkEmpty],
      ],

      LayoverHotelName: [
        this.data.layoverHotelName,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      LayoverHotelAddress: [
        this.data.layoverHotelAddress,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      LayoverHotelPhone: [
        this.data.layoverHotelPhone,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      NumberOfRoomsContracted: [this.data.numberOfRoomsContracted],
      Recommend: [
        this.data.recommend,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      UpdatedBy: [this.utilityService.getCurrentUserId()],
      CreatedBy: [this.utilityService.getCurrentUserId()],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  saveAirportLayoverHotel() {
    this.submitted = true;
    if (this.airportForm.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }

    let dataSave = this.airportForm.value;
    if (!dataSave.NumberOfRoomsContracted) {
      dataSave.NumberOfRoomsContracted = null;
    } else {
      dataSave.NumberOfRoomsContracted = +dataSave.NumberOfRoomsContracted;
    }
    console.log(dataSave);
    this._airportLayoverHotelService
      .saveAirportLayoverHotel(dataSave)
      .subscribe((saveResponse) => {
        if (saveResponse) {
          if (saveResponse.messageCode == '1') {
            this.dialogRef.close(saveResponse);
          } else {
            this.response = saveResponse;
          }
        }
      });
  }

  //Added on 11/09/2023
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 45) return true;
    else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // deleteAirport() {
  //   var id = this.data.id;
  //   if (confirm('Are you sure to delete the current record?')) {
  //     this._airportService.deleteAirport(id).subscribe((result) => {
  //       this.response = result;
  //       if (result.messageCode == '1') {
  //         this.dialogRef.close(result);
  //       } else {
  //         this.response = result;
  //       }
  //     });
  //   }
  // }
}
