import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewCustomerListComponent } from './Components/new-customer-list/new-customer-list.component';
import { CustomerListComponent } from './Components/customer-list/customer-list.component';
import { TripEnrollmentComponent } from './Components/trip-enrollment/trip-enrollment.component';
import { TripEnrollmentTestComponent } from './Components/trip-enrollment-test/trip-enrollment-test.component';
import { TripEnrollmentListComponent } from './Components/trip-enrollment-list/trip-enrollment-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddUserComponent } from './Components/add-user/add-user.component';
import { UserListComponent } from './Components/user-list/user-list.component';
import { RoomTypesListComponent } from './Components/room-types-list/room-types-list.component';
import { AuthGuard } from './auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { SecureComponent } from './layouts/secure/secure.component';
import { ReportEnrolledComponent } from './Components/report-enrolled/report-enrolled.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AirportListComponent } from './airport-list/airport-list.component';
import { AirlineListComponent } from './Components/airline-list/airline-list.component';
import { WhsListComponent } from './whs-list/whs-list.component';
import { SalesmanListComponent } from './salesman-list/salesman-list.component';
import { TripOptionsComponent } from './trip-options/trip-options.component';
import { TripwhsdefaultsListComponent } from './tripwhsdefaults-list/tripwhsdefaults-list.component';
import { SalesmanImportComponent } from './salesman-import/salesman-import.component';
import { CustomerImportComponent } from './customer-import/customer-import.component';
import { ReportNotEnrolledComponent } from './Components/report-not-enrolled/report-not-enrolled.component';
import { ReportTravelerComponent } from './Components/report-traveler/report-traveler.component';
import { NewTripEnrollmentListComponent } from './Components/new-trip-enrollment-list/new-trip-enrollment-list.component';
import { ReportCutomerStatementComponent } from './Components/report-cutomer-statement/report-cutomer-statement.component';
import { PointOptionsListComponent } from './Components/point-options-list/point-options-list.component';
import { PointsAndRewardsComponent } from './Components/points-and-rewards/points-and-rewards.component';
import { ImportPointsAndRewardsComponent } from './Components/points-and-rewards/import-points-and-rewards/import-points-and-rewards.component';
import { ImportPointsListComponent } from './Components/points-and-rewards/import-points-list/import-points-list.component';
import { PointsAndRewardsStatusComponent } from './Components/points-and-rewards/points-and-rewards-status/points-and-rewards-status.component';
import { PointsAndRewardsStatusWithPreviousYearComponent } from './Components/points-and-rewards/points-and-rewards-status-with-previous-year/points-and-rewards-status-with-previous-year.component';
import { AllocatePointsComponent } from './Components/allocate-points/allocate-points.component';
import { ImportArReleaseComponent } from './Components/import-ar-release/import-ar-release.component';
import { IssueCertificatesComponent } from './Components/issue-certificates/issue-certificates.component';
import { CustomerArReleasedComponent } from './Components/customer-ar-released/customer-ar-released.component';
import { CatalogPointComponent } from './Components/catalog-point/catalog-point.component';
import { CatalogPointImportComponent } from './Components/catalog-point-import/catalog-point-import.component';
import { CustomerListPointsComponent } from './Components/customer-list-points/customer-list-points.component';
import { ReportTravelerListComponent } from './Components/report-traveler-list/report-traveler-list.component';
import { ReportTravelerWithGroupComponent } from './Components/report-traveler-with-group/report-traveler-with-group.component';
import { ReportTravelerSpecialRequestsComponent } from './Components/report-traveler-special-requests/report-traveler-special-requests.component';
import { ReportLayoverHotelsComponent } from './Components/report-layover-hotels/report-layover-hotels.component';
import { ReportRoomRosterGroupWiseComponentComponent } from './Components/report-room-roster-group-wise-component/report-room-roster-group-wise-component.component';
import { ReportShippingComponent } from './Components/report-shipping/report-shipping.component';
import { ReportAirfareItineraryComponent } from './Components/report-airfare-itinerary/report-airfare-itinerary.component';
import { ReportItineraryDepartureComponent } from './Components/report-itinerary-departure/report-itinerary-departure.component';
import { ReportItineraryReturnComponent } from './Components/report-itinerary-return/report-itinerary-return.component';
import { ReportMobileAppSpeakerComponent } from './Components/report-mobile-app-speaker/report-mobile-app-speaker.component';
import { ReportMobileAppAttendeesComponent } from './Components/report-mobile-app-attendees/report-mobile-app-attendees.component';
import { ReportItineraryWithMultipleFlightComponent } from './Components/report-itinerary-with-multiple-flight/report-itinerary-with-multiple-flight.component';
import { ReportYtdCustomerStatementComponent } from './Components/report-ytd-customer-statement/report-ytd-customer-statement.component';
import { ReportTravelerPointsOptionComponent } from './Components/report-traveler-points-option/report-traveler-points-option.component';
import { ReportCustomerStatementCommonComponent } from './Components/report-customer-statement-common/report-customer-statement-common.component';
import { ReportDashboardComponent } from './Components/report-dashboard/report-dashboard.component';
import { ReportNoAirfareNeededComponent } from './Components/report-no-airfare-needed/report-no-airfare-needed.component';
import { ReportLogDeletedTravelerComponent } from './Components/ReportLog/report-log-deleted-traveler/report-log-deleted-traveler.component';
import { ReportLogUpdatedTravelerComponent } from './Components/ReportLog/report-log-updated-traveler/report-log-updated-traveler.component';
import { ReportLogGroupDetailUpdateComponent } from './Components/ReportLog/report-log-group-detail-update/report-log-group-detail-update.component';
import { ReportLogTravelerFlightUpdateComponent } from './Components/ReportLog/report-log-traveler-flight-update/report-log-traveler-flight-update.component';
import { ReportLogTravelerLayoverHotelUpdateComponent } from './Components/ReportLog/report-log-traveler-layover-hotel-update/report-log-traveler-layover-hotel-update.component';
import { ReportLogDeletedLayoverHotelsComponent } from './Components/ReportLog/report-log-deleted-layover-hotels/report-log-deleted-layover-hotels.component';
import { ReportLogDeletedFlightComponent } from './Components/ReportLog/report-log-deleted-flight/report-log-deleted-flight.component';
import { ReportLogTravelerHotelUpdateComponent } from './Components/ReportLog/report-log-traveler-hotel-update/report-log-traveler-hotel-update.component';
import { ReportTravelFormInfoByRoomComponent } from './Components/report-travel-form-info-by-room/report-travel-form-info-by-room.component';
import { TravelersByUserComponent } from './travelers-by-user/travelers-by-user.component';
import { ReportYearEndCustomerStatementsComponent } from './Components/report-year-end-customer-statements/report-year-end-customer-statements.component';
import { ReportAirfareManifestComponent } from './Components/report-airfare-manifest/report-airfare-manifest.component';
import { ReportMasterTravelerManifestComponent } from './Components/report-master-traveler-manifest/report-master-traveler-manifest.component';
import { ReportLogCustomerParentChildRelationComponent } from './Components/ReportLog/report-log-customer-parent-child-relation/report-log-customer-parent-child-relation.component';
import { ReportLogRolloverPointsComponent } from './Components/ReportLog/report-log-rollover-points/report-log-rollover-points.component';
import { ReportLogTripSelectionComponent } from './Components/ReportLog/report-log-trip-selection/report-log-trip-selection.component';
import { AirportLayoverHotelsComponent } from './airport-list/airport-layover-hotels/airport-layover-hotels.component';
import { PendingArReleaseComponent } from './Components/pending-ar-release/pending-ar-release.component';
import { HotelDesignationComponent } from './Components/hotel-designation/hotel-designation.component';
import { TripOptionAddEditPopupComponent } from './trip-option-add-edit-popup/trip-option-add-edit-popup.component';
import { ReportLogOriginalTripSelectionComponent } from './Components/ReportLog/report-log-original-trip-selection/report-log-original-trip-selection.component';
import { ReportLogOriginalGroupRegistrationComponent } from './Components/ReportLog/report-log-original-group-registration/report-log-original-group-registration.component';
import { ReportLogNameBadgeComponent } from './Components/ReportLog/report-log-name-badge/report-log-name-badge.component';
import { ReportLogHistoricalPointComponent } from './Components/ReportLog/report-log-historical-point/report-log-historical-point.component';
import { ReportLogHistoricalTravelerComponent } from './Components/ReportLog/report-log-historical-traveler/report-log-historical-traveler.component';
import { ReportRoomLetterListComponent } from './Components/ReportLog/report-room-letter-list/report-room-letter-list.component';
import { ReportCatalogPointComponent } from './Components/report-catalog-point/report-catalog-point.component';
import { ReportNightlyCountComponent } from './Components/report-nightly-count/report-nightly-count.component';
import { ReportInitialFlightPurchasedComponent } from './Components/report-initial-flight-purchased/report-initial-flight-purchased.component';
import { ReportItineraryDeparturesReturnComponent } from './Components/report-itinerary-departures-return/report-itinerary-departures-return.component';

const routes: Routes = [
	// Admin routes goes here here
	{
		path: '',
		component: SecureComponent,
		canActivateChild: [AuthGuard],
		children: [
			{ path: 'not-permanent-customers', component: CustomerListComponent },
			{ path: 'enrolled-customers', component: ReportEnrolledComponent },
			{ path: 'travelers-by-group', component: ReportTravelerComponent },
			{ path: 'travelers-by-room-types', component: ReportTravelerComponent },
			{ path: 'not-enrolled-customers', component: ReportNotEnrolledComponent },
			// { path: 'customer-statements', component: ReportCutomerStatementComponent },
			{ path: 'customer-statements', component: ReportCustomerStatementCommonComponent },
			{ path: 'ytd-customer-statements', component: ReportYtdCustomerStatementComponent },
			{ path: 'traveler-point-options', component: ReportTravelerPointsOptionComponent },
			{ path: 'company-import', component: CustomerImportComponent },
			{ path: 'trip-enrollment', component: NewTripEnrollmentListComponent },
			{ path: 'trip-enrollment-old', component: TripEnrollmentListComponent },
			{ path: 'trip-enrollment-detail', component: TripEnrollmentComponent },
			{ path: 'trip-enrollment-detail/:id', component: TripEnrollmentComponent },
			{ path: 'trip-enrollment-detail-pending-allocation/:id/:isAllocationPending', component: TripEnrollmentComponent },
			{ path: 'trip-enrollment-test-detail/:id', component: TripEnrollmentTestComponent },
			{ path: 'trip-enrollment-detail/:id/:isTripAdded', component: TripEnrollmentComponent },
			{ path: 'companies', component: NewCustomerListComponent },
			{ path: 'room-types', component: RoomTypesListComponent },
			{ path: 'back-office-user', component: UserListComponent },
			{ path: '', component: DashboardComponent },
			{ path: 'update-profile', component: AddUserComponent },
			{ path: 'airport-list', component: AirportListComponent },
			{ path: 'airline', component: AirlineListComponent },
			{ path: 'whs-list', component: WhsListComponent },
			{ path: 'salesman-import', component: SalesmanImportComponent },
			{ path: 'salesman-list', component: SalesmanListComponent },
			{ path: 'trip-options', component: TripOptionsComponent },
			{ path: 'point-options', component: PointOptionsListComponent },
			{ path: 'trip-whs-details', component: TripwhsdefaultsListComponent },
			{ path: 'points-and-rewards', component: PointsAndRewardsComponent },
			{ path: 'points-and-rewards-import', component: ImportPointsAndRewardsComponent },
			{ path: 'imported-points-and-rewards', component: ImportPointsListComponent },
			{ path: 'points-and-rewards-status', component: PointsAndRewardsStatusComponent },
			{
				path: 'points-and-rewards-status-with-previous-year',
				component: PointsAndRewardsStatusWithPreviousYearComponent
			},
			{ path: 'allocate-points', component: AllocatePointsComponent },
			{ path: 'allocated-points', component: AllocatePointsComponent },
			{ path: 'import-ar-release', component: ImportArReleaseComponent },
			{ path: 'issue-certificates', component: IssueCertificatesComponent },
			{ path: 'export-certificates', component: IssueCertificatesComponent },
			{ path: 'customer-ar-release', component: CustomerArReleasedComponent },
			{ path: 'exported-certificates', component: IssueCertificatesComponent },
			{ path: 'catalog-points', component: CatalogPointComponent },
			{ path: 'import-catalog-points', component: CatalogPointImportComponent },
			{ path: 'customer-points', component: CustomerListPointsComponent },
			{ path: 'traveler', component: ReportTravelerListComponent },
			{ path: 'traveler-list', component: TravelersByUserComponent },
			{ path: 'purchase-airfare', component: ReportTravelerWithGroupComponent },
			{ path: 'traveler-report', component: ReportTravelerSpecialRequestsComponent },
			//{ path: 'room-roster', component: ReportRoomRosterComponent },
			{ path: 'layover-hotels', component: ReportLayoverHotelsComponent },
			{ path: 'room-roster', component: ReportRoomRosterGroupWiseComponentComponent },
			{ path: 'shipping-report', component: ReportShippingComponent },
			{ path: 'airfare-itinerary', component: ReportAirfareItineraryComponent },
			{ path: 'airfare-manifest', component: ReportAirfareManifestComponent },
			{ path: 'master-traveler-manifest', component: ReportMasterTravelerManifestComponent },
			{ path: 'itinerary-summary-departure', component: ReportItineraryDepartureComponent },
			{ path: 'itinerary-summary-return', component: ReportItineraryReturnComponent },
			{ path: 'mobile-app-speakers', component: ReportMobileAppSpeakerComponent },
			{ path: 'mobile-app-attendees', component: ReportMobileAppAttendeesComponent },
			{ path: 'layover-flights', component: ReportItineraryWithMultipleFlightComponent },
			{ path: 'report-dashboard', component: ReportDashboardComponent },
			{ path: 'no-airfare-needed', component: ReportNoAirfareNeededComponent },

			{ path: 'deleted-traveler-report', component: ReportLogDeletedTravelerComponent },
			{ path: 'traveler-info-update-report', component: ReportLogUpdatedTravelerComponent },
			{ path: 'updated-group-detail-report', component: ReportLogGroupDetailUpdateComponent },
			{ path: 'traveler-flight-update-report', component: ReportLogTravelerFlightUpdateComponent },
			{ path: 'updated-layover-hotel-report', component: ReportLogTravelerLayoverHotelUpdateComponent },
			{ path: 'deleted-layover-hotel-report', component: ReportLogDeletedLayoverHotelsComponent },
			{ path: 'deleted-flight-report', component: ReportLogDeletedFlightComponent },
			{ path: 'traveler-hotel-update-report', component: ReportLogTravelerHotelUpdateComponent },
			{ path: 'travel-form-info-by-room-report', component: ReportTravelFormInfoByRoomComponent },
			{ path: 'year-end-customer-statements', component: ReportYearEndCustomerStatementsComponent },
			{ path: 'customer-parent-child-relation-report', component: ReportLogCustomerParentChildRelationComponent },
			{ path: 'rollover-points-report', component: ReportLogRolloverPointsComponent },
			
			{ path: 'trip-selection-report', component: ReportLogTripSelectionComponent },
			{ path: 'pending-ar-release', component: PendingArReleaseComponent },
			{ path: 'airport-layover-hotels/:airportId', component: AirportLayoverHotelsComponent },
			{ path: 'hotel-Designation', component: HotelDesignationComponent },
            { path: 'report-log-original-trip-selection', component: ReportLogOriginalTripSelectionComponent},
            { path: 'report-log-original-group-registration', component:ReportLogOriginalGroupRegistrationComponent},
	        { path: 'report-log-name-badge', component:ReportLogNameBadgeComponent},
			{ path: 'report-historical-point', component: ReportLogHistoricalPointComponent },
			{ path: 'report-log-historical-traveler', component: ReportLogHistoricalTravelerComponent },
			{ path: 'report-room-letter-list', component: ReportRoomLetterListComponent },
			{ path: 'report-catalog-points', component:ReportCatalogPointComponent},
			{ path: 'report-nightly-count', component: ReportNightlyCountComponent },
			{ path: 'report-initial-flight-purchased',component:ReportInitialFlightPurchasedComponent},
			{ path: 'report-Itinerary-Departures-Return', component:ReportItineraryDeparturesReturnComponent}
		]
	},
	//no layout routes
	{ path: 'login', component: LoginComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: '**', redirectTo: 'page-not-found' }
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes),
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled'
		})
	],

	exports: [RouterModule]
})
export class AppRoutingModule { }
