import { Injectable, Inject } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

let myAppUrl = "";
@Injectable({
  providedIn: 'root'
})
export class WhsService {

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  saveWHS(model) {
    return this._http.post<any>(myAppUrl + 'api/WHS/AddUpdateWHS', model)
      .pipe(map(data => { return data; }))
  }

  // saveWHS(model) {
  //   return this._http.post<any>(myAppUrl + 'api/WHS/AddUpdateWHS', model)
  //     .map(data => { return data; })
  // }

  getWHSDetail(id: number) {
    return this._http.get<any>(myAppUrl + 'api/WHS/GetWHSDetail?id=' + id)
      .pipe(map(data => { return data; }))
  }
  getWHSList() {
    return this._http.get<any>(myAppUrl + 'api/WHS/WHSList')
      .pipe(map(data => { return data; }))
  }
  getWHSListActive() {
    return this._http.get<any>(myAppUrl + 'api/WHS/WHSListActive')
      .pipe(map(data => { return data; }))
  }
  deleteWHS(id: number) {
    return this._http.post<any>(myAppUrl + 'api/WHS/WHSDelete?id=' + id, null)
      .pipe(map(data => { return data; }))
  }
}
