import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SalesmanService } from '../services/salesman.service';

@Component({
	selector: 'app-salesman-add-edit-popup',
	templateUrl: './salesman-add-edit-popup.component.html',
	styleUrls: [ './salesman-add-edit-popup.component.css' ]
})
export class SalesmanAddEditPopupComponent implements OnInit {
	response: any = {};
	public salesmanForm: FormGroup;
	model = {};
	submitted = false;
	showDeleteButton=false;
	role=+localStorage.getItem('role');
	title="";
	submitButtonText="Save";
	constructor(
		private _salesmanService: SalesmanService,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<SalesmanAddEditPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		this.generateForm();
	}

	get f() {
		return this.salesmanForm.controls;
	}

	generateForm() {
		//console.log(this.data);
		if (this.data.id > 0) { this.title = 'Edit Salesman'; this.showDeleteButton=this.role<=2;this.submitButtonText="Update"; }
		else { this.title = 'Add Salesman'; this.showDeleteButton=false;this.data.isActive=true;}
		this.salesmanForm = this.fb.group({
			Id: [ this.data.id, Validators.required ],
			PpeSalesmanCode: [ this.data.ppeSalesmanCode, Validators.required ],
			SalesmanName: [ this.data.salesmanName, Validators.required ],
			SalesmanEmail: [ this.data.salesmanEmail, Validators.email ],
			IsActive:[this.data.isActive]
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
	saveSalesman() {
		this.submitted = true;
		if (this.salesmanForm.invalid) {
			let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
			if (invalidFields[1]) {
				if (invalidFields[2]) invalidFields[2].focus();
				invalidFields[1].focus();
				return;
			}
		}
		let dataSave = this.salesmanForm.value;
		var id = dataSave.Id;
		if (!dataSave.Id) id = 0;

		this.model = {
			Id: id,
			PpeSalesmanCode: dataSave.PpeSalesmanCode,
			SalesmanName: dataSave.SalesmanName,
			SalesmanEmail: dataSave.SalesmanEmail,
			IsActive: dataSave.IsActive
		};

		this._salesmanService.saveSalesman(this.model).subscribe((result) => {
			if (result) {
				if (result.messageCode == '1') {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			}
		});
	}
	deleteSalesman() {
		var id=this.data.id;
		if (confirm('Are you sure to delete the current record?')) {
			this._salesmanService.deleteSalesman(id).subscribe((result) => {
				this.response = result;
				if (result.messageCode == '1') {
					this.dialogRef.close(result);
				} else {
					this.response = result;
				}
			});
		}
	}
}
