import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-points-break-up",
  templateUrl: "./points-break-up.component.html",
  styleUrls: ["./points-break-up.component.css"],
})
export class PointsBreakUpComponent implements OnInit {
  response: any = {};
  model: any = {};
  totalEarnedPoints: number = 0;
  constructor(
    public dialogRef: MatDialogRef<PointsBreakUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  ngOnInit() {
    this.model = this.data;
    this.totalEarnedPoints =
      this.model.lastYearEarnPoints - this.model.lastYearChildPoints;
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
