import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
let myAppUrl = '';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }

  getUserDetail(user) {
    return this._http.post<any>(myAppUrl + 'api/User/GetUser', user).pipe(
      map((data) => {
        return data;
      })
    );
  }

  sendPasswordDetail(model) {
    return this._http
      .post<any>(myAppUrl + 'api/User/GetPasswordDetail', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  saveUserDetail(model) {
    return this._http
      .post<any>(myAppUrl + 'api/User/AddUpdateUser', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getUserList() {
    return this._http.get<any>(myAppUrl + 'api/User/UserList').pipe(
      map((data) => {
        return data;
      })
    );
  }

  deleteUser(model) {
    return this._http.post<any>(myAppUrl + 'api/User/DeleteUser', model).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getTripOptionListActive() {
    return this._http
      .get<any>(myAppUrl + 'api/TripOption/TripOptionListActive')
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getSchedulerTest() {
    return this._http.get<any>(myAppUrl + 'api/SchedulerTest/Get').pipe(
      map((data) => {
        return data;
      })
    );
  }
}
