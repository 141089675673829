import { Component, Input, Output, EventEmitter, Inject, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';

let myAppUrl = '';
@Component({
	selector: 'app-fileupload',
	templateUrl: './fileupload.component.html',
	styleUrls: [ './fileupload.component.css' ]
})
export class FileuploadComponent {
	
	public progress: number;
	public message: string;
	@Input() fileType: string;
	@Input() fileName: string;
	response: any = {};
	@ViewChild('file', {static:true}) uploadElRef: ElementRef
	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		myAppUrl=baseUrl;
	}

	@Output() fileEvent: EventEmitter<any> = new EventEmitter();

	upload(files) {
		this.response = {};
		if (files.length === 0) return;

		const formData = new FormData();

		for (let file of files) formData.append(file.name, file);
		formData.append('fileType', this.fileType);
		const uploadReq = new HttpRequest('POST', 'api/Upload', formData, {
			reportProgress: true
		});

		this.http.request(uploadReq).subscribe((event) => {
		
			if (event.type === HttpEventType.UploadProgress)
				this.progress = Math.round(100 * event.loaded / event.total);
			else if (event.type === HttpEventType.Response) {
				this.message = event.body.toString();
				this.response = event.body;
				this.response.fileType = this.fileType;
				this.fileEvent.emit(this.response);
				//console.log(this.response);
				this.fileName = this.response.data;
				this.uploadElRef.nativeElement.value = ''
			}
		});
	}
	// sampleFileDownload() {
	// 	window.open(myAppUrl + 'api/Salesman/SampleFileDownload?filename=' + this.fileName +"&filePath=./dist/assets/documents", '_blank');
	// }
}