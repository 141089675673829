import { Component, OnInit, Inject } from '@angular/core';
import * as XLSX from 'xlsx';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';
import { ServerDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-report-not-enrolled',
	templateUrl: './report-not-enrolled.component.html',
	styleUrls: [ './report-not-enrolled.component.css' ]
})
export class ReportNotEnrolledComponent implements OnInit {
	model: any = {};
	settings: any;
	data: any={};
	response: any = {};
	from :number=1; to:number=25; totalRecord:number;
	constructor(
		private enrollmentService: EnrollmentService,
		private _router: Router,
		private _utilityService: UtilityService,
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string
	) {
		this.data = new ServerDataSource(http, {
			endPoint: baseUrl + 'api/TripEnrollment/NotEnrollmentList',
			dataKey: 'data.data',
			totalKey: 'totalCount',
			pagerLimitKey: 'recordCount',
			pagerPageKey: 'pageNumber',
			filterFieldKey: '#field#',
			sortFieldKey: 'sortColumn',
			sortDirKey: 'sortOrder'
		});

		this.settings = {
			columns: {
				customerNumber: {
					title: 'Company No.',
					sort: false
				},
				companyName: {
					title: 'Company Name',
					sort: false
				},
				dapContact: {
					title: 'DAP Contact',
					sort: false
				},
				dapPhone: {
					title: 'Phone',
					sort: false
				},
				ppewhsCodeString: {
					title: 'WHS Code',
					sort: false
				},
				ppeSalesmanCodeString: {
					title: 'Salesman Code',
					sort: false
				},
				lastYearSalesText: {
					title: 'Last Year Sales',
					filter: false,
					sort: false,
					position: 'right'
				}
			},
			noDataMessage: "No record found.",
			actions: {
				edit: false,
				add: false,
				delete: false,
				position: 'right'
			},
			pager: {
				display: true,
				perPage: 25
			},
			attr: {
				class: 'table table color-table dark-table table-striped'
			}
		};
	}

	ngOnInit() {
		this.data.onChanged().subscribe((change) => {			
			this.from=((change.paging.page-1)*change.paging.perPage)+1;
			this.to=this.data.lastRequestCount <=change.paging.page*change.paging.perPage?this.data.lastRequestCount:change.paging.page*change.paging.perPage;
		});

	}

	// exportCustomer() {
	// 	let timeSpan = new Date().toISOString();
	// 	let prefix = name || 'Not_Enrolled_Customer';
	// 	let fileName = `${prefix}-${timeSpan}`;
	// 	let targetTableElm = document.getElementById('tblEnrolledCustomer');
	// 	let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{ sheet: prefix });
	// 	XLSX.writeFile(wb, `${fileName}.xlsx`);
	// }

	exportCustomer() {
		// let field = '';
		// let search = '';
		// let filterData = this.data.getFilter();
		// if(filterData.filters.length>0)
		// {
		// 	field = filterData.filters[filterData.filters.length-1].field;
		// 	search = filterData.filters[filterData.filters.length-1].search;
		// }
		// //console.log(field);
		// //console.log(search);
		// this.enrollmentService.getNotEnrollmentList_Export(field,search);

		

		let filterData = this.data.getFilter();let extraSearch='';
		if(filterData.filters.length>0)
		{
			filterData.filters.forEach(element => {
				extraSearch+= '&'+element.field+'='+element.search;				
			});
		}		
		window.open(this.baseUrl + 'api/TripEnrollment/NotEnrollmentListExportToExcel?'+extraSearch);
	}
}
