import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  PageEvent,
} from "@angular/material";
import { TravelerService } from "../../services/traveler.service";
import { UtilityService } from "../../services/utility.service";
import * as XLSX from 'xlsx';

@Component({
  selector: "app-report-traveler-with-group",
  templateUrl: "./report-traveler-with-group.component.html",
  styleUrls: ["./report-traveler-with-group.component.css"],
})
export class ReportTravelerWithGroupComponent implements OnInit {
  model: any = {};
  yearList: any = [];
  tripOptionList = [];
  alltripList = [];
  years = "";
  tripId = "";
  searchText = "";

  public displayedColumns = [
    "ppeWhsCode",
    "customerNumber",
    "companyName",
    "groups",
    "firstName",
    "lastName",
    "gender",
    "dateOfBirth",
    "trustedTraveler",
    "preferredAirportCode",
    "preferredAirline",
    "prefAirlineFreqFlier",
    "checkInDate",
    "checkOutDate",
    "isDepartureFlight",
    "isReturnFlight",
    "companyOwner",
    "wheelChairRequest",
    "miscGroupRequest",
    "notes",
    "traverlerDesignationText",
    "salesmanName",
    "trip",
  ];

  public dataSource = new MatTableDataSource<TravelerEntity>();
  @ViewChild(MatPaginator, { static: true })
  resultsLength = 0;
  pageSize = 25;
  pageEvent: PageEvent;

  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  response: any = {};
  constructor(
    private travelerService: TravelerService,
    public _utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 3,
      new Date().getFullYear() + 1
    );
    this.travelerService.getTripCodeList().subscribe((result) => {
      this.alltripList = result;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  travelerList(searchText) {
    this.dataSource.data = [];
    let pageIndex = this.pageEvent ? this.pageEvent.pageIndex + 1 : 1;
    const pageSize = this.pageEvent ? this.pageEvent.pageSize : this.pageSize;

    if (searchText) {
      pageIndex = 1;
    }
    this.travelerService
      .reportTravelerWithGroupListNew(+this.tripId, +this.years, pageIndex, pageSize, searchText)
      .subscribe((result) => {
        this.dataSource.data = result.data as TravelerEntity[]; //.fltier(e=>e.isTripAdded)
        this.resultsLength = result.totalCount;
        if (this.paginator) {
          this.paginator.pageSize = pageSize;
          this.paginator.length = this.resultsLength;
        }
      });
  }
  loadPage() {
    this.travelerList(this.searchText);
  }

  changeYear() {
    this.tripId = "";
    this.tripOptionList = [];
    if (this.alltripList && this.alltripList.length > 0)
      this.tripOptionList = this.alltripList.filter(
        (t) => t.years === this.years
      );
    this.dataSource.data = [];
    // this.travelerList();
  }
  changeTrip() {
    this.travelerList(this.searchText);
  }

  applyFilter(filterValue: string) {
    this.searchText = filterValue.trim().toLowerCase();
    this.travelerList(this.searchText);
  }

  exportToExcel(): void {
    const pageIndex = 1;
    const pageSize = this.resultsLength;

    if (+this.tripId > 0 && +this.years > 0) {
      this.travelerService.reportTravelerWithGroupListNew(+this.tripId, +this.years, pageIndex, pageSize, this.searchText)
        .subscribe((result) => {
          const data = result.data.map(item => {
            return {
              'Whs': item.ppeWhsCode,
              'Company#': item.customerNumber,
              'Company Name': item.companyName.replace(/&#39;/g, "'"),
              'Groups': item.groups,
              'First Name': item.firstName,
              // 'Middle Name': item.middleName,
              'Last Name': item.lastName,
              //'Nick Name': item.nickName,
              'Gender': item.gender == 1 ? 'M' : 'F',
              'Date Of Birth': item.dateOfBirth,
              'Trusted Traveler': item.trustedTraveler,
              'Preferred Airport': item.preferredAirportCode,
              'Preferred Airline': item.preferredAirline,
              'Pref. Air. Freq. Flier#': item.prefAirlineFreqFlier,
              'Departure Date': item.checkInDate,
              'Return Date': item.checkOutDate,
              'Departure Flight': item.isDepartureFlight ? 'Y' : 'N',
              'Return Flight': item.isReturnFlight ? 'Y' : 'N',
              'Owner': item.companyOwner ? 'Y' : 'N',
              'Wheel Chair': item.wheelChairRequest ? 'Y' : 'N',
              'Misc. Group Request': item.miscGroupRequest,
              'Notes': item.notes,
              'Employee': item.traverlerDesignationText,
              'Salesman': item.salesmanName,
              'Trip#': item.trip
            };
          });
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Purchase-Airfare');
          XLSX.writeFile(wb, `Purchase_Airfare_${this._utilityService.getCurrentDateTime()}.xlsx`);
        });
    }
  }
}
export interface TravelerEntity {
  trip: string;
  ppeWhsCode: string;
  customerNumber: string;
  companyName: string;
  salesmanName: string;
  groups: string;
  nickName: string;
  traveler: string;
  firstName: string;
  middleName: string;
  lastName: string;
  legalSuffix: string;
  gender: string;
  dateOfBirth: string;
  cellPhone: string;
  trustedTraveler: string;
  preferredAirportCode: string;
  preferredAirline: string;
  prefAirlineFreqFlier: string;
  checkInDate: string;
  checkOutDate: string;
  isDepartureFlight: boolean;
  isReturnFlight: boolean;
  companyOwner: boolean;
  outGoingFlight1: string;
  outGoingFlight2: string;
  outGoingFlight3: string;
  outGoingFlight4: string;
  returnFlight1: string;
  returnFlight2: string;
  returnFlight3: string;
  returnFlight4: string;
  notes: string;
  miscGroupRequest: string;
  rentACar: string;
  wheelChairRequest: string;
  datesOfStay: string;
  specialRequests: string;
  options1: string;
  options2: string;
  options3: string;
  options4: string;
  options5: string;
  options6: string;
  traverlerDesignationText: string;
}
