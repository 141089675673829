import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { SalesmanService } from '../services/salesman.service';
import { Router } from '@angular/router';
import { SalesmanAddEditPopupComponent } from '../salesman-add-edit-popup/salesman-add-edit-popup.component';

@Component({
	selector: 'app-salesman-list',
	templateUrl: './salesman-list.component.html',
	styleUrls: [ './salesman-list.component.css' ]
})
export class SalesmanListComponent implements OnInit {
	public displayedColumns = [ 'ppeSalesmanCode', 'salesmanName', 'salesmanEmail', 'isActiveText', 'action' ];
	public dataSource = new MatTableDataSource<SalesmanListEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
	constructor(private _salesmanService: SalesmanService, private _router: Router, private matDialog: MatDialog) {}

	ngOnInit() {
		if (localStorage.getItem('salesmanImport') != null) {
			this.response = JSON.parse(localStorage.getItem('salesmanImport'));
			localStorage.removeItem('salesmanImport');
		}
		this.salesmanList();
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	salesmanList() {
		//debugger;
		this._salesmanService.getSalesmanList().subscribe((result) => {
			this.dataSource.data = result as SalesmanListEntity[];
			//console.log(this.dataSource.data);
			this.dataSource.paginator = this.paginator;
		});
	}
	addUpdateSalesman(id: number) {
		this._salesmanService.getSalesmanDetail(id).subscribe((result) => {
			if (result) this.model = result;
			else this.model = {};
			const dialogConfig = new MatDialogConfig();
			// The user can't close the dialog by clicking outside its body
			dialogConfig.disableClose = true;
			// dialogConfig.id = "modal-component";
			dialogConfig.width = '500px';
			dialogConfig.panelClass = 'dap-model';
			dialogConfig.data = this.model;
			const modalDialog = this.matDialog.open(SalesmanAddEditPopupComponent, dialogConfig);

			modalDialog.afterClosed().subscribe((result) => {
				if (result) {
					this.response = result;
					this.salesmanList();
				}
			});
		});
	}
	deleteSalesman(id: number) {
		if (confirm('Are you sure to delete the current record?')) {
			this._salesmanService.deleteSalesman(id).subscribe((result) => {
				this.response = result;
				if (result && result.messageCode == 1) {
					this.salesmanList();
				}
			});
		}
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}
export interface SalesmanListEntity {
	id: number;
	ppeSalesmanCode: string;
	salesmanName: string;
	salesmanEmail: string;
	isActiveText: string;
}
