import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-points-option-break-up',
  templateUrl: './points-option-break-up.component.html',
  styleUrls: ['./points-option-break-up.component.css']
})
export class PointsOptionBreakUpComponent implements OnInit {
	public displayedColumns = [ 'groups', 'pointsOption','pointAdjustment' ];
	public dataSource = new MatTableDataSource<ItineraryEntity>();
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	response: any = {};
	model = {};
  destination:string=''
	constructor(public dialogRef: MatDialogRef<PointsOptionBreakUpComponent>,@Inject(MAT_DIALOG_DATA) public data) {}
	ngOnInit() {
		// console.log(this.data);
    this.destination= this.data.destination
		this.dataSource.data = this.data.pointList as ItineraryEntity[];

	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	onCloseClick(): void {
		this.dialogRef.close();
	}
}
export interface ItineraryEntity {
  groups: number;
  pointsOption:string;
  pointAdjustment: string ;
}


