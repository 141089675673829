import { Component, OnInit } from '@angular/core';
import { DashboardCounterService } from '../services/dashboard-counter.service';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dashboardCounter: any = {};
  years = new Date().getFullYear();
  yearList = [];
  displayData = false;

  constructor(private _dashboardCounter: DashboardCounterService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.yearList = this.utilityService.fillYear(new Date().getFullYear() - 2, new Date().getFullYear());
    this.getDashboardCounter();
  }

  getDashboardCounter() {

    this._dashboardCounter.getDashboardCounter(this.years)
      .subscribe((result) => {
        if (result) {
          this.dashboardCounter = result;
          this.displayData = this.dashboardCounter.isSelectedYearHasData;
        }
      });
  }
}
