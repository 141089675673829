import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomValidators } from "src/app/Directives/custom-validators";
import { EnrollmentNotesService } from "src/app/services/enrollment-notes.service";

@Component({
  selector: "app-trip-enrollment-notes",
  templateUrl: "./trip-enrollment-notes.component.html",
  styleUrls: ["./trip-enrollment-notes.component.css"],
})
export class TripEnrollmentNotesComponent implements OnInit {
  desc: any = "";
  submitted = false;
  response: any = {};
  model = {};
  userId = 0;
  enrollmentNotesList = [];
  public enrollmentNotesForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<TripEnrollmentNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private enrollmentNotesService: EnrollmentNotesService,
    private fb: FormBuilder
  ) {}
  ngOnInit() {
    this.model = this.data;
    this.userId = JSON.parse(localStorage.getItem("user")).id;
    this.generateForm();
    this.getList();
  }

  generateForm() {
    this.enrollmentNotesForm = this.fb.group({
      EnrollmentId: [this.data.EnrollmentId, Validators.required],
      Notes: ["", [Validators.required, CustomValidators.checkEmpty]],
      CreatedBy: [this.userId, [Validators.required]],
    });
  }

  saveNotes() {
    this.submitted = true;
    if (this.enrollmentNotesForm.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName("ng-invalid")
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }

    this.enrollmentNotesService
      .save(this.enrollmentNotesForm.value)
      .subscribe((result) => {
        if (result) {
          if (result.messageCode == "1") {
            result.notes = this.f.Notes.value;
            this.dialogRef.close(result);
          } else {
            this.response = result;
          }
        }
      });
  }
  get f() {
    return this.enrollmentNotesForm.controls;
  }
  getList() {
    this.enrollmentNotesService
      .getList(this.data.EnrollmentId)
      .subscribe((result) => {
        this.enrollmentNotesList = result;
      });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
