import { Injectable, Inject } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

let myAppUrl = "";
@Injectable({
  providedIn: 'root'
})
export class TripwhsdefaultsService {

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  saveTripWhs(model) {
    return this._http.post<any>(myAppUrl + 'api/TripWhsDefaults/AddUpdateTripWhs', model)
      .pipe(map(data => { return data; }))
  }

  getTripWhsDetail(id: number) {
    return this._http.get<any>(myAppUrl + 'api/TripWhsDefaults/GetTripWhsDetail?id=' + id)
      .pipe(map(data => { return data; }))
  }
  getTripWhsList() {
    return this._http.get<any>(myAppUrl + 'api/TripWhsDefaults/TripWhsList')
      .pipe(map(data => { return data; }))
  }
  getTripWhsListActive() {
    return this._http.get<any>(myAppUrl + 'api/TripWhsDefaults/TripWhsListActive')
      .pipe(map(data => { return data; }))
  }
  deleteTripWhs(id: number) {
    return this._http.post<any>(myAppUrl + 'api/TripWhsDefaults/TripWhsDelete?id=' + id, null)
      .pipe(map(data => { return data; }))
  }
  getTripWhsDetailWithCodeList(id: number) {
    return this._http.get<any>(myAppUrl + 'api/TripWhsDefaults/GetTripWhsDetailWithCodeList?id=' + id)
      .pipe(map(data => { return data; }))
  }
  getWHSCodeListNotAddedWithSelectedTripId(tripId: number) {
    return this._http.get<any>(myAppUrl + 'api/TripWhsDefaults/GetWHSCodeListNotAddedWithSelectedTripId?tripId=' + tripId)
      .pipe(map(data => { return data; }))
  }
}