
import { Injectable, Inject } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

let myAppUrl = "";
@Injectable({
  providedIn: 'root'
})
export class AirlineService {

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  saveAirline(model) {
    return this._http.post<any>(myAppUrl + 'api/Airline/AddUpdateAirline', model)
      .pipe(map(data => { return data; }))
  }
  getAirlineDetail(id: number) {
    return this._http.get<any>(myAppUrl + 'api/Airline/GetAirlineDetail?id=' + id)
      .pipe(map(data => { return data; }))
  }
  getAirlineList() {
    return this._http.get<any>(myAppUrl + 'api/Airline/AirlineList')
      .pipe(map(data => { return data; }))
  }
  
  deleteAirline(id: number) {
    return this._http.post<any>(myAppUrl + 'api/Airline/AirlineDelete?id=' + id, null)
      .pipe(map(data => { return data; }))
  }
}