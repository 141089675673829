import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UtilityService } from '../../services/utility.service';
//import { DatePipe } from '@angular/common';
//import { UpdateNameService } from '../../services/update-name.service'

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
})
export class AddUserComponent implements OnInit {
  model: any = { roles: '' };

  response: any = {};
  userId: string = '0';
  role = +localStorage.getItem('role');
  roleList = [];
  tripList: any = [];
  public mask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  constructor(
    private _userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private _utilityService: UtilityService
  ) {}
  ngOnInit() {
    this.roleList = this._utilityService.getRoles();
    this.userId = JSON.parse(localStorage.getItem('user')).id;
    if (this.userId) {
      let currentYear = new Date().getFullYear();
      let previousYear = currentYear - 1;
      this._userService.getTripOptionListActive().subscribe((result) => {
        this.tripList = result.filter(
          (t) => !t.isOptional && t.years >= previousYear.toString()
        );
        this.getUserDetail();
      });
    }
  }

  getUserDetail() {
    this.model = { Id: this.userId };
    this._userService.getUserDetail(this.model).subscribe((result) => {
      this.model = result.data;
      this.model.tripId = this.model.tripId ? this.model.tripId : '';
      //console.log(this.model);
      //this.model.yearEndClosingDate = this.model.yearEndClosingDateDisplay ? new Date(this.model.yearEndClosingDateDisplay) : null;
    });
  }
  setMinCheckoutDate(filterValue: string) {
    this.model.yearEndClosingDate = filterValue;
  }
  addUpdate(form: NgForm) {
    if (form.valid) {
      this.model.tripId = +this.model.tripId;
      this.model.YearEndClosingDateString = this.model.yearEndClosingDate
        ? new Date(this.model.yearEndClosingDate).toDateString()
        : '';
      this.model.LogUserId = this._utilityService.getCurrentUserId();

      this._userService.saveUserDetail(this.model).subscribe((result) => {
        if (result) {
          this.response = result;
          if (this.response.messageCode == 1) {
            localStorage.setItem('user', JSON.stringify(result.data));
            this.model.tripId = this.model.tripId ? this.model.tripId : '';
            //this.router.navigate(['/dashboard']);
          }
        }
      });
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      invalidFields[1].focus();
    }
  }
}
