import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

let myAppUrl = '';
@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    myAppUrl = baseUrl;
  }
  updatedCustomerDAP(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Customer/UpdateCustomerDAP', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  updatedCompanyInfo(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Customer/UpdateCompanyInfo', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCustomerDetail(id: number) {
    return this._http
      .get<any>(myAppUrl + 'api/Customer/CustomerDetail?id=' + id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getCustomerList() {
    return this._http.get<any>(myAppUrl + 'api/Customer/CustomerList').pipe(
      map((data) => {
        return data;
      })
    );
  }
  deleteCustomer(id: number, logUserId: number) {
    return this._http
      .post<any>(
        myAppUrl + 'api/Customer/DeleteCustomer/' + id + '/' + logUserId,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getEnrollmentDetail(id: number) {
    return this._http
      .get<any>(myAppUrl + 'api/TripEnrollment/EnrollmentDetail?id=' + id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getEnrollmentList(year) {
    return this._http
      .get<any>(myAppUrl + 'api/TripEnrollment/EnrollmentList?year=' + year)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  Import(fileName) {
    return this._http
      .post<any>(myAppUrl + 'api/Customer/Import?fileName=' + fileName, null)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  importSave(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Customer/ImportSave', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  sampleFileDownload(model) {
    window.open(
      myAppUrl + 'api/Salesman/SampleFileDownload?filename=' + model,
      '_blank'
    );
  }
  getCustomerNameList(value, parentCompanyId) {
    return this._http
      .get<any>(
        myAppUrl +
          'api/Customer/CustomerNameListCompanyEdit?searchKey=' +
          value +
          '&parentCompanyId=' +
          parentCompanyId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getCustomerListPremNotEligibleExport(searchText) {
    window.open(
      myAppUrl +
        'api/Customer/GetCustomerListPremNotEligibleExport?searchText=' +
        searchText
    );
  }

  updateCustomerDAPAddress(model) {
    return this._http
      .post<any>(myAppUrl + 'api/Customer/UpdateCustomerDAPAddress', model)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
